import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/service/auth.service';
import { info, confirm, warn, error } from '@elf/amber-notification/lib/helpers';
import { ClientEventType, PubSubService } from 'src/service/pub-sub.service';

@Injectable({
  providedIn: 'root'
})
export class AvalailableComponentGuard implements CanActivate {
  constructor(private router:Router, private authService: AuthService, private pubSubService: PubSubService ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log(route)
    // console.log(state)
    // return true;

    let url = state.url;
    let frontendConfigObj = this.authService.userInfo['frontend_config'] ? this.authService.userInfo['frontend_config'] : {}
    let countryDashboardEnabled = frontendConfigObj.hasOwnProperty('countryDashboard');
    let countryEnabled = frontendConfigObj.hasOwnProperty('country')
    let companyDashboardEnabled = frontendConfigObj.hasOwnProperty('companyDashboard')
    let companyEnabled = frontendConfigObj.hasOwnProperty('company')
    let referenceDataEnabled = frontendConfigObj.hasOwnProperty('referenceData')
    let adminEnabled = frontendConfigObj.hasOwnProperty('admin')

    let availableTargets = []
    if (countryDashboardEnabled) {
      availableTargets.push('/country-dashboard')
    }
    if(countryEnabled) {
      availableTargets.push('/country')
    }
    if(companyDashboardEnabled) {
      availableTargets.push('/counterparty-dashboard')
    }
    if(companyEnabled) {
      availableTargets.push('/counterparty')
    }
    if(referenceDataEnabled) {
      availableTargets.push('/reference-data')
    }
    if(adminEnabled) {
      availableTargets.push('/admin')
    }
    availableTargets.push('/main')
    let matchUrl = (item: string) => {
      if (url == item) {
        return true
      } else if (url.startsWith(item + ';')) {
        return true;
      }
      return false;
    }
    if (availableTargets.findIndex(matchUrl) != -1) {
      return true;
    } else {
      this.router.navigate([availableTargets[0]])
      return false;
    }
  }

}
