<div class="country-content no-print">
  <div class="title-line-area">
    <div>
      <coral-combo-box id="country-country-combobox" (value-changed)='onCountryChange($event)' [value]="selectedCountry"
       style="height: 40px; font-size: 1.8em; font-weight: bold; background-color: transparent; border: none; border-bottom: 1px solid #000; color: #333; width: 90%"></coral-combo-box>
    </div>
    <div class="active-text" style="display:none;"><span  [ngStyle]="{'display': hiddenDetailSectionAll? 'none': 'inherit'}">{{countryActiveState}}</span></div>
    <div></div>
  </div>
  <div style="margin-top:10px;">
    <coral-collapse header="TOTAL NEWS & SENTIMENT TREND IN PAST 6 MONTHS{{currentCountry4TrendSection ? ' - ' + currentCountry4TrendSection : ''}}">
      <div style="display: flex;align-items: center; padding-top:10px;">
        <label style="padding-right:8px;">Timeframe:</label>
        <coral-split-button id="trend-timeframe-split-btn" managed>
          <coral-button id="trend-timeframe-split-btn-30d" class="30d" toggles >30D</coral-button>
          <coral-button id="trend-timeframe-split-btn-60d" class="60d" toggles >60D</coral-button>
          <coral-button id="trend-timeframe-split-btn-90d" class="90d" toggles >90D</coral-button>
          <coral-button id="trend-timeframe-split-btn-180d" class="180d" toggles >180D</coral-button>
        </coral-split-button>
        <coral-button [disabled]="trendGoBtnDisabled" (click)="onTrendGoBtnClick()"
          style="width:80px;height:24px;font-size: 1.3em;background-color:blue;color:white;margin-left:20px;">Go</coral-button>
      </div>
      <div [ngClass]="hiddenTrendSectionAll? 'hiddenTrendSectionAll': 'trend-section-content'">
        <div style="display: flex; align-items: flex-end; justify-content: center; margin-top: 10px; font-size: 1.3em; font-weight: bold;">
          <span>7D Sentiments and % Total News{{currentCountry4TrendSection ? ' - ' + currentCountry4TrendSection : ''}}</span>
        </div>
        <sapphire-chart id="six-month-trend-chart-7d" style="width: 100%; max-width: 100vw; height: 300px;"></sapphire-chart>
        <div style="display: flex; align-items: flex-end; justify-content: center; margin-top: 12px; font-size: 1.3em; font-weight: bold;">
          <span>30D Sentiments and % Total News{{currentCountry4TrendSection ? ' - ' + currentCountry4TrendSection : ''}}</span>
        </div>
        <sapphire-chart id="six-month-trend-chart-30d" style="width: 100%; max-width: 100vw; height: 300px;"></sapphire-chart>
      </div>
    </coral-collapse>
  </div>
  <div style="background-color: #f2f2f2; height: 28px; display: flex; align-items: center; padding-left: 6px;margin-top:8px;">
    <coral-icon icon="down" slot="left" part="toggle"></coral-icon><span style="padding-left:5px;">NEWS SENTIMENT & HEADLINES BASED ON DATE{{currentCountry4DetailSection ? ' - ' + currentCountry4DetailSection.toUpperCase() : ''}}</span>
  </div>
  <div class="sentiments-line-area">
    <div class="date-selection">
      <emerald-datetime-picker [value]="selectedDate" [min]='minDate' [max]='maxDate' format="yyyy-MM-dd"
        (value-changed)='onDateChange($event)' style="width: 180px;"></emerald-datetime-picker>
      <coral-button [disabled]="detailGoBtnDisabled" (click)="onDetailGoBtnClick()"
        style="width:90px;height:26px;font-size: 1.4em;background-color:blue;color:white;margin-left:20px;">Go</coral-button>
    </div>
    <div [ngClass]="hiddenDetailSectionAll? 'hiddenDetailSectionAll': 'sentiment-ration-bar'"><label>1D:</label>
      <span class="sentiment-visualization">
        <span *ngIf="d1.negativePercentage==0 && d1.neutralPercentage==0 && d1.positivePercentage==0" style="width:100px;border:1px solid lightgray;"></span>
        <span class="negative" *ngIf="d1.negativePercentage!=0" [ngStyle]="{'width': d1.negativePercentage + 'px', 'background-color': negativeColor}" [title]="d1.negativePercentage + '%'">{{d1.negativePercentage >= 15 ? d1.negativePercentage+'%' : ''}}</span>
        <span class="neutral" *ngIf="d1.neutralPercentage!=0" [ngStyle]="{'width': d1.neutralPercentage + 'px', 'background-color': neutralColor}" [title]="d1.neutralPercentage + '%'">{{d1.neutralPercentage >= 15 ? d1.neutralPercentage+'%' : ''}}</span>
        <span class="positive" *ngIf="d1.positivePercentage!=0" [ngStyle]="{'width': d1.positivePercentage + 'px', 'background-color': positiveColor}" [title]="d1.positivePercentage + '%'">{{d1.positivePercentage >= 15 ? d1.positivePercentage+'%' : ''}}</span>
        <span class="total">{{d1.totalNews}}</span>
        <span [ngClass]="d1.sentimentIndicator == -1 ? 'sentiment-bar bold' : 'sentiment-bar'" [style]="{'color':negativeColor}">{{d1.negativeSentiments}}</span>
        <span [ngClass]="d1.sentimentIndicator == 0 ? 'sentiment-bar bold' : 'sentiment-bar'" [style]="{'color':neutralColor}">{{d1.neutralSentiments}}</span>
        <span [ngClass]="d1.sentimentIndicator == 1 ? 'sentiment-bar bold' : 'sentiment-bar'" [style]="{'color':positiveColor}">{{d1.positiveSentiments}}</span>
      </span>
    </div>
    <div [ngClass]="hiddenDetailSectionAll? 'hiddenDetailSectionAll': 'sentiment-ration-bar'"><label>7D:</label>
      <span class="sentiment-visualization">
        <span *ngIf="d7.negativePercentage==0 && d7.neutralPercentage==0 && d7.positivePercentage==0" style="width:100px;border:1px solid lightgray;"></span>
        <span class="negative" *ngIf="d7.negativePercentage!=0" [ngStyle]="{'width': d7.negativePercentage + 'px', 'background-color': negativeColor}" [title]="d7.negativePercentage + '%'">{{d7.negativePercentage >= 15 ? d7.negativePercentage+'%' : ''}}</span>
        <span class="neutral" *ngIf="d7.neutralPercentage!=0" [ngStyle]="{'width': d7.neutralPercentage + 'px', 'background-color': neutralColor}" [title]="d7.neutralPercentage + '%'">{{d7.neutralPercentage >= 15 ? d7.neutralPercentage+'%' : ''}}</span>
        <span class="positive" *ngIf="d7.positivePercentage!=0" [ngStyle]="{'width': d7.positivePercentage + 'px', 'background-color': positiveColor}" [title]="d7.positivePercentage + '%'">{{d7.positivePercentage >= 15 ? d7.positivePercentage+'%' : ''}}</span>
        <span class="total">{{d7.totalNews}}</span>
        <span [ngClass]="d7.sentimentIndicator == -1 ? 'sentiment-bar bold' : 'sentiment-bar'" [style]="{'color':negativeColor}">{{d7.negativeSentiments}}</span>
        <span [ngClass]="d7.sentimentIndicator == 0 ? 'sentiment-bar bold' : 'sentiment-bar'" [style]="{'color':neutralColor}">{{d7.neutralSentiments}}</span>
        <span [ngClass]="d7.sentimentIndicator == 1 ? 'sentiment-bar bold' : 'sentiment-bar'" [style]="{'color':positiveColor}">{{d7.positiveSentiments}}</span>
      </span>
    </div>
    <div [ngClass]="hiddenDetailSectionAll? 'hiddenDetailSectionAll': 'sentiment-ration-bar'"><label>30D:</label>
      <span class="sentiment-visualization">
        <span *ngIf="d30.negativePercentage==0 && d30.neutralPercentage==0 && d30.positivePercentage==0" style="width:100px;border:1px solid lightgray;"></span>
        <span class="negative" *ngIf="d30.negativePercentage!=0" [ngStyle]="{'width': d30.negativePercentage + 'px', 'background-color': negativeColor}" [title]="d30.negativePercentage + '%'">{{d30.negativePercentage >= 15 ? d30.negativePercentage+'%' : ''}}</span>
        <span class="neutral" *ngIf="d30.neutralPercentage!=0" [ngStyle]="{'width': d30.neutralPercentage + 'px', 'background-color': neutralColor}" [title]="d30.neutralPercentage + '%'">{{d30.neutralPercentage >= 15 ? d30.neutralPercentage+'%' : ''}}</span>
        <span class="positive" *ngIf="d30.positivePercentage!=0" [ngStyle]="{'width': d30.positivePercentage + 'px', 'background-color': positiveColor}" [title]="d30.positivePercentage + '%'">{{d30.positivePercentage >= 15 ? d30.positivePercentage+'%' : ''}}</span>
        <span class="total">{{d30.totalNews}}</span>
        <span [ngClass]="d30.sentimentIndicator == -1 ? 'sentiment-bar bold' : 'sentiment-bar'" [style]="{'color':negativeColor}">{{d30.negativeSentiments}}</span>
        <span [ngClass]="d30.sentimentIndicator == 0 ? 'sentiment-bar bold' : 'sentiment-bar'" [style]="{'color':neutralColor}">{{d30.neutralSentiments}}</span>
        <span [ngClass]="d30.sentimentIndicator == 1 ? 'sentiment-bar bold' : 'sentiment-bar'" [style]="{'color':positiveColor}">{{d30.positiveSentiments}}</span>
      </span>
    </div>
  </div>
  <div [ngClass]="hiddenDetailSectionAll? 'hiddenDetailSectionAll': 'parameter'">
    <hr style="margin: 8px 0 8px 0; height: 3px;">
  </div>
  <div class="all-filter-line-area">
    <div [ngClass]="hiddenDetailSectionAll? 'hiddenDetailSectionAll': 'parameter'" style="display:none;">
      <label>Sentiment:</label>
      <coral-split-button id="country-sentiment-type-split-btn" managed>
        <coral-button active toggles >All</coral-button>
        <coral-button toggles >Positive</coral-button>
        <coral-button toggles >Neutral</coral-button>
        <coral-button toggles >Negative</coral-button>
      </coral-split-button>
    </div>
    <div [ngClass]="hiddenDetailSectionAll? 'hiddenDetailSectionAll': 'parameter'">
      <label>Timeframe:</label>
      <coral-split-button id="country-timeframe-split-btn" managed>
        <coral-button id="country-timeframe-split-btn-1d" class="1d" toggles >1D</coral-button>
        <coral-button id="country-timeframe-split-btn-7d" class="7d" toggles >7D</coral-button>
        <coral-button id="country-timeframe-split-btn-30d" class="30d" toggles >30D</coral-button>
      </coral-split-button>
    </div>
    <div></div>
  </div>
  <div [ngClass]="hiddenDetailSectionAll? 'hiddenDetailSectionAll': 'country-chart-area'">
    <sapphire-chart id="country-bar-chart" style="width: 100%; max-width: 100vw; height: 400px;"></sapphire-chart>
    <div style="display: flex; font-weight: 401; align-items: center; color: gray;padding-top:10px;">
      <coral-icon icon="info" style="font-size:1.4em;"></coral-icon>
      <span style="margin-left:2px;font-size:1.3em;">The News Headlines below correspond to the timeframe above. By default, the News on most recent day are listed. Use Topic Selection to include/exclude topics. Click GO button to refresh the headlines after changing the date, sentiment or topic selection.</span>
    </div>
  </div>
  <div [ngClass]="hiddenDetailSectionAll? 'hiddenDetailSectionAll': 'topic-filter-line-area'">
    <label style="font-weight:450;">Date:</label>
    <coral-select id="country-date-filter-select" (value-changed)='onDateFilterChange($event)' [value]="selectedFilterDate" style="margin-left:8px;"></coral-select>
    <label style="margin-left:10px;font-weight:450;">Sentiment:</label>
    <emerald-multi-select  id="country-sentiment-filter-select" (value-changed)='onSentimentFilterChange($event)' show-pills style="border-color:#b9b9b9;margin-left:8px;"></emerald-multi-select>
    <label style="margin-left:10px;font-weight:450;">Topic Selection:</label>
    <emerald-multi-select  id="country-topic-multiselection" (value-changed)='onTopicChange($event)' show-pills style="border-color:#b9b9b9;margin-left:8px;min-width:400px;"></emerald-multi-select>
    <label style="margin-left:10px;font-weight:450;">Sort By:</label>
    <coral-select id="country-sort-by-filter-select" (value-changed)='onSortByFilterChange($event)' [value]="selectedFilterSortBy" style="margin-left:8px;min-width:180px;"></coral-select>
    <div style="padding-left: 8px;">
      <coral-button (click)="onTopicGoBtnClick()" [disabled]="detailFilterGoBtnDisabled"
        style="width:80px;font-size: 1.2em;background-color:blue;color:white;">Go</coral-button>
    </div>
    <div></div>
  </div>
  <div [ngStyle]="{'display': hiddenDetailSectionAll? 'none': 'block'}">
    <emerald-grid id="country-news-grid" #dashboardgrid style="height: 153px;"></emerald-grid>
    <div style="width: 100%;display:flex;justify-content: center;"><emerald-pagination id="country-news-grid-pagination"></emerald-pagination></div>
  </div>
</div>
<div *ngIf="loading" style="display:block;pointer-events: auto;z-index: 110;">
  <amber-loader  style="background-color: rgb(40, 40, 40, 0.5);width:100%;height:100%;position: fixed;"></amber-loader>
</div>
<coral-popup-panel id="country-news-popup-panel" with-backdrop with-shadow>
  <div class="popup-header">
    <div>
      <h4 style="font-weight:500;">{{newsPopupPanelData.headline}}</h4>
      <div style="display:flex;flex-direction: row;justify-content: space-between;width:87%;align-items: center;">
        <h5 style="margin-top:20px;font-weight:400;">{{newsPopupPanelData.timestamp}}</h5>
        <coral-split-button >
          <coral-button icon="bigger-plus" title="Enlarge Font Size" (tap)="enlargeHeadlineContentFontSize()"></coral-button>
          <coral-button icon="line-thick" title="Shrink Font Size" (tap)="shrinkHeadlineContentFontSize()"></coral-button>
        </coral-split-button>
      </div>
    </div>
    <div style="display:flex;justify-content:flex-end;padding-top:20px;padding-right:20px;">
      <coral-tab icon="print" (tap)="onNewsPopupPrintClick()" style="font-size: 24px; padding: 2px 2px 2px 8px;"></coral-tab>
  </div>
  </div>
    <div class="popup-row">
      <div class="content" [style.font-size]="headlineContentFontSizeStr">
        <pre style="white-space: break-spaces;padding-right:6px;font-family:'Proxima Nova Fin';">{{newsPopupPanelData.body}}</pre>
      </div>
      <div class="sentiments" id="country-news-popup-panel-right-coloumn">
        <coral-panel>
            <h4>SOURCE</h4>
            <h5 style="margin:14px;font-weight:450;">{{newsPopupPanelData.source}}</h5>
        </coral-panel>
        <coral-panel>
            <h4>SENTIMENT POSITIVE</h4>
        </coral-panel>
        <sapphire-led-gauge  [style]="{
          '--left-segment-color': gradientPositiveColor0,
          '--center-left-segment-color': gradientPositiveColor1,
          '--center-segment-color': positiveColor,
          '--center-right-segment-color': gradientPositiveColor2,
          '--right-segment-color': gradientPositiveColor3
        }" [attr.top-label]="newsPopupPanelData.positiveSentiment" [attr.top-value]="newsPopupPanelData.positiveSentiment * 200 - 100"></sapphire-led-gauge>
        <coral-panel>
            <h4>SENTIMENT NEUTRAL</h4>
        </coral-panel>
        <sapphire-led-gauge [style]="{
          '--left-segment-color': gradientNeutralColor0,
          '--center-left-segment-color': gradientNeutralColor1,
          '--center-segment-color': neutralColor,
          '--center-right-segment-color': gradientNeutralColor2,
          '--right-segment-color': gradientNeutralColor3
        }" [attr.top-label]="newsPopupPanelData.neutralSentiment" [attr.top-value]="newsPopupPanelData.neutralSentiment * 200 - 100"></sapphire-led-gauge>
        <coral-panel>
            <h4>SENTIMENT NEGATIVE</h4>
        </coral-panel>
        <sapphire-led-gauge [style]="{
          '--left-segment-color': gradientNegativeColor0,
          '--center-left-segment-color': gradientNegativeColor1,
          '--center-segment-color': negativeColor,
          '--center-right-segment-color': gradientNegativeColor2,
          '--right-segment-color': gradientNegativeColor3
        }" [attr.top-label]="newsPopupPanelData.negativeSentiment" [attr.top-value]="newsPopupPanelData.negativeSentiment * 200 - 100"></sapphire-led-gauge>
        <coral-panel>
            <h4>TOPICS</h4>
        </coral-panel>
        <coral-panel>
            <ul style="margin-top: 0;">
              <li style="font-size: 1.2em; padding: 3px 0;" *ngFor="let topic of newsPopupPanelData.topics">{{topic}}</li>
            </ul>
        </coral-panel>
    </div>
  </div>
</coral-popup-panel>