<div style="height: 100%;width: 100%;background-color: blue;">
  <coral-dialog id="org_dlg" [opened]="true" [header]="data.dataType=='org'? 'Organizations' : 'users'" (cancel)="dlgCancel()"  style="min-width:1200px;min-height:740px;">
    <div *ngIf="data.dataType=='org'" style="display:grid;grid-template-columns: 33% 33% 33%;padding-left:8px;">
      <div style="display:grid;grid-template-columns: 30% 70%;align-items:center;">
        <label>Org ID:</label>
        <label>{{data.orgId}}</label>
      </div>
      <div style="display:grid;grid-template-columns: 30% 70%;align-items:center;">
        <label>Long Name:</label>
        <coral-text-field id="long_name_input" [value]="data.longName" placeholder="Long Name" (value-changed)="setOrgLongName($event)"></coral-text-field>
      </div>
      <div style="display:grid;grid-template-columns: 30% 70%;align-items:center;">
        <label>Short Name:</label>
        <coral-text-field *ngIf="data.opType=='add'" id="short_name_input" [value]="data.shortName" placeholder="Short Name" (value-changed)="setOrgShortName($event)"></coral-text-field>
        <label *ngIf="data.opType=='edit'" >{{data.shortName}}</label>
      </div>
    </div>
    <div *ngIf="data.dataType=='user'" style="display:grid;grid-template-columns: 33% 33% 33%;">
      <div style="display:grid;grid-template-columns: 30% 70%;align-items:center;">
        <label>User ID:</label>
        <label>{{data.userId}}</label>
      </div>
      <div style="display:grid;grid-template-columns: 30% 70%;align-items:center;">
        <label>AAA Login:</label>
        <coral-text-field id="aaa_login_input" [value]="data.aaaLogin" placeholder="AAA Login" (value-changed)="userAAALoginChanged($event)"></coral-text-field>
      </div>
      <div style="display:grid;grid-template-columns: 30% 70%;align-items:center;">
        <label>Organization:</label>
        <coral-select id="user_org_id_select" (value-changed)="userOrgIdSelectChanged($event)">
          <coral-item *ngFor="let item of orgsValue" [key]="item.value" [value]="item.value" [selected]="item.value == data.orgId">{{item.label}}</coral-item>
        </coral-select>
      </div>
    </div>
    <coral-accordion spacing  auto-collapse-disabled style="margin-top:8px;">
      <coral-collapse expanded header="Frontend Config">
        <div style=" margin: 4px 0;">
          <div style="background-color: #f2f0f0;">
            <coral-tab-bar>
              <coral-tab label="Common" id="common-tab" style="min-width:160px;height:30px;" (tap)="dlgTabClick('common')" active></coral-tab>
              <coral-tab label="Country Dashboard" id="country-dashboard-tab" style="min-width:160px;height:30px;" (tap)="dlgTabClick('country-dashboard')"></coral-tab>
              <coral-tab label="Country" id="country-tab" style="min-width:160px;height:30px;" (tap)="dlgTabClick('country')"></coral-tab>
              <coral-tab label="Company Dashboard" id="company-dashboard-tab" style="min-width:160px;height:30px;" (tap)="dlgTabClick('company-dashboard')"></coral-tab>
              <coral-tab label="Company" id="company-tab" style="min-width:160px;height:30px;" (tap)="dlgTabClick('company')"></coral-tab>
              <coral-tab label="Reference Data" id="reference-data-tab" style="min-width:160px;height:30px;" (tap)="dlgTabClick('reference-data')"></coral-tab>
              <coral-tab label="Admin" id="admin-tab" style="min-width:160px;height:30px;" (tap)="dlgTabClick('admin')"></coral-tab>
            </coral-tab-bar>
            <div id="common-content" style="padding: 4px 8px;">
              <div style="display:grid;grid-template-columns:16% 84%;padding-top:8px;">
                <label>Enabled:</label>
                <coral-checkbox disabled [checked]="true"></coral-checkbox>
              </div>
              <hr style="margin:2px 0;background-color:blue;padding:1px;margin-top: 8px;margin-bottom: 8px;">
              <div style="background-color: #f2f0f0;display:grid;grid-template-columns: 50% 50%;">
                <div style="display:grid;grid-template-columns: 40% 60%;align-items:center;">
                  <label>Company Dashboard Component Name:</label>
                  <coral-text-field id="company_dashboard_component_name_input" [value]="companyDashboardComponentName" placeholder="Component Name" (value-changed)="companyDashboardComponentNameChanged($event)"></coral-text-field>
                </div>
                <div style="display:grid;grid-template-columns: 40% 40% 20%;align-items: center;"><label>Country Dashboard Page Size:</label><coral-number-field placeholder="Page Size" id="countryDashboardPageSize-input" [value]="countryDashboardPageSize" (value-changed)="countryDashboardPageSizeValueChanged($event)" (error-changed)="_countryDashboardPageSizeValueChanged($event)" min="1" max="200" step="1"></coral-number-field></div>
                <div style="display:grid;grid-template-columns: 40% 60%;align-items:center;">
                  <label>Company Dashboard Name:</label>
                  <coral-text-field id="company_component_name_input" [value]="companyComponentName" placeholder="Component Name" (value-changed)="companyComponentNameChanged($event)"></coral-text-field>
                </div>
                <div style="display:grid;grid-template-columns: 40% 40% 20%;align-items: center;"><label>Country Headline Table Page Size:</label><coral-number-field placeholder="Page Size" id="countryHeadlinePageSize-input" [value]="countryHeadlinePageSize" (value-changed)="countryHeadlinePageSizeValueChanged($event)" (error-changed)="_countryHeadlinePageSizeValueChanged($event)" min="1" max="200" step="1"></coral-number-field></div>
                <div style="display:grid;grid-template-columns: 40% 40% 20%;align-items: center;"><label>Positive Color:</label><div><coral-text-field placeholder="Color Value" [value]="positiveColor" (value-changed)="positiveColorTextinputValueChanged($event)"></coral-text-field><coral-button cta  icon="palette" (tap)="colorBtnClick('positive')" style="background-color: blue;border-color: blue;width:16px;"></coral-button></div><div></div></div>
                <div style="display:grid;grid-template-columns: 40% 40% 20%;align-items: center;"><label>Company Dashboard Page Size:</label><coral-number-field placeholder="Page Size" id="companyDashboardPageSize-input" [value]="companyDashboardPageSize" (value-changed)="companyDashboardPageSizeValueChanged($event)" (error-changed)="_companyDashboardPageSizeValueChanged($event)" min="1" max="200" step="1"></coral-number-field></div>
                <div style="display:grid;grid-template-columns: 40% 40% 20%;align-items: center;"><label>Neutral Color:</label><div><coral-text-field placeholder="Color Value" [value]="neutralColor" (value-changed)="neutralColorTextinputValueChanged($event)"></coral-text-field><coral-button cta  icon="palette" (tap)="colorBtnClick('neutral')" style="background-color: blue;border-color: blue;width:16px;"></coral-button></div><div></div></div>
                <div style="display:grid;grid-template-columns: 40% 40% 20%;align-items: center;"><label>Company Headline Table Page Size:</label><coral-number-field placeholder="Page Size" id="companyHeadlinePageSize-input" [value]="companyHeadlinePageSize" (value-changed)="companyHeadlinePageSizeValueChanged($event)" (error-changed)="_companyHeadlinePageSizeValueChanged($event)" min="1" max="200" step="1"></coral-number-field></div>
                <div style="display:grid;grid-template-columns: 40% 40% 20%;align-items: center;"><label>Negative Color:</label><div><coral-text-field placeholder="Color Value" [value]="negativeColor"(value-changed)="negativeColorTextinputValueChanged($event)"></coral-text-field><coral-button cta  icon="palette" (tap)="colorBtnClick('negative')" style="background-color: blue;border-color: blue;width:16px;"></coral-button></div><div></div></div>
              </div>
            </div>
            <div id="country-dashboard-content" style="padding: 4px 8px;display:none;">
              <div style="display:grid;grid-template-columns:16% 84%;padding-top:8px;">
                <label>Enabled:</label>
                <coral-checkbox [checked]="countryDashboardEnabled" (checked-changed)="countryDashboardCheckBtnHandler($event)"></coral-checkbox>
              </div>
              <hr style="margin:2px 0;background-color:blue;padding:1px;margin-top: 8px;margin-bottom: 8px;">
              <div style="padding:8px 8px 20px 8px;">
                No more setting for Country Dashboard component so far.
              </div>
            </div>
            <div id="country-content" style="padding: 4px 8px;display:none;">
              <div style="display:grid;grid-template-columns:16% 84%;padding-top:8px;">
                <label>Enabled:</label>
                <coral-checkbox [checked]="countryEnabled" (checked-changed)="countryCheckBtnHandler($event)"></coral-checkbox>
              </div>
              <hr style="margin:2px 0;background-color:blue;padding:1px;margin-top: 8px;margin-bottom: 8px;">
              <div style="padding:8px 8px 20px 8px;">
                No more setting for Country component so far.
              </div>
            </div>
            <div id="company-dashboard-content" style="padding: 4px 8px;display:none;">
              <div style="display:grid;grid-template-columns:16% 84%;padding-top:8px;">
                <label>Enabled:</label>
                <coral-checkbox [checked]="companyDashboardEnabled" (checked-changed)="companyDashboardCheckBtnHandler($event)"></coral-checkbox>
              </div>
              <hr style="margin:2px 0;background-color:blue;padding:1px;margin-top: 8px;margin-bottom: 8px;">
              <div style="padding:8px 8px 20px 8px;">
                No more setting for Company Dashboard component so far.
              </div>
            </div>
            <div id="company-content" style="padding: 4px 8px;display:none;">
              <div style="display:grid;grid-template-columns:16% 84%;padding-top:8px;">
                <label>Enabled:</label>
                <coral-checkbox [checked]="companyEnabled" (checked-changed)="companyCheckBtnHandler($event)"></coral-checkbox>
              </div>
              <hr style="margin:2px 0;background-color:blue;padding:1px;margin-top: 8px;margin-bottom: 8px;">
              <div style="padding:8px 8px 20px 8px;">
                No more setting for Company component so far.
              </div>
            </div>
            <div id="reference-data-content" style="padding: 4px 8px;display:none;">
              <div style="display:grid;grid-template-columns:16% 84%;padding-top:8px;">
                <label>Enabled:</label>
                <coral-checkbox [checked]="referenceDataEnabled" (checked-changed)="referenceDataCheckBtnHandler($event)"></coral-checkbox>
              </div>
              <hr style="margin:2px 0;background-color:blue;padding:1px;margin-top: 8px;margin-bottom: 8px;">
              <div style="padding:8px 8px 20px 8px;">
                No more setting for Reference Data component so far.
              </div>
            </div>
            <div id="admin-content" style="padding: 4px 8px;display:none;">
              <div style="display:grid;grid-template-columns:16% 84%;padding-top:8px;">
                <label>Enabled:</label>
                <coral-checkbox [checked]="adminEnabled" (checked-changed)="adminCheckBtnHandler($event)"></coral-checkbox>
              </div>
              <hr style="margin:2px 0;background-color:blue;padding:1px;margin-top: 8px;margin-bottom: 8px;">
              <div style="padding:8px 8px 20px 8px;">
                No more setting for Admin component. User also need <i>isAdmin</i> privilege to view Admin Component
              </div>
            </div>
          </div>
        </div>
      </coral-collapse>
      <coral-collapse header="Backend Config">
        <div>
          <textarea style="height:60px; width: 100%;" [(ngModel)]="backendConfig"></textarea>
        </div>
      </coral-collapse>

    </coral-accordion>
    <div [slot]="'footer'" style="padding: 8px 25px;float: right;">
        <coral-button cta (tap)="dlgSave()">Save</coral-button>
        <coral-button style="margin-left:20px;" cta (tap)="dlgCancel()">Cancel</coral-button>
    </div>
  </coral-dialog>
  <coral-dialog id="admin_edit_confirm_dlg" header="Confirmation" (confirm)="doSave($event)">
    <p>{{confirmObj.title}}</p>
    <p>{{confirmObj.message}}</p>
  </coral-dialog>
</div>
<emerald-color-dialog [value]="colorDialogValue" [opened]="colorDlgOpened" (value-changed)="colorDlgValueChanged($event)" (opened-changed)="colorDlgOpenChanged($event)"></emerald-color-dialog>
<div *ngIf="loading" style="display:block;pointer-events: auto;z-index: 113;">
  <amber-loader  style="background-color: rgb(40, 40, 40, 0.5);width:100%;height:100%;position: fixed;z-index:113;"></amber-loader>
</div>
