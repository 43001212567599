<div>
  <div class="request-tool-bar">
    <div class="conditions-area">
      <emerald-datetime-picker [value]="yesterday" [min]='minDate' [max]='yesterday' (value-changed)='onDateChange($event)'  format="yyyy-MM-dd"
        style="height: 40px; background-color: transparent; border: none; border-bottom: 1px solid #000; color: #333; width: 100%">
      </emerald-datetime-picker>
      <coral-button (click)="onGoBtnClick()" style="width:80px;height:30px;font-size: 1.3em;background-color:blue;color:white;">Go</coral-button>
    </div>
    <div></div>
    <div></div>
  </div>
  <div class="content-tool-bar">
    <div class="content-tool-bar-widgets-line">
      <div class="filter-area">
        <quartz-label style="font-size:1.3em;font-weight: 400;padding-right:8px;">Region:</quartz-label>
        <emerald-multi-select  id="country-dashboard-region-multiselection" (value-changed)='onRegionsChange($event)' show-pills style="border-color:#b9b9b9;margin-right:8px;"></emerald-multi-select>
        <quartz-label style="font-size:1.3em;font-weight: 400;padding-right:8px;">Group:</quartz-label>
        <emerald-multi-select  id="country-dashboard-active-multiselection" (value-changed)='onActiveChange($event)' show-pills style="border-color:#b9b9b9;margin-right:8px;width:100px;"></emerald-multi-select>
        <quartz-label style="font-size:1.3em;font-weight: 400;padding-right:8px;">Country:</quartz-label>
        <emerald-multi-select  id="country-dashboard-country-multiselection" (value-changed)='onCountriesChange($event)' show-pills style="border-color:#b9b9b9;margin-right:8px;"></emerald-multi-select>
        <coral-checkbox id="country-dashboard-show-percentage-ckb" (checked-changed)="onShowPecentageCkbChange($event)" style="border-color:#b9b9b9;font-size:1.3em;font-weight: 400;padding-left:8px;">Show % change of news compared to previous period</coral-checkbox>
      </div>
      <div></div>
      <div class="export-area">
        <div>
          <coral-tab icon="excel" label="Export" (tap)="onExcelBtnClick()"></coral-tab>
          <coral-tab icon="pdf" label="Export" (tap)="onPdfBtnClick()"></coral-tab>
        </div>
      </div>
    </div>
    <div style="display:flex;"><coral-icon icon="info" style="padding-right:4px;font-size:1.2em;"></coral-icon>Note: Changing the Region or Group filter will automatically update the selected Countries.<coral-icon icon="info" style="padding-left:12px;padding-right:4px;font-size:1.2em;"></coral-icon><span>T = Total News, S = Sentiment</span></div>
  </div>
  <div style="display:block;padding-bottom:6px;">
    <emerald-grid id="country-dashboard-grid" style="margin-top:-1px;font-size:1.05em; border-bottom: 1px solid #ededed;font-weight:bold;"></emerald-grid>
    <div style="display:grid;justify-items: center;padding-top:8px;"><emerald-pagination id="dashboard-grid-pagination"></emerald-pagination></div>
  </div>
  <!--<br><br><coral-button cta (click)="test()" style="margin-top: 10px;">Click Me</coral-button>-->
</div>
<div *ngIf="loading" style="display:block;pointer-events: auto;z-index: 110;">
  <amber-loader  style="background-color: rgb(40, 40, 40, 0.5);"></amber-loader>
</div>