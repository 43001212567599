import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public readonly defaultValues:any = Object.freeze({
      companyDashboardComponentName: "Company Dashboard",
      companyComponentName: "Company",

      positiveColor: '#92d050',
      neutralColor: '#FF9817',
      negativeColor: '#ff0000',

      countryDashboardPageSize: 50,
      countryHeadlinePageSize: 100,
      companyDashboardPageSize: 50,
      companyHeadlinePageSize: 100,
    })

  constructor() { }

  getVersion() {
    return '1.0.58';
  }


}
