<div style="padding: 8px 10px;">
  <coral-tab-bar>
    <coral-tab id="admin-orgs-tab" label="Organizations" class="tab-bar-item" (tap)="tabClick($event)" active ></coral-tab>
    <coral-tab id="admin-users-tab" label="Users" class="tab-bar-item" (tap)="tabClick($event)"></coral-tab>
  </coral-tab-bar>
  <div style="padding-top:4px; padding-bottom: 8px;">
    <coral-split-button class="tab-button-bar">
      <coral-button class="tab-button-item" id="admin-add-btn" (tap)="addBtnClick($event)">Add</coral-button>
      <coral-button class="tab-button-item" id="admin-edit-btn" [disabled]="editBtnDisabled" (tap)="editBtnClick($event)">Edit</coral-button>
      <coral-button class="tab-button-item" [disabled]="removeBtnDisabled" (tap)="removeBtnClick($event)">Remove</coral-button>
    </coral-split-button>
    <emerald-grid class="admin-table" id="admin-grid"></emerald-grid>
    <div class="pagitation-div"><emerald-pagination id="admin-pagination"></emerald-pagination></div>
  </div>
</div>
<div *ngIf="loading" style="display:block;pointer-events: auto;z-index: 110;">
  <amber-loader  style="background-color: rgb(40, 40, 40, 0.5);width:100%;height:100%;position: fixed;"></amber-loader>
</div>
<app-admin-edit *ngIf="editDlgOpened" [data]="editDlgData" (success)="editDlgSaved()" (cancel)="editDlgCanceled()"></app-admin-edit>
<coral-dialog *ngIf="confirmDlgOpened" opened id="admin_confirm_dlg" header="Confirmation" (confirm)="doRemove($event)" (cancel)="confirmDlgOpened = false">
  <p>{{confirmObj.title}}</p>
  <p>{{confirmObj.message}}</p>
</coral-dialog>