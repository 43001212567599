import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import '@elf/emerald-grid';
import '@elf/emerald-pagination';
import '@elf/coral-button';
import '@elf/emerald-datetime-picker';
import '@elf/coral-slider';
import '@elf/quartz-label';
import '@elf/coral-tab';
import '@elf/emerald-multi-select';
import '@elf/amber-loader';
import '@elf/coral-checkbox';
import { CompositeGrid, CoreGrid, DataView } from '@grid/types';
import { PaginationExtension } from 'tr-grid-pagination';
import { ColumnGroupingExtension } from 'tr-grid-column-grouping';
import { ContextMenuExtension } from 'tr-grid-contextmenu';
import { info, confirm, warn, error } from '@elf/amber-notification/lib/helpers';

import '@elf/emerald-grid/themes/halo/light';
import '@elf/emerald-pagination/themes/halo/light';
import '@elf/coral-button/themes/halo/light';
import '@elf/emerald-datetime-picker/themes/halo/light';
import '@elf/coral-slider/themes/halo/light';
import '@elf/quartz-label/themes/halo/light';
import '@elf/coral-tab/themes/halo/light';
import '@elf/emerald-multi-select/themes/halo/light';
import '@elf/amber-loader/themes/halo/light';
import '@elf/coral-checkbox/themes/halo/light';
import { ActivationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { SentimentService } from 'src/service/sentiment.service';
import { forkJoin, of } from 'rxjs';
import { saveAs } from 'file-saver';
import { AppStateService } from 'src/service/app-state.service';
import { AuthService } from 'src/service/auth.service';
import { ConfigService } from 'src/service/config.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './country-dashboard.component.html',
  styleUrls: ['./country-dashboard.component.scss']
})
export class CountryDashboardComponent implements OnInit {

  yesterday: string = ''
  minDate: string = ''
  selectedDate: string = ''
  rawCountriesData: any = [];
  rawCountrySummaryData: any = {};
  countriesDropdownData: object[] = []
  regionDropdownData: object[] = []
  selectedCountries: string[] = []
  selectedRegions: string[] = []
  selectedActives: string[] = ['active'];
  tableData: object[] = []
  gridFields0: string[];
  gridFields1: string[];
  exportSkipFieds: string[];
  exprtFieldsMap: any;
  allGridData = []
  originalGridData = []
  defaultTablePageSize: number;
  tablePageSize: number;
  isShowNewsPecentageChangeData: boolean;

  defaultPositiveColor: string;
  defaultNeutralColor: string;
  defaultNegativeColor: string;
  positiveColor: string;
  neutralColor: string;
  negativeColor: string;

  counturiesDropdownInited: boolean = false;

  public loading: boolean = false;

  constructor(private router: Router,
              private sentimentService: SentimentService,
              private appStateService: AppStateService,
              private authService: AuthService,
              private configService: ConfigService) {
    this.defaultTablePageSize = this.configService.defaultValues['countryDashboardPageSize']
    this.tablePageSize = this.defaultTablePageSize;
    this.defaultPositiveColor = this.configService.defaultValues['positiveColor']
    this.defaultNeutralColor = this.configService.defaultValues['neutralColor']
    this.defaultNegativeColor = this.configService.defaultValues['negativeColor']
    this.positiveColor = this.defaultPositiveColor
    this.neutralColor = this.defaultNeutralColor
    this.negativeColor = this.defaultNegativeColor
    this.isShowNewsPecentageChangeData = false;

    this.gridFields0 = ['countryId', 'countryName', 'countryRegion', 'countryActive',
      "d1NegativeNews", "d1NeutralNews", "d1PositiveNews", "d1Total",//d1Total: {"negativePercentage", "neutralPercentage", "positivePercentage", "totalNews"}
      "d1NegativeSentiments", "d1NeutralSentiments", "d1PositiveSentiments", "d1SentimentIndicator",
      "d7NegativeNews", "d7NeutralNews", "d7PositiveNews", "d7Total",//d7Total: {"negativePercentage", "neutralPercentage", "positivePercentage", "totalNews"}
      "d7NegativeSentiments", "d7NeutralSentiments", "d7PositiveSentiments", "d7SentimentIndicator",
      "d30NegativeNews", "d30NeutralNews", "d30PositiveNews", "d30Total",//d30Total: {"negativePercentage", "neutralPercentage", "positivePercentage", "totalNews"}
      "d30NegativeSentiments", "d30NeutralSentiments", "d30PositiveSentiments", "d30SentimentIndicator",
      "countryStartDate", // country start date
    ]
    
    this.gridFields1 = ['countryId', 'countryName', 'countryRegion', 'countryActive',
      "d1NegativePercentageChange", "d1NeutralPercentageChange", "d1PositivePercentageChange", "d1TotalNewsPercentageChange",
      "d1NegativeSentiments", "d1NeutralSentiments", "d1PositiveSentiments", "d1SentimentIndicator",
      "d7NegativePercentageChange", "d7NeutralPercentageChange", "d7PositivePercentageChange", "d7TotalNewsPercentageChange",
      "d7NegativeSentiments", "d7NeutralSentiments", "d7PositiveSentiments", "d7SentimentIndicator",
      "d30NegativePercentageChange", "d30NeutralPercentageChange", "d30PositivePercentageChange", "d30TotalNewsPercentageChange",
      "d30NegativeSentiments", "d30NeutralSentiments", "d30PositiveSentiments", "d30SentimentIndicator",
      "countryStartDate", // country start date
    ]
    this.exportSkipFieds = ['countryId', "d1SentimentIndicator", "d7SentimentIndicator", "d30SentimentIndicator", "countryStartDate"]
    this.exprtFieldsMap = {
      countryName: 'Name',
      countryRegion: 'Region',
      countryActive: 'Group',
    	d1NegativeNews: 'Negative_Total',
      d1NeutralNews: 'Neutral_Total',
      d1PositiveNews: 'Positive_Total',
      d1Total: ['All_Total', 'Negative_PC',	'Neutral_PC',	'Positive_PC'],
      d1NegativePercentageChange: "Negative_Percentage_Change",
      d1NeutralPercentageChange: "Neutral_Percentage_Change",
      d1PositivePercentageChange: "Positive_Percentage_Change",
      d1TotalNewsPercentageChange: "Total_News_Percentage_Change",
      d1NegativeSentiments: 'Negative_Sentiment',
    	d1NeutralSentiments: 'Neutral_Sentiment',
      d1PositiveSentiments: 'Positive_Sentiment',

    	d7NegativeNews: 'Negative_Total',
      d7NeutralNews: 'Neutral_Total',
      d7PositiveNews: 'Positive_Total',
      d7Total: ['All_Total', 'Negative_PC',	'Neutral_PC',	'Positive_PC'],
      d7NegativePercentageChange: "Negative_Percentage_Change",
      d7NeutralPercentageChange: "Neutral_Percentage_Change",
      d7PositivePercentageChange: "Positive_Percentage_Change",
      d7TotalNewsPercentageChange: "Total_News_Percentage_Change",
      d7NegativeSentiments: 'Negative_Sentiment',
    	d7NeutralSentiments: 'Neutral_Sentiment',
      d7PositiveSentiments: 'Positive_Sentiment',

    	d30NegativeNews: 'Negative_Total',
      d30NeutralNews: 'Neutral_Total',
      d30PositiveNews: 'Positive_Total',
      d30Total: ['All_Total', 'Negative_PC',	'Neutral_PC',	'Positive_PC'],
      d30NegativePercentageChange: "Negative_Percentage_Change",
      d30NeutralPercentageChange: "Neutral_Percentage_Change",
      d30PositivePercentageChange: "Positive_Percentage_Change",
      d30TotalNewsPercentageChange: "Total_News_Percentage_Change",
      d30NegativeSentiments: 'Negative_Sentiment',
    	d30NeutralSentiments: 'Neutral_Sentiment',
      d30PositiveSentiments: 'Positive_Sentiment',

    }

    this.yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
    this.minDate = moment().subtract(1 + 335, 'days').format('YYYY-MM-DD');
    this.selectedDate = this.yesterday;
    //console.log(this.yesterday, this.minDate)
  }

  ngOnInit(): void {
    this._setTablePageSize()

    let userInfo = this.authService.userInfo
    let commonObj = userInfo['frontend_config'] && userInfo['frontend_config']['common']
    this.positiveColor = commonObj && commonObj['positiveColor'] ? commonObj && commonObj['positiveColor'] : this.defaultPositiveColor;
    this.neutralColor  = commonObj && commonObj['neutralColor'] ? commonObj && commonObj['neutralColor'] : this.defaultNeutralColor;
    this.negativeColor  = commonObj && commonObj['negativeColor'] ? commonObj && commonObj['negativeColor'] : this.defaultNegativeColor;

    this.loading = true

    let countriesRequest$ = this.sentimentService.requestCountries();
    let countriesSummaryRequest$ = this.sentimentService.requestCountriesSummary();
    forkJoin([countriesRequest$, countriesSummaryRequest$]).subscribe({
      next: (value:any) => {
        this.appStateService.setCountriesData(value[0])
        this.rawCountriesData = value[0]
        this.rawCountrySummaryData = value[1]
        this._populateFiltersFromResponse(value[0])
        this._populateTableFromResponse(value[1])
        this.loading = false
      },
      error: (e:any) => {
        this.loading = false;
        error(e.message, 6000)
      }
    })
  }

  _setActiveDropdown() {
    const activeSelect:any = document.getElementById("country-dashboard-active-multiselection");
    activeSelect.data = [
      {
        'value': 'active',
        'label': 'Active',
        'selected': true,
      },
      {
        'value': 'others',
        'label': 'Others',
      },
    ];
    this.selectedActives = ['active']
  }

  _setTablePageSize() {
    let userInfo = this.authService.userInfo
    let commonObj = userInfo['frontend_config'] && userInfo['frontend_config']['common']
    this.tablePageSize = commonObj && commonObj['countryDashboardPageSize'] ? commonObj && commonObj['countryDashboardPageSize'] : this.defaultTablePageSize;
  }

  _populateFiltersFromResponse(countries:any) {
    /*
    {name: "Australia", id: 12, active: true, region: "Asia / Pacific", startDate: "2021-07-01"}
    */
    this.countriesDropdownData = []
    this.regionDropdownData = []
    this.selectedCountries = []
    this.selectedRegions = []
    if (countries) {
      let regionSet = new Set();
      countries.forEach((country:any) => {
        regionSet.add(country.region)
        this.countriesDropdownData.push( {
          label: `${country.name} (${country.region}, ${country.active == true ? 'Active' : 'Others'})` ,
          value: country.id + '',
          //selected: country.active == true,
        })
        if (country.active == true) {
          this.selectedCountries.push(country.id + '')
        }
      })
      regionSet.forEach((region:any) => {
        this.regionDropdownData.push({
          label: region,
          value: region,
          selected: true
        })
        this.selectedRegions.push(region)
      })
      this.regionDropdownData.sort((a:any, b:any) => a.value >= b.value ? 1 : -1)
    }
    if (this.countriesDropdownData.length > 0) {
      this.counturiesDropdownInited = true;
    }
    const dropdown:any = document.getElementById("country-dashboard-country-multiselection");
    dropdown.data = this.countriesDropdownData;
    dropdown.values = this.selectedCountries;
    const regionDropdown:any = document.getElementById("country-dashboard-region-multiselection");
    regionDropdown.data = this.regionDropdownData
    this._setActiveDropdown()
  }

  _tableConfigObj() {
    const positiveStyle =  {'color':this.positiveColor, "font-weight": 'normal', 'font-size':'1.1em'}
    const positiveWithRightBorderStyle =  {'color':this.positiveColor, "font-weight": 'normal', 'font-size':'1.1em', 'border-right':'1px solid lightgray'}
    const neutralStyle = {'color':this.neutralColor, "font-weight": 'normal', 'font-size':'1.1em'}
    const negativeStyle = {'color':this.negativeColor, "font-weight": 'normal', 'font-size':'1.1em'}
    let linkFormatter = {
      render: function(){},
      bind: (rowIndex:any, colIndex:any, value:any, cell:any) => {
        var content = cell.getContent();
        content = document.createElement("a");
        content.textContent = value
        content.title = value;
        content.addEventListener('click', () => {
          const grid: any = document.getElementById("country-dashboard-grid");
          const allRowData = grid.api.getDataView().getAllRowData();
          let rowData:any = allRowData[rowIndex]
          //console.log('rowData:', rowData)
          let countryName = rowData['countryName']
          let countryStartDate = rowData['countryStartDate']
          if (moment(countryStartDate, 'YYYY-MM-DD').diff(this.selectedDate, 'days') > 0) {
            warn(`Date ${this.selectedDate} is before ${countryName} start date ${countryStartDate}`, 6000);
            return;
          }
          let countryDashboardSelection = {countryId: rowData['countryId'], countryStartDate: rowData['countryStartDate'], selectedDate: this.selectedDate}
          this.router.navigate(['/country', countryDashboardSelection])
        })
        content.style['cursor'] = "pointer";
        cell.setContent(content);
      },
      renderTitle: (columnIndex:any, cell:any, columnDef:any) => {
        cell.setStyle('background-color', '#dddddd')
        cell.setStyle('border-top', '#f1eeee 1px solid')
        cell.setContent(columnDef.title);
      }
    }
    let textHighlightFommater = {
      render: function(){},
      bind: (rowIndex:any, colIndex:any, value:any, cell:any) => {
        var content = cell.getContent();
        content = document.createElement("span");
        content.textContent = value
        content.title = value;
        const grid: any = document.getElementById("country-dashboard-grid");
        let allRowData = []
        if (grid && grid.api) {
          allRowData = grid.api.getDataView().getAllRowData();
        } else {
          allRowData = this.originalGridData;
        }
        let rowData:any = allRowData[rowIndex]
        if (colIndex == 7 && rowData['d1SentimentIndicator'] == -1
          || colIndex == 8 && rowData['d1SentimentIndicator'] == 0
          || colIndex == 9 && rowData['d1SentimentIndicator'] == 1
          || colIndex == 14 && rowData['d7SentimentIndicator'] == -1
          || colIndex == 15 && rowData['d7SentimentIndicator'] == 0
          || colIndex == 16 && rowData['d7SentimentIndicator'] == 1
          || colIndex == 21 && rowData['d30SentimentIndicator'] == -1
          || colIndex == 22 && rowData['d30SentimentIndicator'] == 0
          || colIndex == 23 && rowData['d30SentimentIndicator'] == 1) {
              cell.setStyle('font-weight', 'bold')
              cell.setStyle('text-decoration-line', 'underline')
              cell.setStyle('text-decoration-thickness', '3px')
        } else {
          cell.setStyle("font-weight", 'normal')
          cell.setStyle('text-decoration-line', 'none')
          cell.setStyle('text-decoration-thickness', 'auto')
        }
        cell.setContent(content);
        cell.setAttribute('title', value)
      },
      renderTitle: (columnIndex:any, cell:any, columnDef:any) => {
        cell.setStyle('background-color', '#dddddd')
        cell.setStyle("color", 'black')
        cell.setStyle('font-weight', 'normal')
        cell.setStyle('border-top', '#f1eeee 1px solid')
        cell.setContent(columnDef.title);
      }
    }
    let ratioBarFormatter = {
      render: function(){},
      bind: (rowIndex:any, colIndex:any, value:any, cell:any) => {
        //{"negativePercentage", "neutralPercentage", "positivePercentage", "totalNews"}
        if (!value) {
          console.error(`value can not be null for table cell: ${rowIndex}, ${colIndex}`)
          return;
        }
        if (value.positivePercentage == 0 && value.neutralPercentage == 0 && value.negativePercentage == 0) {
          var content = cell.getContent();
          content = document.createElement("span");
          content.classList.add("sentiment-visualization"); // Static styling
          let ratioSpan: any = document.createElement("span")
          ratioSpan.style['display'] = 'inline-block'
          ratioSpan.style['width'] = '100px'
          // total news number
          let totalSpan:any = document.createElement("span");
          totalSpan.style['display'] = 'inline-block'
          totalSpan.style['width'] = '20px'
          totalSpan.style['font-size'] = '14px'
          totalSpan.style['font-weight'] = 'normal'
          totalSpan.textContent = 0
          totalSpan.title = 0
          content.appendChild(ratioSpan);
          content.appendChild(totalSpan);
          cell.setContent(content);
          return;
        }
        const positivePercentage = value.positivePercentage;
        const neutralPercentage = value.neutralPercentage;
        const negativePercentage = value.negativePercentage;
        const totalNews = value.totalNews;

        let setSpanStyle = (spanElement:any, width:any, bgColor:any) => {
          spanElement.style.width = width
          spanElement.style.display = 'inline-flex';
          spanElement.style.height = '20px';
          spanElement.style['text-overflow'] = 'ellipsis';
          spanElement.style.overflow = 'hidden';
          spanElement.style['align-items'] = 'center';
          spanElement.style['justify-content'] = 'center';

          spanElement.style.border = 'solid #cfcfcf';
          spanElement.style['border-width'] = '1px 0 1px 0';
          spanElement.style['background-color'] = bgColor;
          spanElement.style['font-size'] = '10px';
          spanElement.style['text-align'] = 'center';
        }
        var content = cell.getContent();
        content = document.createElement("span");
        content.classList.add("sentiment-visualization"); // Static styling
        // content.style['min-width'] = '120px';
        // content.style['display'] = 'grid';
        // content.sytle['grid-template-columns'] = "80% 20%";
        let ratioSpan: any = document.createElement("span")
        ratioSpan.style['display'] = 'inline-block'
        ratioSpan.style['width'] = '100px'
        // negative percentage span
        let negativePercentageSpan:any = document.createElement("span");
        setSpanStyle(negativePercentageSpan, negativePercentage * 0.8 + "px", this.negativeColor)
        negativePercentageSpan.textContent = (negativePercentage) + '%';
        if (negativePercentage < 20) {
          negativePercentageSpan.style['color'] = this.negativeColor
        }
        negativePercentageSpan.title = 'Negative: ' + (negativePercentage) + '%'
        // neutral percentage span
        let neutralPercentageSpan:any = document.createElement("span");
        setSpanStyle(neutralPercentageSpan, neutralPercentage * 0.8 + "px", this.neutralColor)
        neutralPercentageSpan.textContent = (neutralPercentage) + '%'
        if (neutralPercentage < 20) {
          neutralPercentageSpan.style['color'] = this.neutralColor
        }
        neutralPercentageSpan.title = 'Neutral: ' + (neutralPercentage) + '%'
        // positive percentage span
        let positivePercentageSpan:any = document.createElement("span");
        setSpanStyle(positivePercentageSpan, positivePercentage * 0.8 + "px", this.positiveColor)
        positivePercentageSpan.textContent = (positivePercentage) + '%'
        if (positivePercentage < 20) {
          positivePercentageSpan.style['color'] = this.positiveColor
        }
        positivePercentageSpan.title = 'Positive: ' + (positivePercentage) + '%'
        // total news number
        let totalSpan:any = document.createElement("span");
        totalSpan.style['display'] = 'inline-block'
        totalSpan.style['width'] = '32px'
        //totalSpan.style['padding-left'] = '4px';
        totalSpan.style['font-size'] = '14px'
        totalSpan.style['font-weight'] = 'normal'
        totalSpan.textContent = totalNews
        totalSpan.title = `Total News: ${totalNews}`

        ratioSpan.appendChild(negativePercentageSpan);
        ratioSpan.appendChild(neutralPercentageSpan);
        ratioSpan.appendChild(positivePercentageSpan);

        content.appendChild(ratioSpan);
        content.appendChild(totalSpan);
        content.addEventListener('click', () => {
          const grid: any = document.getElementById("country-dashboard-grid");
          const allRowData = grid.api.getDataView().getAllRowData();
          let rowData:any = allRowData[rowIndex]
          //console.log('rowData:', rowData)
          let countryName = rowData['countryName']
          let countryStartDate = rowData['countryStartDate']
          if (moment(countryStartDate, 'YYYY-MM-DD').diff(this.selectedDate, 'days') > 0) {
            warn(`Date ${this.selectedDate} is before ${countryName} start date ${countryStartDate}`, 6000);
            return;
          }
          let days = 1;
          switch(colIndex) {
            case 6:
              days = 1;
              break;
            case 13:
              days = 7;
              break;
            case 20:
              days = 30;
              break;
            default:
              days = 1;
          }
          let countryDashboardSelection = {countryId: rowData['countryId'], countryStartDate: rowData['countryStartDate'], selectedDate: this.selectedDate, days: days}
          this.router.navigate(['/country', countryDashboardSelection])
        })
        content.style['cursor'] = "pointer";
        cell.setContent(content);
      },
      renderTitle: (columnIndex:any, cell:any, columnDef:any) => {
        cell.setStyle('background-color', '#dddddd')
        cell.setStyle('font-weight', 'normal')
        cell.setStyle('font-size', '1.1em')
        cell.setStyle('border-top', '#f1eeee 1px solid')
        cell.setContent(columnDef.title);
      }
    };
    let ratioBarSort = (a:any, b:any, order:any) => {
      let totalNewsA = a.totalNews
      let totalNewsB = b.totalNews
      if ( order == 1) {
        return totalNewsA - totalNewsB;
      } else {
        return totalNewsB - totalNewsA;
      }
    }
    let titleFormatter = {
      renderTitle: (columnIndex:any, cell:any, columnDef:any) => {
        cell.setStyle('background-color', '#dddddd')
        cell.setStyle("color", 'black')
        cell.setStyle('font-weight', 'normal')
        cell.setStyle('border-top', '#f1eeee 1px solid')
        cell.setContent(columnDef.title);
      }
    }
    let percentageFormatter = {
      render: function(){},
      bind: (rowIndex:any, colIndex:any, value:any, cell:any) => {
        //let content = cell.getContent();
        let content = document.createElement("span");
        content.textContent = Math.round(value * 100) + '%'
        cell.setContent(content);
      },
      renderTitle: (columnIndex:any, cell:any, columnDef:any) => {
        cell.setStyle('background-color', '#dddddd')
        cell.setStyle("color", 'black')
        cell.setStyle("font-size", '0.9em')
        cell.setStyle('font-weight', 'normal')
        cell.setStyle('border-top', '#f1eeee 1px solid')
        cell.setContent(columnDef.title);
      }
    }
    let fields = this.isShowNewsPecentageChangeData ? this.gridFields1 :this.gridFields0;
    const columns:any = [
      { id: 'c1', title: 'Country', field: fields[1], minWidth: 100, formatter: linkFormatter },
      { id: 'c2', title: 'Region', field: fields[2], alignment: "center", minWidth: 90, formatter: titleFormatter},
      { id: 'c3', title: 'Group', field: fields[3], alignment: "center", minWidth: 70, styles: {'color': 'dark', 'border-right':'1px solid lightgray'}, formatter: titleFormatter},
      // d1
      ]
    if (this.isShowNewsPecentageChangeData) {
      columns.push(...[{ id: 'c4', title: 'ΔT (-)', field: fields[4], alignment: 'right', styles: negativeStyle, formatter: percentageFormatter},
      { id: 'c5', title: 'ΔT (=)', field: fields[5], alignment: 'right', styles: neutralStyle, formatter: percentageFormatter },
      { id: 'c6', title: 'ΔT (+)', field: fields[6], alignment: 'right', styles: positiveStyle, formatter: percentageFormatter },
        { id: 'c7', title: 'ΔTotal News', field: fields[7], minWidth: 160, alignment: "center", styles: {"font-weight": 'bold'}, formatter: percentageFormatter}])
    } else {
      columns.push(...[{ id: 'c4', title: 'T (-)', field: fields[4], alignment: 'right', styles: negativeStyle, formatter: titleFormatter},
      { id: 'c5', title: 'T (=)', field: fields[5], alignment: 'right', styles: neutralStyle, formatter: titleFormatter },
      { id: 'c6', title: 'T (+)', field: fields[6], alignment: 'right', styles: positiveStyle, formatter: titleFormatter },
      { id: 'c7', title: 'Total News', field: fields[7], minWidth: 160, alignment: "center", styles: {"font-weight": 'bold'},
      formatter: ratioBarFormatter, sortLogic: ratioBarSort }])
    }
    columns.push(...[
      { id: 'c8', title: 'S (-)', field: fields[8], alignment: 'right', styles: negativeStyle, formatter: textHighlightFommater },
      { id: 'c9', title: 'S (=)', field: fields[9], alignment: 'right', styles: neutralStyle, formatter: textHighlightFommater },
      { id: 'c10', title: 'S (+)', field: fields[10], alignment: 'right', styles: positiveWithRightBorderStyle, formatter: textHighlightFommater },
      // d7
      ])
    if (this.isShowNewsPecentageChangeData) {
      columns.push(...[{ id: 'c11', title: 'ΔT (-)', field: fields[12], alignment: 'right', styles: negativeStyle, formatter: percentageFormatter },
      { id: 'c12', title: 'ΔT (=)', field: fields[13], alignment: 'right', styles: neutralStyle, formatter: percentageFormatter },
      { id: 'c13', title: 'ΔT (+)', field: fields[14], alignment: 'right', styles: positiveStyle, formatter: percentageFormatter },
      { id: 'c14', title: 'ΔTotal News', field: fields[15], minWidth: 160, alignment: "center", styles: {"font-weight": 'bold'}, formatter: percentageFormatter}])
    } else {
      columns.push(...[{ id: 'c11', title: 'T (-)', field: fields[12], alignment: 'right', styles: negativeStyle, formatter: titleFormatter },
      { id: 'c12', title: 'T (=)', field: fields[13], alignment: 'right', styles: neutralStyle, formatter: titleFormatter },
      { id: 'c13', title: 'T (+)', field: fields[14], alignment: 'right', styles: positiveStyle, formatter: titleFormatter },
      { id: 'c14', title: 'Total News', field: fields[15], minWidth: 160, alignment: "center", styles: {"font-weight": 'bold'},
        formatter: ratioBarFormatter, sortLogic: ratioBarSort }])
    }
    columns.push(...[
      { id: 'c15', title: 'S (-)', field: fields[16], alignment: 'right', styles: negativeStyle, formatter: textHighlightFommater },
      { id: 'c16', title: 'S (=)', field: fields[17], alignment: 'right', styles: neutralStyle, formatter: textHighlightFommater },
      { id: 'c17', title: 'S (+)', field: fields[18], alignment: 'right', styles: positiveWithRightBorderStyle, formatter: textHighlightFommater },
      // d30
      ])
    if (this.isShowNewsPecentageChangeData) {
      columns.push(...[{ id: 'c18', title: 'ΔT (-)', field: fields[20], alignment: 'right', styles: negativeStyle, formatter: percentageFormatter },
      { id: 'c19', title: 'ΔT (=)', field: fields[21], alignment: 'right', styles: neutralStyle, formatter: percentageFormatter },
      { id: 'c20', title: 'ΔT (+)', field: fields[22], alignment: 'right', styles: positiveStyle, formatter: percentageFormatter },
      { id: 'c21', title: 'ΔTotal News', field: fields[23], minWidth: 160, alignment: "center", styles: {"font-weight": 'bold'}, formatter: percentageFormatter}])
    } else {
      columns.push(...[{ id: 'c18', title: 'T (-)', field: fields[20], alignment: 'right', styles: negativeStyle, formatter: titleFormatter },
      { id: 'c19', title: 'T (=)', field: fields[21], alignment: 'right', styles: neutralStyle, formatter: titleFormatter },
      { id: 'c20', title: 'T (+)', field: fields[22], alignment: 'right', styles: positiveStyle, formatter: titleFormatter },
      { id: 'c21', title: 'Total News', field: fields[23], minWidth: 160, alignment: "center", styles: {"font-weight": 'bold'},
        formatter: ratioBarFormatter, sortLogic: ratioBarSort }])
    }
    columns.push(...[
      { id: 'c22', title: 'S (-)', field: fields[24], alignment: 'right', styles: negativeStyle, formatter: textHighlightFommater },
      { id: 'c23', title: 'S (=)', field: fields[25], alignment: 'right', styles: neutralStyle, formatter: textHighlightFommater },
      { id: 'c24', title: 'S (+)', field: fields[26], alignment: 'right', styles: positiveStyle, formatter: textHighlightFommater },
    ]);

    let onPageChanged = () => {
      console.log('page changed')
    }

    const config: any = {
      rowHeight: 40,
      sorting: {
        sortableColumns: true,
        multiColumn: true,
        threeStatesSorting: true
      },
      columns: columns,
      columnGrouping: [
          { id: "d1group", title: "1 Day", children: ["c4", "c5", "c6", "c7", "c8", "c9", "c10"], alignment: "center",
            render: function(e:any) {
              e.cell.setStyle("color", "black");
            }},
          { id: "d7group", title: "7 Days", children: ["c11", "c12", "c13","c14",  "c15", "c16", "c17"], alignment: "center",
            render: function(e:any) {
              e.cell.setStyle("color", "black");
            }},
          { id: "d30group", title: "30 Days", children: ["c18", "c19", "c20", "c21",  "c22", "c23", "c24"], alignment: "center",
            render: function(e:any) {
              e.cell.setStyle("color", "black");
          }},
      ],
      dataModel: {
        fields: fields,
        data: []
      },
      whenDefined: function (e: any) {
        let api: CompositeGrid = e.api as CompositeGrid;
        // let core: CoreGrid = api.getCoreGrid();
        // let dv: DataView = api.getDataView();
        //console.log('grid instance:', e)
        // Do something with grid with api...
      },
      pagination: {
          element: "dashboard-grid-pagination",
          page: 1,
          pageSize: this.tablePageSize,
          pageChanged: onPageChanged
      },
      extensions: [new PaginationExtension(), new ColumnGroupingExtension(), new ContextMenuExtension()],
      contextMenu: this.contextMenuModel,
    };

    return config
  }

  _tableData(countriesSummary: any): any {
    this.allGridData = []
    this.originalGridData = []
    if (countriesSummary && countriesSummary.fields && countriesSummary.items && countriesSummary.items.length > 0) {
      let allTableData:any = []
      // calculate indexes
      let fields = countriesSummary.fields
      let negativeNewsIndex = fields.indexOf('negativeNews')
      let neutralNewsIndex = fields.indexOf('neutralNews')
      let positiveNewsIndex = fields.indexOf('positiveNews')
      let totalNewsIndex = fields.indexOf('totalNews')
      let negativePercentageChangeIndex = fields.indexOf('negativePercentageChange')
      let neutralPercentageChangeIndex = fields.indexOf('neutralPercentageChange')
      let positivePercentageChangeIndex = fields.indexOf('positivePercentageChange')
      let totalNewsPercentageChange = fields.indexOf('totalNewsPercentageChange')
      let negativePercentageIndex = fields.indexOf('negativePercentage')
      let neutralPercentageIndex = fields.indexOf('neutralPercentage')
      let positivePercentageIndex = fields.indexOf('positivePercentage')
      let negativeSentimentsIndex = fields.indexOf('negativeSentiments')
      let neutralSentimentsIndex = fields.indexOf('neutralSentiments')
      let positiveSentimentsIndex = fields.indexOf('positiveSentiments')
      let sentimentIndicatorIndex = fields.indexOf('sentimentIndicator')
      countriesSummary.items.forEach((item:any) => {
        let data:any = {};
        data['countryId'] = item.country.id + '';
        data['countryName'] = item.country.name;
        data['countryRegion'] = item.country.region;
        data['countryActive'] = item.country.active ? 'Y' : 'N';
        data['countryStartDate'] = item.country.startDate
        // d1
        if (this.isShowNewsPecentageChangeData) {
          data["d1NegativePercentageChange"] = item.sentiments.day[negativePercentageChangeIndex];
          data["d1NeutralPercentageChange"] = item.sentiments.day[neutralPercentageChangeIndex];
          data["d1PositivePercentageChange"] = item.sentiments.day[positivePercentageChangeIndex];
          data["d1TotalNewsPercentageChange"] = item.sentiments.day[totalNewsPercentageChange];
        } else {
          data["d1NegativeNews"] = item.sentiments.day[negativeNewsIndex];
          data["d1NeutralNews"] = item.sentiments.day[neutralNewsIndex];
          data["d1PositiveNews"] = item.sentiments.day[positiveNewsIndex];
          data["d1Total"] = {
            "negativePercentage":item.sentiments.day[negativePercentageIndex],
            "neutralPercentage":item.sentiments.day[neutralPercentageIndex],
            "positivePercentage":item.sentiments.day[positivePercentageIndex],
            "totalNews":item.sentiments.day[totalNewsIndex]
          }
        }        
        data["d1NegativeSentiments"] = item.sentiments.day[negativeSentimentsIndex];
        data["d1NeutralSentiments"] = item.sentiments.day[neutralSentimentsIndex];
        data["d1PositiveSentiments"] = item.sentiments.day[positiveSentimentsIndex];
        data["d1SentimentIndicator"] = item.sentiments.day[sentimentIndicatorIndex];
        // d7
        if (this.isShowNewsPecentageChangeData) {
          data["d7NegativePercentageChange"] = item.sentiments.week[negativePercentageChangeIndex];
          data["d7NeutralPercentageChange"] = item.sentiments.week[neutralPercentageChangeIndex];
          data["d7PositivePercentageChange"] = item.sentiments.week[positivePercentageChangeIndex];
          data["d7TotalNewsPercentageChange"] = item.sentiments.week[totalNewsPercentageChange];
        } else {
          data["d7NegativeNews"] = item.sentiments.week[negativeNewsIndex];
          data["d7NeutralNews"] = item.sentiments.week[neutralNewsIndex];
          data["d7PositiveNews"] = item.sentiments.week[positiveNewsIndex];
          data["d7Total"] = {
            "negativePercentage":item.sentiments.week[negativePercentageIndex],
            "neutralPercentage":item.sentiments.week[neutralPercentageIndex],
            "positivePercentage":item.sentiments.week[positivePercentageIndex],
            "totalNews":item.sentiments.week[totalNewsIndex]
          }
        }
        data["d7NegativeSentiments"] = item.sentiments.week[negativeSentimentsIndex];
        data["d7NeutralSentiments"] = item.sentiments.week[neutralSentimentsIndex];
        data["d7PositiveSentiments"] = item.sentiments.week[positiveSentimentsIndex];
        data["d7SentimentIndicator"] = item.sentiments.week[sentimentIndicatorIndex];
        // d30
        if (this.isShowNewsPecentageChangeData) {
          data["d30NegativePercentageChange"] = item.sentiments.month[negativePercentageChangeIndex];
          data["d30NeutralPercentageChange"] = item.sentiments.month[neutralPercentageChangeIndex];
          data["d30PositivePercentageChange"] = item.sentiments.month[positivePercentageChangeIndex];
          data["d30TotalNewsPercentageChange"] = item.sentiments.month[totalNewsPercentageChange];
        } else {
          data["d30NegativeNews"] = item.sentiments.month[negativeNewsIndex];
          data["d30NeutralNews"] = item.sentiments.month[neutralNewsIndex];
          data["d30PositiveNews"] = item.sentiments.month[positiveNewsIndex];
          data["d30Total"] = {
            "negativePercentage":item.sentiments.month[negativePercentageIndex],
            "neutralPercentage":item.sentiments.month[neutralPercentageIndex],
            "positivePercentage":item.sentiments.month[positivePercentageIndex],
            "totalNews":item.sentiments.month[totalNewsIndex]
          }
        }
        data["d30NegativeSentiments"] = item.sentiments.month[negativeSentimentsIndex];
        data["d30NeutralSentiments"] = item.sentiments.month[neutralSentimentsIndex];
        data["d30PositiveSentiments"] = item.sentiments.month[positiveSentimentsIndex];
        data["d30SentimentIndicator"] = item.sentiments.month[sentimentIndicatorIndex];

        allTableData.push(data)
      })

      this.allGridData = allTableData
      this.originalGridData = this.allGridData.filter((item:any) => this.selectedCountries.indexOf(item['countryId']) != -1)
      return this.originalGridData
    } else {
      warn("There is no news content for the selected date.", 6000)
      return []
    }
  }

  _populateTableFromResponse(countriesSummary?: any) {
    if (!countriesSummary) {
      if (this.rawCountrySummaryData && this.rawCountrySummaryData.fields && this.rawCountrySummaryData.items && this.rawCountrySummaryData.items.length > 0) {
        countriesSummary = this.rawCountrySummaryData
      } else {
        warn('No valid country summary data.', 6000)
        return;
      }
    }
    let tableData:any = this._tableData(countriesSummary);
    let config = this._tableConfigObj()
    config.dataModel.data = tableData;

    const grid: any = document.getElementById("country-dashboard-grid");
    grid.config = config;
  }

  ngAfterViewInit(): void {
  }

  onDateChange(event: any) {
    this.selectedDate = event.target.value
  }

  onRegionsChange(event: any) {
    this.selectedRegions = event.target.values;
    let selectedCountries:any = [];
    this.selectedRegions.forEach((region:any) => {
      let cs =  this.rawCountriesData.filter((c:any) => c.region == region)
      if (this.selectedActives.length != 2) {
        cs = cs.filter((c:any) => {
          let active = c.active ? 'active' : 'others'
          return this.selectedActives.indexOf(active) != -1
        })
      }
      cs.forEach((c:any) => {
        selectedCountries.push(c.id + '')
      })
    })
    this.selectedCountries = selectedCountries;
    this._updateCountryDropdownValues();
    this._updateGrid();

    /*
    let targetValues = event.target.values;
    let addRegions:any = [];
    let removeRegions:any = [];
    this.selectedRegions.forEach((region:any) => {
      if (targetValues.indexOf(region) == -1) {
        removeRegions.push(region)
      }
    })
    targetValues.forEach((region:string) => {
      if (this.selectedRegions.indexOf(region) == -1) {
        addRegions.push(region)
      }
    });
    if(addRegions.length == 0 && removeRegions.length == 0) {
      return; // values doesn't change, do nothing
    }
    let modified:boolean = false;
    if (addRegions.length > 0) {
      addRegions.forEach((region:string) => {
        let cs =  this.rawCountriesData.filter((c:any) => c.region == region)
        if (this.selectedActives.length != 2) {
          cs = cs.filter((c:any) => {
            let active = c.active ? 'active' : 'others'
            return this.selectedActives.indexOf(active) != -1
          })
        }
        cs.forEach((c:any) => {
          this.selectedCountries.push(c.id + '')
          modified = true;
        })
      })
    }
    if (removeRegions.length > 0) {
      removeRegions.forEach((region:string) => {
        let cs = this.rawCountriesData.filter((c:any) => c.region == region)
        if (this.selectedActives.length != 2) {
          cs = cs.filter((c:any) => {
            let active = c.active ? 'active' : 'others'
            return this.selectedActives.indexOf(active) != -1
          })
        }
        let tc = this.selectedCountries.filter((cid:any) => cs.findIndex((c:any)=>(c.id + '')==cid) == -1)
        if (tc.length != this.selectedCountries.length) {
          this.selectedCountries = tc;
          modified = true
        }
      })
    }

    this.selectedRegions = targetValues;
    if (modified) {
      this._updateCountryDropdownValues();
      this._updateGrid()
    }
    */
  }

  onActiveChange(event: any) {
    this.selectedActives = event.target.values;
    let selectedCountries:any = []
    this.selectedActives.forEach((a:string) => {
      let active: boolean = a == 'active' ? true : false;
      let cs =  this.rawCountriesData.filter((c:any) => c.active == active)
      cs = cs.filter((c:any) => this.selectedRegions.indexOf(c.region) != -1)
      cs.forEach((c:any) => {
        selectedCountries.push(c.id + '')
      })
    })
    this.selectedCountries = selectedCountries;
    this._updateCountryDropdownValues();
    this._updateGrid();

    /*
    let targetValues = event.target.values

    let removeItems:any = [];
    let addItems:any = [];
    targetValues.forEach((a:string) => {
      if(this.selectedActives.indexOf(a) == -1) {
        addItems.push(a)
      }
    })
    this.selectedActives.forEach((a:string) => {
      if (targetValues.indexOf(a) == -1) {
        removeItems.push(a)
      }
    })
    if (addItems.length == 0 && removeItems.length == 0) {
      return; // values doesn't change, do nothing
    }
    let modified:boolean = false;
    if (addItems.length > 0) {
      addItems.forEach((a:string) => {
        let active: boolean = a == 'active' ? true : false;
        let cs =  this.rawCountriesData.filter((c:any) => c.active == active)
        cs = cs.filter((c:any) => this.selectedRegions.indexOf(c.region) != -1)
        cs.forEach((c:any) => {
          this.selectedCountries.push(c.id + '')
          modified = true;
        })
      })
    }
    if (removeItems.length > 0) {
      removeItems.forEach((a:string) => {
        let active: boolean = a == 'active' ? true : false;
        let cs = this.rawCountriesData.filter((c:any) => c.active == active)
        cs = cs.filter((c:any) => this.selectedRegions.indexOf(c.region) != -1)
        let tc = this.selectedCountries.filter((cid:any) => cs.findIndex((c:any)=>(c.id + '')==cid) == -1)
        if (tc.length != this.selectedCountries.length) {
          this.selectedCountries = tc;
          modified = true
        }
      })
    }

    this.selectedActives = targetValues;
    if (modified) {
      this._updateCountryDropdownValues();
      this._updateGrid()
    }

    this.selectedActives = targetValues;
    */
  }

  onCountriesChange(event: any) {
    this.selectedCountries = event.target.values

    this._updateGrid();
  }

  _updateCountryDropdownValues() {
    const countryDropdown:any = document.getElementById("country-dashboard-country-multiselection");
    countryDropdown.data = [...this.countriesDropdownData];
    countryDropdown.values = [...this.selectedCountries]
  }

  _updateGrid() {
    this.originalGridData = this.allGridData.filter((item:any) => this.selectedCountries.indexOf(item['countryId']) != -1)

    let config = this._tableConfigObj()
    config.dataModel.data = this.originalGridData;

    const grid: any = document.getElementById("country-dashboard-grid");
    grid.config = config;
  }

  _resetFilterDropdwon() {
    this.selectedCountries = []
    this.selectedRegions = []
    this.countriesDropdownData = []

    const countryDropdown:any = document.getElementById("country-dashboard-country-multiselection");
    countryDropdown.data = [];
    const regionDropdown:any = document.getElementById("country-dashboard-region-multiselection");
    regionDropdown.data = [];
  }

  _resetTable() {
    this.originalGridData = []
    this.allGridData = []
    this.rawCountrySummaryData = {}

    let config = this._tableConfigObj()
    config.dataModel.data = [];

    const grid: any = document.getElementById("country-dashboard-grid");
    grid.config = config;
  }

  onGoBtnClick() {
    this.loading = true
    this._resetTable()

    let countriesRequest$;
    if (!this.counturiesDropdownInited) {
      countriesRequest$ = this.sentimentService.requestCountries();
    } else {
      countriesRequest$ = of([])
    }
    let countriesSummaryRequest$ = this.sentimentService.requestCountriesSummary(this.selectedDate);
    forkJoin([countriesRequest$, countriesSummaryRequest$]).subscribe({
      next: (value:any) => {
        if (!this.counturiesDropdownInited) {
          this.rawCountriesData = value[0]
          this._populateFiltersFromResponse(value[0])
        }
        this.rawCountrySummaryData = value[1]
        this._populateTableFromResponse(value[1])
        this.loading = false
      },
      error: (e:any) => {
        this.loading = false;
        error(e.message, 6000)
      }
    })
  }

  onExcelBtnClick() {
    let curTime = moment().format("YYMMDDHHmm");
    let exportExcelFileName = `Country_Dashboard_${curTime}.xlsx`;

    let wb = XLSX.utils.book_new();
    let ws_name = "Countries";

    let ws_data = []

    let fields = this.isShowNewsPecentageChangeData ? this.gridFields1 : this.gridFields0;
    let exportFields = fields.filter(f => this.exportSkipFieds.indexOf(f) == -1)
    // push parameters
    ws_data.push(['Date', this.selectedDate])
    ws_data.push(['Show % change of news compared to previous period', this.isShowNewsPecentageChangeData])
    ws_data.push(['', ''])
    // push grid headers
    if (this.isShowNewsPecentageChangeData) {
      ws_data.push(['Name', 'Region', 'Group', '1D', '', '', '', '', '', '', '7D', '', '', '', '', '', '', '30D', '', '', '', '', '', ''])
    } else {
      ws_data.push(['Name', 'Region', 'Group', '1D', '', '', '', '', '', '', '', '', '', '7D', '', '', '', '', '', '', '', '', '', '30D', '', '', '', '', '', '', '', '', ''])
    }
    let fieldNames:any = []
    exportFields.forEach(f => {
      if (f != "d1Total" && f != 'd7Total' && f != 'd30Total') {
        fieldNames.push(this.exprtFieldsMap[f])
      } else {
        fieldNames.push(...this.exprtFieldsMap[f])
      }
    })
    ws_data.push(fieldNames)
    // push grid data
    //console.log(this.allGridData)
    // if (f.endsWith('PercentageChange')) { // format percentage change data
    //   arrayData.push(record[f].toFixed(4));
    // } else {
    //   arrayData.push(record[f])
    // }
    this.allGridData.forEach((record:any) => {
      let arrayData:any = []
      exportFields.map(f => {
        if (f != "d1Total" && f != 'd7Total' && f != 'd30Total') {
          if (f.endsWith('PercentageChange')) {
            arrayData.push(record[f].toFixed(2))
          } else {
            arrayData.push(record[f])
          }
        } else {
          arrayData.push(record[f]['totalNews']);
          arrayData.push(record[f]['negativePercentage'] * 0.01);
          arrayData.push(record[f]['neutralPercentage'] * 0.01);
          arrayData.push(record[f]['positivePercentage'] * 0.01);
        }
      })
      ws_data.push(arrayData)
    })

    let ws = XLSX.utils.aoa_to_sheet(ws_data);
    const merge = this.isShowNewsPecentageChangeData ? [
      { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
      { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
      { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },
      { s: { r: 3, c: 3 }, e: { r: 3, c: 9 } },
      { s: { r: 3, c: 13 }, e: { r: 3, c: 16 } },
      { s: { r: 3, c: 23 }, e: { r: 3, c: 23 } }
    ] : [
      { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
      { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
      { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },
      { s: { r: 3, c: 3 }, e: { r: 3, c: 12 } },
      { s: { r: 3, c: 13 }, e: { r: 3, c: 22 } },
      { s: { r: 3, c: 23 }, e: { r: 3, c: 32 } }
    ];
    ws["!merges"] = merge;
    // ws["A1"].s = {									// set the style for target cell
    //   font: {
    //     name: '宋体',
    //     sz: 24,
    //     bold: true,
    //     color: { rgb: "FFFFAA00" }
    //   },
    //   alignment: {
    //     vertical: 'center',
    //     horizontal: 'center',
    //   }
    // };
    XLSX.utils.book_append_sheet(wb, ws, ws_name);

    let wopts:any = { bookType:'xlsx', bookSST:false, type:'array' };
    let wbout = XLSX.write(wb, wopts);
    saveAs(new Blob([wbout],{type:"application/octet-stream"}), exportExcelFileName);
  }

  onPdfBtnClick() {
    window.print();
  }

  onShowPecentageCkbChange(event:any) {
    this.isShowNewsPecentageChangeData = event.detail.value
    
    // get sort options
    const grid: any = document.getElementById("country-dashboard-grid");
    let tableSortStates = grid.api.getCoreGrid().getPlugin("SortableTitle").getSortedColumns();

    this._populateTableFromResponse();

    setTimeout(() => {
      const grid: any = document.getElementById("country-dashboard-grid");
      let sort = grid.api.getCoreGrid().getPlugin("SortableTitle");
      sort.sortColumns(tableSortStates)
    }, 0)
  }

  // Prepare model for context-menu
  readonly contextMenuModel: any = {
    items: {
      NEW_TAB: {
        text: "Open in new tab",
        callback: (e:any) => {
          const { rowIndex, item: { value } } = e;

          const grid: any = document.getElementById("country-dashboard-grid");
          const allRowData = grid.api.getDataView().getAllRowData();
          let rowData:any = allRowData[rowIndex]
          window.open(`/country;countryId=${rowData['countryId']};countryStartDate=${rowData['countryStartDate']};selectedDate=${this.selectedDate}`, "_blank")
        }
      },
    },
    onMenu: function(e:any) {
      var context = e.context;
      var menu = e.menu;
      menu.addItems("NEW_TAB");
    }
  };
}
