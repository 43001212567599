import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export var ClientEventType = Object.freeze({
  showLoadingBlocker: '__showLoadingBlocker',
  hideLoadingBlocker: '__hideLoadingBlocker',
  showLoginDialog: '__showLoginDialog',
});

@Injectable({
  providedIn: 'root'
})
export class PubSubService {
  _clientEventSubject: Subject<string>;
  constructor() {
   this._clientEventSubject = new Subject();
  }

  publishClientEvent(type: string) {
    this._clientEventSubject.next(type);
  }

  subscribeClientEvent(func: (value: string) => void) {
    return this._clientEventSubject.subscribe(func);
  }
}