import { Component, OnInit } from '@angular/core';

import '@elf/coral-dialog';
import "@elf/coral-tab-bar";
import '@elf/coral-tab';
import '@elf/amber-loader';
import '@elf/emerald-grid';
import { info, confirm, warn, error } from '@elf/amber-notification/lib/helpers';

import { CheckboxExtension } from 'tr-grid-checkbox';
import { PaginationExtension } from 'tr-grid-pagination';
import { AutoTooltipExtension } from "tr-grid-auto-tooltip";

import '@elf/coral-dialog/themes/halo/light';
import "@elf/coral-tab-bar/themes/halo/light";
import '@elf/coral-tab/themes/halo/light';
import '@elf/amber-loader/themes/halo/light';
import '@elf/emerald-grid/themes/halo/light';
import { AdminService } from 'src/service/admin.service';
import { ConditionalExpr } from '@angular/compiler';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  loading:boolean = false;
  editBtnDisabled: boolean = true;
  removeBtnDisabled: boolean = true;
  gridCheckboxExt: any = null;
  orgTableColumns: any;
  userTableColumns: any;
  pageSize:number =  50;
  dataType:string = 'org';
  editDlgOpened: boolean = false;
  editDlgData: any = null;
  confirmObj: any;
  confirmDlgOpened: boolean = false;


  constructor(private adminService:AdminService) {
    this.orgTableColumns = [
      { title: 'Org ID', field: 'orgId', sortBy: 'orgId', width: 1, scalable: true },
      { title: 'Long Name', field: 'longName', sortBy: 'longName', minWidth: 60 , width: 2, scalable: true},
      { title: 'Short Name', field: 'shortName', sortBy: 'shortName', alignment: 'left', width: 1, scalable: true },
      { title: 'Frontend Config', field: 'frontendConfig', sortBy: 'frontendConfig', width: 6.5, scalable: true },
      { title: 'Backend Config', field: 'backendConfig', sortBy: 'backendConfig', width: 4.5, scalable: true },
    ];
    this.userTableColumns = [
      { title: 'User ID', field: 'userId', sortBy: 'userId', width: 1, scalable: true },
      { title: 'AAA Login', field: 'aaaLogin', sortBy: 'aaaLogin', minWidth: 60, width: 2.5, scalable: true },
      { title: 'Org ID', field: 'orgId', sortBy: 'orgId', width: 1, scalable: true },
      { title: 'Frontend Config', field: 'frontendConfig', sortBy: 'frontendConfig', width: 6, scalable: true },
      { title: 'Backend Config', field: 'backendConfig', sortBy: 'backendConfig', width: 4, scalable: true },
      { title: 'Is Admin', field: 'isAdmin', sortBy: 'isAdmin', width: 1, scalable: true}
    ]
    this.confirmObj = {
      title: 'Confirmation:',
      message: 'Do you want to confirm?'

    }
  }

  ngOnInit(): void {
    this.dataType = "org"
    this.requestOrgTableContent();
    //this.requestUserTableContent();
  }

  requestOrgTableContent() {
    this.editBtnDisabled = true;
    this.removeBtnDisabled = true;
    this.gridCheckboxExt = this.createGridCheckboxExt()
    const grid:any = document.getElementById('admin-grid');
    grid.config = {
      rowHighlight: true,
      columns: this.orgTableColumns,
      sorting: {
        sortableColumns: true,
        threeStatesSorting: true
      },
      pagination: {
        element: document.getElementById("admin-pagination"),
        page: 1,
        pageSize: this.pageSize
      },
      extensions: [
        this.gridCheckboxExt,
        new PaginationExtension(),
        new AutoTooltipExtension()
      ]
    };
    this.loading = true;
    this.adminService.requestOrganizations().subscribe({
      next: (rt:any) => {
        let gridData:any = []
        if (rt && rt.length > 0) {
          rt.forEach((i:any) => {
            i['frontendConfig'] = i['frontendConfig'] ? JSON.stringify(i['frontendConfig']) : '';
            i['backendConfig'] = i['backendConfig'] ? JSON.stringify(i['backendConfig']) : '';
            gridData.push(i)
          })
        } else {
          warn("No organization data", 5000)
        }
        grid.config = {
          rowHighlight: true,
          columns: this.orgTableColumns,
          sorting: {
            sortableColumns: true,
            threeStatesSorting: true
          },
          dataModel: {
            format: "rows",
            data: gridData
          },
          pagination: {
            element: document.getElementById("admin-pagination"),
            page: 1,
            pageSize: this.pageSize
          },
          extensions: [
            this.gridCheckboxExt,
            new PaginationExtension(),
            new AutoTooltipExtension()
          ]
        };
        this.loading = false;
    }, error: (e:any) => {
      this.loading = false;
      error(e.message, 6000)
    }})
  }
  requestUserTableContent() {
    this.editBtnDisabled = true;
    this.removeBtnDisabled = true;
    this.gridCheckboxExt = this.createGridCheckboxExt()
    let grid:any = document.getElementById('admin-grid');
    grid.config = {
      rowHighlight: true,
      columns: this.userTableColumns,
      sorting: {
        sortableColumns: true,
        threeStatesSorting: true
      },
      pagination: {
        element: document.getElementById("admin-pagination"),
        page: 1,
        pageSize: this.pageSize
      },
      extensions: [
        this.gridCheckboxExt,
        new PaginationExtension(),
        new AutoTooltipExtension()
      ]
    };
    this.loading = true
    this.adminService.requestUsers().subscribe({
      next: (rt:any) => {
        let gridData:any = []
        if (rt && rt.length > 0) {
          rt.forEach((i:any) => {
            i['isAdmin'] = i['isAdmin'] ? true : false
            i['frontendConfig'] = i['frontendConfig'] ? JSON.stringify(i['frontendConfig']) : '';
            i['backendConfig'] = i['backendConfig'] ? JSON.stringify(i['backendConfig']) : '';
            gridData.push(i)
          })
        } else {
          warn("No user data", 5000)
        }
        let grid:any = document.getElementById('admin-grid');
        grid.config = {
          rowHighlight: true,
          columns: this.userTableColumns,
          sorting: {
            sortableColumns: true,
            threeStatesSorting: true
          },
          dataModel: {
            format: "rows",
            data: gridData
          },
          pagination: {
            element: document.getElementById("admin-pagination"),
            page: 1,
            pageSize: this.pageSize
          },
          extensions: [
            this.gridCheckboxExt,
            new PaginationExtension(),
            new AutoTooltipExtension()
          ]
        };
        this.loading = false;
    }, error: (e:any) => {
      this.loading = false;
      error(e.message, 6000)
    }})

  }

  createGridCheckboxExt() {
    let gridCheckboxExt = new CheckboxExtension();
    gridCheckboxExt.addEventListener("clicked", () => {
      let allSelected = gridCheckboxExt.getFilteredSelectedData()
      if (allSelected && allSelected.length == 1) {
        this.editBtnDisabled = false;
        this.removeBtnDisabled = false;
      } else {
        this.editBtnDisabled = true;
        this.removeBtnDisabled = true;
      }
    })
    return gridCheckboxExt
  }

  tabClick(event:any) {
    if (event.target.id != 'admin-orgs-tab') {
      let w:any = document.getElementById('admin-orgs-tab');
      w.active = false;
    } else {
      this.dataType = 'org'
      let w:any = document.getElementById('admin-orgs-tab');
      w.active = true;
      this.requestOrgTableContent();
    }
    if (event.target.id != 'admin-users-tab') {
      let w:any = document.getElementById('admin-users-tab');
      w.active = false;
    } else {
      this.dataType = "user"
      let w:any = document.getElementById('admin-users-tab');
      w.active = true;
      this.requestUserTableContent();
    }
  }

  addBtnClick(event:any) {
    let rowData:any = {dataType: this.dataType, opType: 'add'}
    if (this.dataType == 'org') {
      rowData.longName = ''
      rowData.shortName = ''
      rowData.frontendConfig = ''
      rowData.backendConfig = ''
      this.editDlgData = rowData
      this.editDlgOpened = true;
    } else {
      this.loading = true;
      this.adminService.requestOrganizations().subscribe({
        next: (value:any) => {
          rowData.userId = ''
          rowData.aaaLogin = ''
          rowData.frontendConfig = ''
          rowData.backendConfig = ''
          rowData.orgs = value.map((item:any) => {
            return {
              orgId: item.orgId,
              shortName: item.shortName
            }
          })
          this.loading = false;
          this.editDlgData = rowData
          this.editDlgOpened = true;
        },
        error: (e:any) => {
          error(`Failed to request organization list: ${e.message}`, 4000)
          this.loading = false;
        }
      })
    }
  }

  editBtnClick(event:any) {
    let allSelected = this.gridCheckboxExt.getFilteredSelectedData()
    if (allSelected && allSelected.length > 0) {
      let rowData = allSelected[0];
      if (this.dataType == 'org') {
        //console.log(rowData)
        this.editDlgData = Object.assign({dataType: this.dataType, opType: 'edit'}, rowData)
        this.editDlgOpened = true;
      } else {
        this.loading = true;
        this.adminService.requestOrganizations().subscribe({
          next: (value:any) => {
            rowData.orgs = value.map((item:any) => {
              return {
                orgId: item.orgId,
                shortName: item.shortName
              }
            })
            this.loading = false;
            this.editDlgData = Object.assign({dataType: this.dataType, opType: 'edit'}, rowData)
            //console.log(this.editDlgData)
            this.editDlgOpened = true;
          },
          error: (e:any) => {
            error(`Failed to request organization list: ${e.message}`, 4000)
            this.loading = false;
          }
        })
      }
    } else {
      Error("No row is selected");
    }
  }

  removeBtnClick(event:any) {
    let allSelected = this.gridCheckboxExt.getFilteredSelectedData()
    if (allSelected && allSelected.length > 0) {
      let rowData = allSelected[0];
      if (this.dataType == 'org') { // remove organization
        this.openConfirmDlg(`Do you want to remove orgnization ${rowData['longName']}?`)
      } else { // remove user
        this.openConfirmDlg(`Do you want to remove user ${rowData['aaaLogin']}?`)
      }
    } else {
      Error("No row is selected");
    }
  }

  doRemove(event:any) {
    this.confirmDlgOpened = false;
    let allSelected = this.gridCheckboxExt.getFilteredSelectedData()
    if (allSelected && allSelected.length > 0) {
      let rowData = allSelected[0];
      //console.log(rowData)
      if (this.dataType == 'org') { // remove organization
        this.loading = true;
        this.adminService.deleteOrganization(rowData['orgId']).subscribe({
          next: (value:any) => {
            info(`Organization ${rowData['longName']} is removed`, 4000)
            this.loading = false;
            this.requestOrgTableContent()
          },
          error: (e:any) => {
            error(`Failed to remove organization ${rowData['longName']}: ${e.message}`, 4000)
            this.loading = false;
          }
        })
      } else { // remove user
        this.loading = true;
        this.adminService.deleteUser(rowData['userId']).subscribe({
          next: (value:any) => {
            info(`User ${rowData['aaaLogin']} is removed`, 4000)
            this.loading = false;
            this.requestUserTableContent()
          },
          error: (e:any) => {
            error(`Failed to remove User ${rowData['aaaLogin']}: ${e.message}`, 4000)
            this.loading = false;
          }
        })
      }
    } else {
      console.log("warn: no table row is selected")
    }
  }

  openConfirmDlg(message:string) {
    this.confirmObj = {
      title: "Confirmation:",
      message: message
    }
    this.confirmDlgOpened = true;
  }

  editDlgSaved() {
    this.editDlgOpened = false;
    if(this.dataType == 'org') {
      this.requestOrgTableContent()
    } else {
      this.requestUserTableContent()
    }
  }

  editDlgCanceled() {
    this.editDlgOpened = false;
  }
}
