import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CountryDashboardComponent } from './country-dashboard/country-dashboard.component';
import { CounterpartyComponent } from './counterparty/counterparty.component';
import { CountryComponent } from './country/country.component';
import { ReferenceDataComponent } from './reference-data/reference-data.component';
import { MainComponent } from './main/main.component';
import { CounterpartyDashboardComponent } from './counterparty-dashboard/counterparty-dashboard.component';
import { AdminComponent } from './admin/admin.component';
import { AdminEditComponent } from './admin/admin-edit/admin-edit.component';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from 'src/guard/cache-route-reuse.strategy';

@NgModule({
  declarations: [
    AppComponent,
    CountryDashboardComponent,
    CounterpartyComponent,
    CountryComponent,
    ReferenceDataComponent,
    MainComponent,
    CounterpartyDashboardComponent,
    AdminComponent,
    AdminEditComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: CacheRouteReuseStrategy}
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
