import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/service/auth.service';
import { info, confirm, warn, error } from '@elf/amber-notification/lib/helpers';
import { ClientEventType, PubSubService } from 'src/service/pub-sub.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router:Router, private authService: AuthService, private pubSubService: PubSubService ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      //console.log(route)
      //console.log(state)
      //return true;
    let hasSessionInfo = this.authService.hasSessionInfo();
    if (this.authService.isLoggedIn()) {
      return true;
    } else {
      this.router.navigate(["/main"])
      this.pubSubService.publishClientEvent(ClientEventType.showLoginDialog);
      if (hasSessionInfo) {
        error("Login session is expired. Please login again", 6000)
      }

      return false;
    }
  }

}
