<div>
  <div class="request-tool-bar">
    <div class="conditions-area">
      <emerald-datetime-picker [value]="yesterday" [min]='minDate' [max]='yesterday' (value-changed)='onDateChange($event)'  format="yyyy-MM-dd"
        style="height: 40px; background-color: transparent; border: none; border-bottom: 1px solid #000; color: #333; width: 100%">
      </emerald-datetime-picker>
      <div style="display:flex;flex-direction: row;align-items: center;">
        <quartz-label style="font-size:1.25em;font-weight: 401;padding-right:8px;">Minimum Relevance:</quartz-label>
        <coral-slider id="company-dashboard-minimum-relevance-slider" min="0" max="1" step="0.1" [value]="selectedMinimumRelevance"  show-steps show-input-field
         (value-changed)="onMinimumRelevanceChange($event)" style="padding-left:8px;"></coral-slider>
      </div>
      <coral-button (click)="onGoBtnClick()" style="width:80px;height:30px;font-size: 1.3em;background-color:blue;color:white;">Go</coral-button>
    </div>
    <div></div>
    <div></div>
  </div>
  <div class="content-tool-bar">
    <div class="filter-area">
      <quartz-label style="font-size:1.3em;font-weight: 400;padding-right:8px;">Domicile:</quartz-label>
      <emerald-multi-select  id="bank-dashboard-domicile-multiselection" (value-changed)='onDomicilesChange($event)' show-pills style="border-color:#b9b9b9;"></emerald-multi-select>
      <quartz-label style="font-size:1.3em;font-weight: 400;padding-left:8px; padding-right:8px;">{{companyComponentName}}:</quartz-label>
      <emerald-multi-select  id="bank-dashboard-bank-multiselection" (value-changed)='onCompaniesChange($event)' show-pills style="border-color:#b9b9b9;"></emerald-multi-select>
      <coral-checkbox id="counterparty-dashboard-show-percentage-ckb" (checked-changed)="onShowPecentageCkbChange($event)" style="border-color:#b9b9b9;font-size:1.3em;font-weight: 400;padding-left:8px;">Show % change of news compared to previous period.</coral-checkbox>
      <coral-icon icon="info" style="padding-left:12px;padding-right:4px;font-size:1.4em;"></coral-icon><span style="font-size:1.3em;">T = Total News, S = Sentiment</span>
    </div>
    <div></div>
    <div class="export-area">
      <div>
        <coral-tab icon="excel" label="Export" (tap)="onExcelBtnClick()"></coral-tab>
        <coral-tab icon="pdf" label="Export" (tap)="onPdfBtnClick()"></coral-tab>
      </div>
    </div>
  </div>
  <div style="display:block;padding-bottom:6px;">
    <emerald-grid id="company-dashboard-grid" #dashboardgrid style="margin-top:-1px;font-size:1.05em; border-bottom: 1px solid #ededed;font-weight:bold;"></emerald-grid>
    <div style="display:grid;justify-items: center;padding-top:8px;"><emerald-pagination id="bank-dashboard-grid-pagination"></emerald-pagination></div>
  </div>
  <!--<br><br><coral-button cta (click)="test()" style="margin-top: 10px;">Click Me</coral-button>-->
</div>
<div *ngIf="loading" style="display:block;pointer-events: auto;z-index: 110;">
  <amber-loader  style="background-color: rgb(40, 40, 40, 0.5);"></amber-loader>
</div>