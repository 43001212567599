import { Component, OnInit } from '@angular/core';


import '@elf/emerald-grid';
import '@elf/amber-loader';
import { CompositeGrid, CoreGrid, DataView } from '@grid/types';
import { info, confirm, warn, error } from '@elf/amber-notification/lib/helpers';

import '@elf/emerald-grid/themes/halo/light';
import '@elf/amber-loader/themes/halo/light';
import { AppStateService } from 'src/service/app-state.service';
import { SentimentService } from 'src/service/sentiment.service';
import { forkJoin, of } from 'rxjs';
import { AutoTooltipExtension } from "tr-grid-auto-tooltip";
import { TitleWrapExtension } from 'tr-grid-titlewrap';
import { ConfigService } from 'src/service/config.service';
import { AuthService } from 'src/service/auth.service';
import {plural} from 'pluralize';

@Component({
  selector: 'app-reference-data',
  templateUrl: './reference-data.component.html',
  styleUrls: ['./reference-data.component.scss']
})
export class ReferenceDataComponent implements OnInit {
  loading:boolean = false;
  companyGridData:any = [];
  countryGridData:any = [];
  topicGridData:any = [];

  companyComponentName: string;
  pluralCompanyComponentName: string;

  constructor(private appStateService: AppStateService,
              private sentimentService: SentimentService,
              private configService: ConfigService,
              private authService: AuthService) {
    this.companyComponentName = this.configService.defaultValues['companyComponentName']
    this.pluralCompanyComponentName = plural(this.configService.defaultValues['companyComponentName'])
  }

  ngOnInit(): void {
    let userInfo = this.authService.userInfo
    let commonObj = userInfo['frontend_config'] && userInfo['frontend_config']['common']
    if (commonObj && commonObj['companyComponentName']) {
      this.companyComponentName = commonObj['companyComponentName']
      this.pluralCompanyComponentName = plural(commonObj['companyComponentName'])
    }

    this.loading = true
    let companyRequest$ = this.appStateService.hasCompaniesData() ? of(this.appStateService.getCompaniesData()) : this.sentimentService.requestCompanies();
    let countryRequest$ = this.appStateService.hasCountriesData() ? of(this.appStateService.getCountriesData()) : this.sentimentService.requestCountries();
    let topicRequest$ = this.appStateService.hasTopicsData() ? of(this.appStateService.getTopicsData()) : this.sentimentService.requestTopics();
    forkJoin([companyRequest$, countryRequest$, topicRequest$]).subscribe({
      next: (value:any) => {
        let companiesData = value[0]
        //console.log(companiesData)
        if (!companiesData || companiesData.length == 0) {
          warn("No company data fetched.", 6000)
        } else {
          if (!this.appStateService.hasCompaniesData()) {
            this.appStateService.setCompaniesData(companiesData)
          }
          this.companyGridData = []
          companiesData.forEach((company:any) => {
            this.companyGridData.push([
              company.domicile.name,
              company.name
            ])
          })
          this._setCompanyGrid(this.companyGridData)
        }

        let countriesData:any = value[1]
        //console.log(countriesData)
        if (!countriesData || countriesData.length == 0) {
          warn("No country data fetched.", 6000)
        } else {
          if (!this.appStateService.hasCountriesData()) {
            this.appStateService.setCountriesData(countriesData)
          }
          this.countryGridData = []
          countriesData.forEach((country:any) => {
            this.countryGridData.push([country['name'], country['active'] ? 'Y' : 'N'])
          })
          this._setCountryGrid(this.countryGridData)
        }

        let topicsData = value[2]
        //console.log(topicsData)
        if (!topicsData || topicsData.length == 0) {
          warn("No topic data fetched.", 6000)
        } else {
          if (!this.appStateService.hasTopicsData()) {
            this.appStateService.setTopicsData(topicsData)
          }
          this.topicGridData = []
          topicsData.filter((topic:any) => topic['country_inscope'] || topic['company_inscope']).forEach((topic:any) => {
            this.topicGridData.push([topic['category'], topic['description'], topic['n2000_code'],
                                    `${topic['country_inscope'] ? 'Y' : 'N'}`, `${topic['company_inscope'] ? 'Y' : 'N'}`])
          })
          this._setTopicGrid(this.topicGridData)
        }
        this.loading = false
      },
      error: (e:any) => {
        this.loading = false;
        error(e.message, 6000)
      }
    })
  }

  _setCompanyGrid(companyData: any,): void {

    let fields = ['domicile', 'name'];
    const columns: CompositeGrid.Column[] = [
      { id: 'c0', title: 'Domicile', field: fields[0], visible:true, alignment: 'left', styles: {
        'border-left': 'none',
        'border-top': 'none',
        'border-right': 'none',
        'border-bottom': '2px solid black !important'}, width: 1, scalable: true},
      { id: 'c1', title: this.companyComponentName, field: fields[1], styles: {
      'border-left': 'none',
      'border-top': 'none',
      'border-right': 'none',
      'border-bottom': '2px solid black !important'}, width: 1.5, scalable: true }
    ];

    const config: CompositeGrid.Options = {
      linearWheelScrolling: true,
      rowHeight: 40,
      columns: columns,
      dataModel: {
        fields: fields,
        data: companyData,
      },
      extensions: [
        new AutoTooltipExtension()
      ],
      whenDefined: function (e: any) {
        let api: CompositeGrid = e.api as CompositeGrid;
        // let core: CoreGrid = api.getCoreGrid();
        // let dv: DataView = api.getDataView();
        // Do something with grid with api...
      },
    } as CompositeGrid.Options;

    const grid: any = document.getElementById("reference-company-grid");
    grid.config = config;
  }

  _setCountryGrid(countryData: any,): void {

    let fields = ['name', 'active'];
    const columns: CompositeGrid.Column[] = [
      { id: 'c0', title: 'Country', field: fields[0], visible:true, alignment: 'left', styles: {
        'border-left': 'none',
        'border-top': 'none',
        'border-right': 'none',
        'border-bottom': '2px solid black !important'}, width: 1.5, scalable: true},
      { id: 'c1', title: 'Active',field: fields[1], styles: {
      'border-left': 'none',
      'border-top': 'none',
      'border-right': 'none',
      'border-bottom': '2px solid black !important'}, width: 1, scalable: true }
    ];

    const config: CompositeGrid.Options = {
      rowHeight: 40,
      columns: columns,
      dataModel: {
        fields: fields,
        data: countryData,
      },
      extensions: [
        new AutoTooltipExtension()
      ],
      whenDefined: function (e: any) {
        let api: CompositeGrid = e.api as CompositeGrid;
        // let core: CoreGrid = api.getCoreGrid();
        // let dv: DataView = api.getDataView();
        // Do something with grid with api...
      },
    } as CompositeGrid.Options;

    const grid: any = document.getElementById("reference-country-grid");
    grid.config = config;
  }

  _setTopicGrid(topicData: any,): void {
    let fields = ['category', 'description', 'code', 'country company Inscope'];
    const columns: CompositeGrid.Column[] = [
      { id: 'c0', title: 'Category', field: fields[0], visible:true, alignment: 'left', styles: {
        'border-left': 'none',
        'border-top': 'none',
        'border-right': 'none',
        'border-bottom': '2px solid black !important'}, width: 1.5, scalable: true},
      { id: 'c1', title: 'Description',field: fields[1], styles: {
      'border-left': 'none',
      'border-top': 'none',
      'border-right': 'none',
      'border-bottom': '2px solid black !important'}, width: 1.7, scalable: true },
      { id: 'c2', title: 'Code', field: fields[2], styles: {
      'border-left': 'none',
      'border-top': 'none',
      'border-right': 'none',
      'border-bottom': '2px solid black !important'}, width: 1.2, scalable: true },
      { id: 'c3', title: 'Country',field: fields[3], styles: {
      'border-left': 'none',
      'border-top': 'none',
      'border-right': 'none',
      'border-bottom': '2px solid black !important'}, width: 0.9, scalable: true  },
      { id: 'c4', title: this.companyComponentName,field: fields[4], styles: {
      'border-left': 'none',
      'border-top': 'none',
      'border-right': 'none',
      'border-bottom': '2px solid black !important'}, width: 0.9, scalable: true  },
    ];

    const config: CompositeGrid.Options = {
      linearWheelScrolling: true,
      rowHeight: 40,
      columns: columns,
      dataModel: {
        fields: fields,
        data: topicData,
      },
      autoTooltip: {
        title: true,
      },
      extensions: [
        new AutoTooltipExtension(),
        new TitleWrapExtension()
      ],
      whenDefined: function (e: any) {
        let api: CompositeGrid = e.api as CompositeGrid;
        // let core: CoreGrid = api.getCoreGrid();
        // let dv: DataView = api.getDataView();
        // Do something with grid with api...
      },
    } as CompositeGrid.Options;

    const grid: any = document.getElementById("reference-topic-grid");
    grid.config = config;
  }

}
