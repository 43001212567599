<div class="reference-container">
  <div class="grid-container">
    <span class="grid-title">{{pluralCompanyComponentName}}</span>
    <emerald-grid id="reference-company-grid" style="width:100%;"></emerald-grid>
  </div>
  <div class="grid-container">
    <span class="grid-title">Countries</span>
    <emerald-grid id="reference-country-grid" style="width:100%;"></emerald-grid></div>
  <div class="grid-container">
    <span class="grid-title">Topics</span>
    <emerald-grid id="reference-topic-grid" style="width:100%;"></emerald-grid></div>
</div>
<div *ngIf="loading" style="display:block;pointer-events: auto;z-index: 110;">
  <amber-loader  style="background-color: rgb(40, 40, 40, 0.5);width:100%;height:100%;position: fixed;"></amber-loader>
</div>