<div id="nav-section" class="nav-section-white" role="banner">
  <div class="nav">
    <img  class="logo" src="../assets/icons/{{logoFileName}}.png">
    <ul>
      <li *ngIf="countryDashboardEnabled"><a routerLink="/country-dashboard"  routerLinkActive="active" [routerLinkActiveOptions]={exact:true}>Country Dashboard</a></li>
      <li *ngIf="countryEnabled"><a routerLink="/country" routerLinkActive="active">Country</a></li>
      <li *ngIf="companyDashboardEnabled"><a routerLink="/counterparty-dashboard" routerLinkActive="active">{{companyDashboardComponentName}}</a></li>
      <li *ngIf="companyEnabled"><a routerLink="/counterparty" routerLinkActive="active">{{companyComponentName}}</a></li>
      <li *ngIf="referenceDataEnabled"><a routerLink="/reference-data" routerLinkActive="active">Reference Data</a></li>
      <li *ngIf="adminEnabled && hasAdminPrivilege"><a routerLink="/admin" routerLinkActive="active">Admin</a></li>
    </ul>
  </div>
  <div id="username-section" style="display:flex;flex-direction: row;align-items: center;padding-left:16px;font-size:1.3em;color:white;padding-right:16px;">
    <coral-button [disabled]="!loggedIn" cta id="usernameBtn" icon="individual" style="border-color: #e6e6e6;background-color:#001eff;height:30px;font-size:0.9em;padding-left:12px;padding-right:12px;">{{shortUserId}}</coral-button>
  </div>
  <emerald-popup-menu id="usernameMenu" [opend]="usernameMenuOpened">
  </emerald-popup-menu>
</div>
<router-outlet></router-outlet>
<coral-popup-panel [formGroup]="loginForm" [opened]="loginDlgOpened" [noCancelOnOutsideClick]="true"
  [noCancelOnEscKey]="true" [fullScreen]="true">
  <div class="coral-panel" style="display:flex;flex-direction: column;align-items: center;">
    <img src="assets/rft-lg-logo.png" style="width:280px;margin-top:30%" alt="Logo">
    <coral-text-field id="login-username"
      formControlName="username"
      [error]="username && username.invalid && (username.dirty || username.touched)"
      placeholder="Username"
      ngDefaultControl
      style="margin-top:10px;"
    >
    </coral-text-field>
    <coral-password-field id="login-password"
      formControlName="password"
      [error]="password && password.invalid && (password.dirty || password.touched)"
      placeholder="Password"
      ngDefaultControl
      style="margin-top:6px;"
    >
    </coral-password-field>
    <coral-button cta (click)="login()" [disabled]="!loginForm.valid" style="margin-top: 10px;">Login</coral-button>
  </div>
</coral-popup-panel>
<div *ngIf="loading" style="display:block;pointer-events: auto;z-index: 110;">
  <amber-loader  style="background-color: rgb(40, 40, 40, 0.5);z-index: 111;"></amber-loader>
</div>