import { Injectable } from '@angular/core';
import { LocalstorageService } from './localstorage.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { HttpRequestBase } from './http-request-base.service';
import { catchError, map, throwError } from 'rxjs';
import { info, confirm, warn, error } from '@elf/amber-notification/lib/helpers';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends HttpRequestBase  {

  private baseUrl = environment.apiUrl; // "https://api.dev.news-solutions.refinitiv.com";
  private logigedIn: boolean = false;
  public userInfo: any = {}

  constructor(private localStorageService: LocalstorageService, private http: HttpClient) {
    super()

    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let loginTime = this.localStorageService.getItem('login_time');
    let frontendConfig = this.localStorageService.getItem('frontend_config') ? JSON.parse(this.localStorageService.getItem('frontend_config')) : {};
    let backendConfig = this.localStorageService.getItem('backend_config') ? JSON.parse(this.localStorageService.getItem('backend_config')) : {};
    let orgName = this.localStorageService.getItem('org_name');
    let _isAdmin = this.localStorageService.getItem('is_admin');
    let isAdmin = ('true' === _isAdmin.toLowerCase())

    if (username && accessToken && loginTime && frontendConfig && backendConfig && orgName) {
      this.userInfo = {
        'username': username,
        'access_token': accessToken,
        'login_time': loginTime,
        'frontend_config': frontendConfig,
        'backend_config': backendConfig,
        'org_name': orgName,
        'is_admin': isAdmin,
      }
    } else {
      this.userInfo = {}
    }
   }

  storeSessionInfo(value: any) {
    this.localStorageService.setItem('username', value['username']);
    this.localStorageService.setItem('access_token', value['access_token']);
    this.localStorageService.setItem('login_time', value['login_time'] + '');
    this.localStorageService.setItem('frontend_config', JSON.stringify(value['frontend_config']));
    this.localStorageService.setItem('backend_config', JSON.stringify(value['backend_config']));
    this.localStorageService.setItem('org_name', value['org_name']);
    this.localStorageService.setItem('is_admin', value['is_admin'] + '');

    this.userInfo = Object.assign({}, value)
  }

  hasSessionInfo(): boolean {
    let accessToken = this.localStorageService.getItem('access_token');
    return !!accessToken;
  }

  clearSessionInfo(): void {
    this.localStorageService.clear()
    this.userInfo = {}
  }

  isAdmin(): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }
    let _isAdmin = this.localStorageService.getItem('is_admin');
    let isAdmin = ('true' === _isAdmin.toLowerCase())
    return isAdmin;
  }

  isLoggedIn(): boolean {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let loginTime = this.localStorageService.getItem('login_time');
    let frontendConfig = this.localStorageService.getItem('frontend_config') ? JSON.parse(this.localStorageService.getItem('frontend_config')) : {};
    let backendConfig = this.localStorageService.getItem('backend_config') ? JSON.parse(this.localStorageService.getItem('backend_config')) : {};
    let orgName = this.localStorageService.getItem('org_name');

    if (username && accessToken && loginTime && frontendConfig && backendConfig && orgName) {
      // check if login_time is expired
      let tokenValidityInHours = 8;
      if (backendConfig && backendConfig.TokenValidityInHours) {
        tokenValidityInHours = backendConfig.TokenValidityInHours
      }
      if ((Date.now() - Date.parse(loginTime)) >= (tokenValidityInHours * 60 * 60 * 1000)) {
        console.info("Warn: Login session is expired, need to login again")
        this.clearSessionInfo();
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  login(username: string, password: string): any {
    let parameter = {
      username: username,
      password: password,
    }
    let url = `${this.baseUrl}/auth/gui`
    return this.http.post(url, parameter, this.httpOptions).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          //error('User is not authorized. Please contact Administrator.', 4000)
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

}
