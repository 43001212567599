import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import '@elf/coral-dialog';
import '@elf/coral-text-field';
import '@elf/coral-number-field';
import '@elf/coral-accordion';
import '@elf/coral-collapse';
import '@elf/emerald-color-dialog';
import '@elf/amber-loader';

import '@elf/coral-dialog/themes/halo/light';
import '@elf/coral-text-field/themes/halo/light';
import '@elf/coral-number-field/themes/halo/light';
import '@elf/coral-accordion/themes/halo/light';
import '@elf/coral-collapse/themes/halo/light';
import '@elf/emerald-color-dialog/themes/halo/light';
import '@elf/amber-loader/themes/halo/light';
import { warn, error, info } from '@elf/amber-notification/lib/helpers';
import { AdminService } from 'src/service/admin.service';
import { debounce } from "lodash";


@Component({
  selector: 'app-admin-edit',
  templateUrl: './admin-edit.component.html',
  styleUrls: ['./admin-edit.component.scss']
})
export class AdminEditComponent implements OnInit {

  @Input() data!:any;
  @Output() success = new EventEmitter();
  @Output() cancel = new EventEmitter();
  confirmObj: any;
  colorDlgOpened: boolean = false;
  orgLongName: string = '';
  orgShortName: string = '';
  frontendConfig: any = {};
  backendConfig: string = '';
  loading: boolean = false;
  orgsValue: any;
  userOrgId: string = '';
  userAAALogin: string = '';
  countryDashboardEnabled: boolean = false;
  countryEnabled: boolean = false;
  companyDashboardEnabled: boolean = false;
  companyEnabled: boolean = false;
  referenceDataEnabled: boolean = false;
  adminEnabled: boolean = false;
  companyDashboardComponentName: string = '';
  companyComponentName: string = '';
  positiveColor: string = '';
  neutralColor: string = '';
  negativeColor: string = '';
  colorType: string = 'positive';
  colorDialogValue: string = '#ffffff';
  countryDashboardPageSize: any = '';
  countryHeadlinePageSize: any = '';
  companyDashboardPageSize: any = '';
  companyHeadlinePageSize: any = '';


  constructor(private adminService: AdminService) {
    this.confirmObj = {
      title: "Confirmation:",
      message: `Do you want to confirm?`
    }
  }

  ngOnInit(): void {
    //console.log(this.data)
    this.orgLongName = this.data.longName ? this.data.longName : '';
    this.orgShortName = this.data.shortName ? this.data.shortName : '';
    this.backendConfig = this.data.backendConfig ? this.data.backendConfig : '';
    this.frontendConfig = this.data.frontendConfig ? JSON.parse(this.data.frontendConfig) : {};

    this.userAAALogin = this.data.aaaLogin ? this.data.aaaLogin : '';
    this.userOrgId = this.data.orgId ? this.data.orgId : '';

    this.countryDashboardEnabled = this.frontendConfig.hasOwnProperty('countryDashboard');
    this.countryEnabled = this.frontendConfig.hasOwnProperty('country')
    this.companyDashboardEnabled = this.frontendConfig.hasOwnProperty('companyDashboard')
    this.companyEnabled = this.frontendConfig.hasOwnProperty('company')
    this.referenceDataEnabled = this.frontendConfig.hasOwnProperty('referenceData')
    this.adminEnabled = this.frontendConfig.hasOwnProperty('admin')

    if (this.frontendConfig && this.frontendConfig['common']) {
      if (this.frontendConfig['common']['companyDashboardComponentName']) {
        this.companyDashboardComponentName = this.frontendConfig['common']['companyDashboardComponentName']
      }
      if (this.frontendConfig['common']['companyComponentName']) {
        this.companyComponentName = this.frontendConfig['common']['companyComponentName']
      }
      if (this.frontendConfig['common']['positiveColor']) {
        this.positiveColor = this.frontendConfig['common']['positiveColor']
      }
      if (this.frontendConfig['common']['neutralColor']) {
        this.neutralColor = this.frontendConfig['common']['neutralColor']
      }
      if (this.frontendConfig['common']['negativeColor']) {
        this.negativeColor = this.frontendConfig['common']['negativeColor']
      }
      if (this.frontendConfig['common']['countryDashboardPageSize']) {
        this.countryDashboardPageSize = this.frontendConfig['common']['countryDashboardPageSize']
      }
      if (this.frontendConfig['common']['countryHeadlinePageSize']) {
        this.countryHeadlinePageSize = this.frontendConfig['common']['countryHeadlinePageSize']
      }
      if (this.frontendConfig['common']['companyDashboardPageSize']) {
        this.companyDashboardPageSize = this.frontendConfig['common']['companyDashboardPageSize']
      }
      if (this.frontendConfig['common']['companyHeadlinePageSize']) {
        this.companyHeadlinePageSize = this.frontendConfig['common']['companyHeadlinePageSize']
      }
    }

    this.orgsValue = []
    if (this.data.orgs) {
      this.data.orgs.forEach((item:any) => {
        this.orgsValue.push({
          value: item.orgId,
          label: item.shortName,
        })
      })
    }
    //console.log(this.orgsValue)
  }

  test() {
    this.success.emit();
  }

  dlgTabClick(event:any) {
    const tabs = ['common', 'country-dashboard', 'country', 'company-dashboard', 'company', 'reference-data', 'admin'];
    tabs.forEach((name:string) => {
      let c:any = document.getElementById(`${name}-content`);
      c.style.display="none";
      let tab:any = document.getElementById(`${name}-tab`)
      tab.active = false;
    })
    let c:any = document.getElementById(`${event}-content`);
    c.style.display="block";
    let tab:any = document.getElementById(`${event}-tab`)
    tab.active = true;
  }

  setOrgLongName(event:any) {
    this.orgLongName = event.detail.value
  }

  setOrgShortName(event:any) {
    this.orgShortName = event.detail.value
  }

  _validateParam() {
    if (this.data.opType == 'add') {
      if (this.data.dataType == 'org') {
        if (!this.orgLongName) {
          error("Long Name is required.", 4000);
          return false;
        }
        if (!this.orgShortName) {
          error("Short Name is required.", 4000);
          return false;
        }
      } else { // dataType == 'user'
        if (!this.userAAALogin) {
          error("AAA Login is required.", 4000)
          return false;
        }
        if (!this.userOrgId) {
          error("Organization is required.", 4000);
          return false;
        }
      }
    } else { // opType = 'edit'
      if (this.data.dataType == 'org') {
        if (!this.orgLongName) {
          error("Long Name is required.", 4000);
          return false;
        }
      } else { // dataType == 'user'
        if (!this.userAAALogin) {
          error("AAA Login is required.", 4000)
          return false;
        }
      }
    }
    return true;
  }

  dlgSave() {
    if (!this._validateParam()) {
      return;
    }

    let dataTypeDescription = this.data.dataType == "org" ? "organization" : "user"
    let opTypeStr = this.data.opType == "add" ? "add" : "update"
    this.confirmObj = {
      title: "Confirmation:",
      message: `Do you want to ${opTypeStr} this ${dataTypeDescription}?`
    }
    let d:any = document.getElementById('admin_edit_confirm_dlg')
    d.opened = true;
  }

  dlgCancel() {
    this.cancel.emit()
  }

  doSave(event:any) {
    let frontendConfigObj:any = {};
    let commonObj:any = {}
    if (this.companyDashboardComponentName) {
      commonObj['companyDashboardComponentName'] = this.companyDashboardComponentName
    }
    if (this.companyComponentName) {
      commonObj['companyComponentName'] = this.companyComponentName
    }
    if (this.positiveColor) {
      commonObj['positiveColor'] = this.positiveColor
    }
    if (this.neutralColor) {
      commonObj['neutralColor'] = this.neutralColor
    }
    if (this.negativeColor) {
      commonObj['negativeColor'] = this.negativeColor
    }

    if (this.countryDashboardPageSize) {
      commonObj['countryDashboardPageSize'] = this.countryDashboardPageSize
    }
    if (this.countryHeadlinePageSize) {
      commonObj['countryHeadlinePageSize'] = this.countryHeadlinePageSize
    }
    if (this.companyDashboardPageSize) {
      commonObj['companyDashboardPageSize'] = this.companyDashboardPageSize
    }
    if (this.companyHeadlinePageSize) {
      commonObj['companyHeadlinePageSize'] = this.companyHeadlinePageSize
    }

    if (Object.keys(commonObj).length > 0) {
      frontendConfigObj['common'] = commonObj;
    }
    if (this.countryDashboardEnabled) {
      frontendConfigObj['countryDashboard'] = ''
    }
    if (this.countryEnabled) {
      frontendConfigObj['country'] = ''
    }
    if (this.companyDashboardEnabled) {
      frontendConfigObj['companyDashboard'] = ''
    }
    if (this.companyEnabled) {
      frontendConfigObj['company'] = ''
    }
    if (this.referenceDataEnabled) {
      frontendConfigObj['referenceData'] = ''
    }
    if (this.adminEnabled) {
      frontendConfigObj['admin'] = ''
    }
    let frontendConfigStr = Object.keys(frontendConfigObj).length != 0 ? JSON.stringify(frontendConfigObj) : ''
    if (this.data.opType == 'add') {
      if (this.data.dataType == 'org') {
        let newOrg = {
          "longName": this.orgLongName,
          "shortName": this.orgShortName,
          "frontendConfig": frontendConfigStr,
          "backendConfig": this.backendConfig,
        }
        //console.log(newOrg)
        this.loading = true;
        this.adminService.addOrganization(newOrg).subscribe({
          next: (value:any) => {
            info(`${this.orgLongName} is added.`, 4000)
            this.loading = false;
            this.success.emit()
          },
          error: (e:any) => {
            error(`Failed to add organization: ${e.message}`, 4000)
            this.loading = false;
          }
        })
      } else { // dataType == 'user'
        //console.log('add user')
        let newUser = {
          "aaaLogin": this.userAAALogin,
          "orgId": this.userOrgId,
          "frontendConfig": frontendConfigStr,
          "backendConfig": this.backendConfig
        }
        this.loading = true;
        this.adminService.addUser(newUser).subscribe({
          next: (value:any) => {
            info(`User ${this.userAAALogin} is added.`, 4000)
            this.loading = false;
            this.success.emit()
          },
          error: (e:any) => {
            error(`Failed to add user: ${e.message}`, 4000)
            this.loading = false;
          }
        })
      }
    } else { // opType = 'edit'
      if (this.data.dataType == 'org') {
        //console.log('update org')
        let updateOrg = {
          "orgId": this.data.orgId,
          "longName": this.orgLongName,
          "shortName": this.orgShortName,
          "frontendConfig": frontendConfigStr,
          "backendConfig": this.backendConfig,
        }
        //console.log(updateOrg)
        this.loading = true;
        this.adminService.updateOrganization(updateOrg).subscribe({
          next: (value:any) => {
            info(`${this.orgLongName} is updated.`, 4000)
            this.loading = false;
            this.success.emit()
          },
          error: (e:any) => {
            error(`Failed to update organization: ${e.message}`, 4000)
            this.loading = false;
          }
        })
      } else { // dataType == 'user'
        //console.log('update user')
        let updateUser = {
          "userId": this.data.userId,
          "aaaLogin": this.userAAALogin,
          "orgId": this.userOrgId,
          "frontendConfig": frontendConfigStr,
          "backendConfig": this.backendConfig
        }
        this.loading = true;
        this.adminService.updateUser(updateUser).subscribe({
          next: (value:any) => {
            info(`User ${this.userAAALogin} is updated.`, 4000)
            this.loading = false;
            this.success.emit()
          },
          error: (e:any) => {
            error(`Failed to update user: ${e.message}`, 4000)
            this.loading = false;
          }
        })
      }
    }
  }

  countryDashboardCheckBtnHandler(event:any) {
    this.countryDashboardEnabled = event.detail.value
    //console.log(this.countryDashboardEnabled)
  }

  countryCheckBtnHandler(event:any) {
    this.countryEnabled = event.detail.value
  }

  companyDashboardCheckBtnHandler(event:any) {
    this.companyDashboardEnabled = event.detail.value
  }

  companyCheckBtnHandler(event:any) {
    this.companyEnabled = event.detail.value
  }

  referenceDataCheckBtnHandler(event:any) {
    this.referenceDataEnabled = event.detail.value
  }

  adminCheckBtnHandler(event:any) {
    this.adminEnabled = event.detail.value
  }

  colorBtnClick(colorType: string) {
    this.colorType = colorType
    if (colorType == 'positive') {
      this.colorDialogValue = this.positiveColor
    } else if (colorType == 'neutral') {
      this.colorDialogValue = this.neutralColor
    } else if (colorType == 'negative') {
      this.colorDialogValue = this.negativeColor
    }
    this.colorDlgOpened = true
  }

  colorDlgOpenChanged(event:any) {
    //console.log(event)
    this.colorDlgOpened = event.detail.value
  }

  colorDlgValueChanged(event:any) {
    if (this.colorType == 'positive') {
      this.positiveColor = event.detail.value
    } else if (this.colorType == 'neutral') {
      this.neutralColor = event.detail.value
    } else if (this.colorType == 'negative') {
      this.negativeColor = event.detail.value
    }
  }

  userOrgIdSelectChanged(event: any) {
    this.userOrgId = event.detail.value
  }

  userAAALoginChanged(event: any) {
    this.userAAALogin = event.detail.value
  }

  companyDashboardComponentNameChanged(event:any) {
    this.companyDashboardComponentName = event.detail.value
  }

  companyComponentNameChanged(event:any) {
    this.companyComponentName = event.detail.value
  }

  positiveColorTextinputValueChanged(event:any) {
    this.positiveColor = event.detail.value
  }

  neutralColorTextinputValueChanged(event:any) {
    this.neutralColor = event.detail.value
  }

  negativeColorTextinputValueChanged(event:any) {
    this.negativeColor = event.detail.value
  }

  _countryDashboardPageSizeValueChanged(event:any) {
    if (event.type == 'value-changed') {
      let v:any = Number(event.detail.value);
      if (v < 1 || v > 200) {
        v = ''
        this._setPageSizeWidgetValue('countryDashboardPageSize-input', '')
      }
      this.countryDashboardPageSize = v;
    } else if (event.type == 'error-changed') {
      this.countryDashboardPageSize = ''
      this._setPageSizeWidgetValue('countryDashboardPageSize-input', '')
    }
  }

  countryDashboardPageSizeValueChanged = debounce(this._countryDashboardPageSizeValueChanged, 800)

  _countryHeadlinePageSizeValueChanged(event:any) {
    if (event.type == 'value-changed') {
      let v:any = Number(event.detail.value);
      if (v < 1 || v > 200) {
        v = ''
        this._setPageSizeWidgetValue('countryHeadlinePageSize-input', '')
      }
      this.countryHeadlinePageSize = v
    } else if (event.type == 'error-changed') {
      this.countryHeadlinePageSize = ''
      this._setPageSizeWidgetValue('countryHeadlinePageSize-input', '')
    }
  }

  countryHeadlinePageSizeValueChanged = debounce(this._countryHeadlinePageSizeValueChanged, 800)

  _companyDashboardPageSizeValueChanged(event:any) {
    if (event.type == 'value-changed') {
      let v:any = Number(event.detail.value);
      if (v < 1 || v > 200) {
        v = ''
        this._setPageSizeWidgetValue('companyDashboardPageSize-input', '')
      }
      this.companyDashboardPageSize = v
    } else if (event.type == 'error-changed') {
      this.companyDashboardPageSize = ''
      this._setPageSizeWidgetValue('companyDashboardPageSize-input', '')
    }
  }

  companyDashboardPageSizeValueChanged = debounce(this._companyDashboardPageSizeValueChanged, 800)

  _companyHeadlinePageSizeValueChanged(event:any) {
    if (event.type == 'value-changed') {
      let v:any = Number(event.detail.value);
      if (v < 1 || v > 200) {
        v = ''
        this._setPageSizeWidgetValue('companyHeadlinePageSize-input', '')
      }
      this.companyHeadlinePageSize = v
    } else if (event.type == 'error-changed') {
      this.companyHeadlinePageSize = ''
      this._setPageSizeWidgetValue('companyHeadlinePageSize-input', '')
    }
  }

  companyHeadlinePageSizeValueChanged = debounce(this._companyHeadlinePageSizeValueChanged, 800)

  _setPageSizeWidgetValue(id:string, v:any) {
    let element:any = document.getElementById(id);
    element.value = v
  }


}
