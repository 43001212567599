import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { HttpRequestBase } from './http-request-base.service';
import { LocalstorageService } from './localstorage.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SentimentService extends HttpRequestBase {

  private baseUrl = environment.apiUrl; // "https://api.dev.news-solutions.refinitiv.com";
  constructor(private localStorageService: LocalstorageService, private http: HttpClient) {
    super();
  }

  requestCountries() {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/metadata/countries?username=${username}`
    return this.http.get(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  requestCountriesSummary(date?:string) {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/sentiments/countries/summary?username=${username}${date ? '&date='+date : ''}`
    return this.http.get(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  requestOneCountrySummary(countryId: string, date?:string) {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/sentiments/countries/summary?username=${username}&countryId=${countryId}${date ? '&date='+date : ''}`
    return this.http.get(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  requestCountryChart(countryId: string, date?:string) {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/sentiments/countries/chart?username=${username}&countryId=${countryId}${date ? '&date='+date : ''}`
    return this.http.get(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  requestCountryHeadlines(payload: any) {
    let countryId = payload['countryId']
    let date = payload['date'] ? payload['date'] : ''
    let selectedSubjects = payload['selectedSubjects'] ? payload['selectedSubjects'] : ''
    let sort = payload['sort'] ? payload['sort'] : ''
    let pageSize = payload['pageSize'] ? payload['pageSize'] : ''
    let pageNumber = payload['pageNumber'] ? payload['pageNumber'] : ''
    let back30date = payload['back30date'] ? payload['back30date'] : ''
    let selectedSentiments = payload['selectedSentiments'] ? payload['selectedSentiments'] : ''
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/sentiments/countries/headline?username=${username}&countryId=${countryId}` +
              `${date ? '&date='+date : ''}${pageSize ? '&pageSize='+pageSize : ''}${pageNumber ? '&pageNumber='+pageNumber : ''}`+
              `${selectedSubjects? '&selectedSubjects='+selectedSubjects : ''}${back30date? '&back30date='+back30date : ''}` +
              `${selectedSentiments? '&selectedSentiments='+selectedSentiments : ''}${sort? '&selectedSort='+sort : ''}`
    return this.http.get(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  requestCountryTrend(countryId: string) {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/sentiments/countries/trend?username=${username}&countryId=${countryId}`
    return this.http.get(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  requestCompanies() {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/metadata/companies?username=${username}`
    return this.http.get(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  requestCompaniesSummary(date?: string, minRelevance?:string) {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/sentiments/companies/summary?username=${username}${date ? '&date='+date : ''}${minRelevance ? '&minRelevance='+minRelevance : ''}`
    return this.http.get(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  requestOneCompanySummary(permId: string, date?:string, minRelevance?:string) {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/sentiments/companies/summary?username=${username}&permId=${permId}${date ? '&date='+date : ''}${minRelevance? '&minRelevance='+minRelevance : ''}`
    return this.http.get(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  requestCompanyChart(permId: string, date?:string, minRelevance?:string) {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/sentiments/companies/chart?username=${username}&permId=${permId}${date ? '&date='+date : ''}${minRelevance? '&minRelevance='+minRelevance : ''}`
    return this.http.get(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  requestCompanyHeadlines(payload: any) {
    let permId = payload['companyId']
    let date = payload['date'] ? payload['date'] : ''
    let minRelevance = payload['minRelevance'] ? payload['minRelevance'] : ''
    let selectedSubjects = payload['selectedSubjects'] ? payload['selectedSubjects'] : ''
    let sort = payload['sort'] ? payload['sort'] : ''
    let pageSize = payload['pageSize'] ? payload['pageSize'] : ''
    let pageNumber = payload['pageNumber'] ? payload['pageNumber'] : ''
    let back30date = payload['back30date'] ? payload['back30date'] : ''
    let selectedSentiments = payload['selectedSentiments'] ? payload['selectedSentiments'] : ''
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/sentiments/companies/headline?username=${username}&permId=${permId}` +
              `${date ? '&date='+date : ''}${pageSize ? '&pageSize='+pageSize : ''}${pageNumber ? '&pageNumber='+pageNumber : ''}`+
              `${selectedSubjects? '&selectedSubjects='+selectedSubjects : ''}${back30date? '&back30date='+back30date : ''}` +
              `${selectedSentiments? '&selectedSentiments='+selectedSentiments : ''}${minRelevance? '&minRelevance='+minRelevance : ''}` +
              `${sort? '&selectedSort='+sort : ''}`
    return this.http.get(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  requestCompanyTrend(permId: string, minRelevance?:string) {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/sentiments/companies/trend?username=${username}&permId=${permId}${minRelevance? '&minRelevance='+minRelevance : ''}`
    return this.http.get(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  requestTopics() {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/metadata/topics?username=${username}`
    return this.http.get(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  requestNewsDetail(newsId: string) {
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/metadata/news?newsId=${encodeURIComponent(newsId)}`
    return this.http.get(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  requestCountryNewsDetail(newsId: string, countryId: string) {
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/metadata/news?newsId=${encodeURIComponent(newsId)}&countryId=${countryId}`
    return this.http.get(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  requestCompanyNewsDetail(newsId: string, companyId: string) {
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/metadata/news?newsId=${encodeURIComponent(newsId)}&permId=${companyId}`
    return this.http.get(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

}
