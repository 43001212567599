import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

export class HttpRequestBase {

  httpOptions = Object.freeze({
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  });

  handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', errorResponse.error.message);
      alert(`An error occurred: ${errorResponse.error.message}`);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.log(errorResponse)
      console.error(`Backend returned code ${errorResponse.status}, ` +
        `body was: ${JSON.stringify(errorResponse.error)}`);
      // alert(`Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`)
    }
    // Return an observable with a user-facing error message.
    let errorMsg = 'Something bad happened; please try again later.'
    if (errorResponse) {
      if (errorResponse.error && errorResponse.error['error_description']) {
        errorMsg = errorResponse.error['error_description']
      } else if (errorResponse.error && errorResponse.error['message']) {
        errorMsg = errorResponse.error['message']
      } else if (errorResponse['message']) {
        errorMsg = errorResponse['message']
      } else if (errorResponse['status']) {
        errorMsg = `'Something bad happened; Code: ${errorResponse['status']}; please try again later.'`
      }
    }

    return throwError( () => new Error(errorMsg));
  }
}