import {
  RouteReuseStrategy,
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
} from "@angular/router";

export class CacheRouteReuseStrategy implements RouteReuseStrategy {

  private cache: { [key: string]: DetachedRouteHandle } = {};

  // if the future route should use the same component as the current rout
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  // if attach the cached component
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    let url = this.getUrl(route);
    if (url == 'main') { // navegate to /main means user is logging out, then delete all cache
      this.cache = {}
      return false;
    }
    let key;
    if (url == 'country' || url == 'counterparty') {
      if (Object.keys(route.params).length > 0) { // navigation by click in dashboard table
        let cacheKeys = Object.keys(this.cache)
        cacheKeys.forEach((key:any) => { // remove all the related cached
          if (key.startsWith(url+"_")) {
            delete this.cache[key]
          }
        })
        return false; // always create new component
      } else { // navigation by click on the tab name
        let existingKey = Object.keys(this.cache).find((item:string) => item.startsWith(url + '_'))
        if (existingKey) {
          key = existingKey
        } else {
          return false;
        }
      }
    } else {
      key = url + '_';
    }

    return !!this.cache[key];
  }

  // get the cached component for attaching to current route
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.routeConfig || route.routeConfig.loadChildren) {
      return null;
    }
    let key;
    let url = this.getUrl(route)
    let existingKey = Object.keys(this.cache).find((item:string) => item.startsWith(url + '_'))
    if (existingKey) {
      key = existingKey
    } else {
      key = url + '_';
    }

    return this.cache[key];
  }

  // if the leaving component should be cached for next time access
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.routeConfig && route.routeConfig.data && (route.routeConfig.data['reuse']);
  }

  // store the leaving component for next use
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    if (handle) {
      let url = this.getUrl(route)
      let cacheKeys = Object.keys(this.cache)
      cacheKeys.forEach((key:any) => { // remove all the related cached
        if (key.startsWith(url+"_")) {
          delete this.cache[key]
        }
      })
      let appendix = Object.keys(route.params).length > 0 ? ('_' + this.hashCode(JSON.stringify(route.params))) : '_'
      let key = url + appendix
      this.cache[key] = handle;
    }
  }

  getUrl(route: ActivatedRouteSnapshot): string {
    if (route.routeConfig) {
      return route.routeConfig.path ? route.routeConfig.path : '__default__';
    }
    return '__default__'
  }

  hashCode(s: string) {
    var hash = 0, i, chr;
    if (s.length === 0) return hash;
    for (i = 0; i < s.length; i++) {
      chr   = s.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };

}