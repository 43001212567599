import { Component, OnInit, ViewChild } from '@angular/core';
import '@elf/emerald-grid';
import '@elf/emerald-pagination';
import '@elf/coral-button';
import '@elf/coral-select';
import '@elf/emerald-datetime-picker';
import '@elf/coral-split-button';
import '@elf/sapphire-chart';
import '@elf/coral-multi-input';
import '@elf/emerald-autosuggest';
import '@elf/coral-toggle';
import '@elf/amber-loader';
import '@elf/emerald-multi-select';
import '@elf/coral-popup-panel';
import '@elf/sapphire-led-gauge';
import '@elf/coral-tab';
import '@elf/coral-panel';
import '@elf/coral-combo-box'
import '@elf/coral-collapse';
import { CompositeGrid, CoreGrid, DataView } from '@grid/types';
//import { PaginationExtension } from 'tr-grid-pagination';
//import { RowGroupingExtension } from 'tr-grid-row-grouping';
import * as moment from 'moment';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { info, confirm, warn, error } from '@elf/amber-notification/lib/helpers';

import '@elf/emerald-grid/themes/halo/light';
import '@elf/emerald-pagination/themes/halo/light';
import '@elf/coral-button/themes/halo/light';
import '@elf/coral-select/themes/halo/light';
import '@elf/emerald-datetime-picker/themes/halo/light';
import '@elf/coral-split-button/themes/halo/light';
import '@elf/sapphire-chart/themes/halo/light';
import '@elf/coral-multi-input/themes/halo/light';
import '@elf/emerald-autosuggest/themes/halo/light';
import '@elf/coral-toggle/themes/halo/light';
import '@elf/amber-loader/themes/halo/light';
import '@elf/emerald-multi-select/themes/halo/light';
import '@elf/coral-popup-panel/themes/halo/light';
import '@elf/sapphire-led-gauge/themes/halo/light'
import '@elf/coral-tab/themes/halo/light';
import '@elf/coral-panel/themes/halo/light';
import '@elf/coral-combo-box/themes/halo/light';
import '@elf/coral-collapse/themes/halo/light';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SentimentService } from 'src/service/sentiment.service';
import { forkJoin, of } from 'rxjs';
import { AppStateService } from 'src/service/app-state.service';
import { AuthService } from 'src/service/auth.service';
import { ConfigService } from 'src/service/config.service';
import { ContextMenuExtension } from 'tr-grid-contextmenu';
import { CellSelectionExtension } from 'tr-grid-cell-selection';


@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit {

  needInit: boolean = false;
  yesterday: string = '';
  minDate: string = '';
  maxDate: string = '';
  selectedCountry: string = '';
  selectedCountryName: string = '';
  selectedDate: string = '';
  selectedFilterDate: string = '';
  selectedFilterSentiments: any = [];
  selectedFilterTopics: any = []
  readonly defaultSelectedFilterSortBy: string = 'date-asc';
  selectedFilterSortBy = this.defaultSelectedFilterSortBy;
  routeParamMap: any = [];
  public loading: boolean = false;
  trendGoBtnDisabled: boolean;
  hiddenTrendSectionAll: boolean;
  detailGoBtnDisabled: boolean;
  detailFilterGoBtnDisabled: boolean;
  hiddenDetailSectionAll: boolean;
  countryActiveState: string = '';
  d1: any;
  d7: any;
  d30: any;
  countriesDropdownData: any;
  dateFilterDropdownData: any;
  newsPopupPanelData: any;
  // chart data
  chartLabels:any = []
  chartNegativeNewsData:any = []
  chartNeutralNewsData:any = []
  chartPositiveNewsData:any = []
  chartTotalNewsData:any = []
  chartNegativePercentageData:any = []
  chartNeutralPercentageData:any = []
  chartPositivePercentageData:any = []
  chartNegativeSentimentsData:any = []
  chartNeutralSentimentsData:any = []
  chartPositiveSentimentsData:any = []
  defaultDaysNumber: number = 7;
  chartTimeframeValue:number = 7;
  currentCountry4DetailSection: string = '';

  defaultHeadlineTablePageSize: number;
  headlineTablePageSize: number;

  defaultPositiveColor: string;
  defaultNeutralColor: string;
  defaultNegativeColor: string;
  positiveColor: string;
  neutralColor: string;
  negativeColor: string;

  gradientPositiveColor0: string = '';
  gradientPositiveColor1: string = '';
  gradientPositiveColor2: string = '';
  gradientPositiveColor3: string = '';
  gradientNeutralColor0: string = '';
  gradientNeutralColor1: string = '';
  gradientNeutralColor2: string = '';
  gradientNeutralColor3: string = '';
  gradientNegativeColor0: string = '';
  gradientNegativeColor1: string = '';
  gradientNegativeColor2: string = '';
  gradientNegativeColor3: string = '';

  topicFilterDropdownInited:boolean = false;
  headlineContentFontSize: number = 1.4;
  headlineContentFontSizeStr: string = "1.4em";

  sixMonthTrendData: any = []
  sixMonthTrendLabels: any = []
  defaultTrendTimeframeValue:number = 180;
  trendTimeframeValue:number;
  currentCountry4TrendSection: string = '';
  trendChartsDataHidden = [[false, false, false, false, false, false], [false, false, false, false, false, false]];

  @ViewChild('dashboardgrid') dashboardGrid: any;
  constructor(private router: Router,
              private route: ActivatedRoute,
              private sentimentService: SentimentService,
              private appStateService: AppStateService,
              private authService: AuthService,
              private configService: ConfigService) {
    this.defaultHeadlineTablePageSize = this.configService.defaultValues['countryHeadlinePageSize']
    this.headlineTablePageSize = this.defaultHeadlineTablePageSize;
    this.defaultPositiveColor = this.configService.defaultValues['positiveColor']
    this.defaultNeutralColor = this.configService.defaultValues['neutralColor']
    this.defaultNegativeColor = this.configService.defaultValues['negativeColor']
    this.positiveColor = this.defaultPositiveColor
    this.neutralColor = this.defaultNeutralColor
    this.negativeColor = this.defaultNegativeColor
    this.trendTimeframeValue = this.defaultTrendTimeframeValue;

    this.hiddenTrendSectionAll = true;
    this.hiddenDetailSectionAll = true

    this.yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
    this.minDate = moment().subtract(1 + 335, 'days').format('YYYY-MM-DD');

    if (this.route.snapshot.paramMap.keys.length > 0) {
      this.routeParamMap = this.route.snapshot.paramMap
    }
    //console.log(this.routeData)
    this.needInit = this.routeParamMap.keys.length > 0 ? true : false;
    if (this.routeParamMap.keys.length && this.routeParamMap.keys.indexOf('selectedDate') != -1) {
      this.selectedDate = this.routeParamMap.get('selectedDate')
    } else {
      this.selectedDate = this.yesterday;
    }
    if (this.routeParamMap.keys.length && this.routeParamMap.keys.indexOf('countryStartDate') != -1) {
      this._setDatetimePicker(this.routeParamMap.get('countryStartDate'))
    } else {
      this._setDatetimePicker()
    }
    if (this.routeParamMap.keys.length && this.routeParamMap.keys.indexOf('countryId') != -1) {
      this.selectedCountry = this.routeParamMap.get('countryId')
    } else {
      this.selectedCountry = ''
    }
    if (this.routeParamMap.keys.length && this.routeParamMap.keys.indexOf('days') != -1) {
      this.defaultDaysNumber = Number(this.routeParamMap.get('days'))
      if (this.defaultDaysNumber != 1 && this.defaultDaysNumber != 7 && this.defaultDaysNumber != 30) {
        this.defaultDaysNumber = 7;
      }
      this.chartTimeframeValue = this.defaultDaysNumber
    }
    this.trendGoBtnDisabled = false;
    this.detailGoBtnDisabled = false;
    this.detailFilterGoBtnDisabled = true;
    this._setDefaultSummarySentimentScores();
    this._resetNewsPopupPanelData()
  }

  _setDefaultSummarySentimentScores() {
    this.d1 = {
      negativePercentage:0,neutralPercentage:0,positivePercentage:0,
      totalNews:0,
      negativeSentiments:'0.00',neutralSentiments:'0.00',positiveSentiments:'0.00',
      sentimentIndicator:-2
    }
    this.d7 = {
      negativePercentage:0,neutralPercentage:0,positivePercentage:0,
      totalNews:0,
      negativeSentiments:'0.00',neutralSentiments:'0.00',positiveSentiments:'0.00',
      sentimentIndicator:-2
    }
    this.d30 = {
      negativePercentage:0,neutralPercentage:0,positivePercentage:0,
      totalNews:0,
      negativeSentiments:'0.00',neutralSentiments:'0.00',positiveSentiments:'0.00',
      sentimentIndicator:-2
    }
  }

  ngOnInit(): void {
    this._setTrendTimeframeSplitBtn();

    this._setTimeframeSplitBtn();
    this._setSentimentFilter();
    this._setSortByFilterSelect();
    this._setNewsGridPagination();

    this._setColorSchema();
    this._setTablePageSize();

    if (!this.appStateService.hasCountriesData()) {
      this.loading = true
      this.sentimentService.requestCountries().subscribe({
        next: (rt:any) => {
          this.appStateService.setCountriesData(rt)
          let countriesData = this.appStateService.getCountriesData();
          this.countriesDropdownData = []
          if (!countriesData || countriesData.length == 0) {
            warn("No countries data fetched.", 6000)
          } else {
            countriesData.forEach((country:any) => {
              this.countriesDropdownData.push({
                label: country['name'] + ' (' + (country['active'] ? 'Active' : 'Others') + ')',
                value: country['id'] + '',
                startDate: country['startDate'] ? country['startDate'] : ''
              })
              if (this.selectedCountry && country['id'] == this.selectedCountry) {
                this.selectedCountryName = country['name']
              }
            })
          }
          this._setCountryComboBox(this.countriesDropdownData, this.selectedCountry)
          let countryStartDate = ''
          if (this.routeParamMap.keys.length && this.routeParamMap.keys.indexOf('countryId') != -1) {
            this.selectedCountry = this.routeParamMap.get('countryId')
            countryStartDate = this.routeParamMap.get('countryStartDate')
          } else {
            this.selectedCountry = this.countriesDropdownData.length > 0 ? this.countriesDropdownData[0].value : ''
            this.selectedCountryName = this.countriesDropdownData.length > 0 ? this.countriesDropdownData[0].label.substr(0, this.countriesDropdownData[0].label.indexOf(' (')) : ''
            countryStartDate = this.countriesDropdownData.length > 0 ? this.countriesDropdownData[0].startDate : ''
          }
          this._setDatetimePicker(countryStartDate)
          this.loading = false;
          if (!this.needInit) {
            this.trendGoBtnDisabled = false;
            this.detailGoBtnDisabled = false;
          } else {
            this.trendGoBtnDisabled = true;
            this.detailGoBtnDisabled = true;
            this._setDateFilterSelect(this.selectedDate)
            this._requestData(this.selectedCountry, this.selectedDate, true, true)
          }
      }, error: (e:any) => {
        this.loading = false;
        error(e.message, 6000)
      }})
    } else {
      let countriesData = this.appStateService.getCountriesData();
      this.countriesDropdownData = []
      countriesData.forEach((country:any) => {
        this.countriesDropdownData.push({
          label: country['name'] + ' (' + (country['active'] ? 'Active' : 'Others') + ')',
          value: country['id'] + '',
          startDate: country['startDate'] ? country['startDate'] : ''
        })
        if (this.selectedCountry && country['id'] == this.selectedCountry) {
          this.selectedCountryName = country['name']
        }
      })
      this._setCountryComboBox(this.countriesDropdownData, this.selectedCountry)
      let countryStartDate = ''
      if (this.routeParamMap.keys.length && this.routeParamMap.keys.indexOf('countryId') != -1) {
        this.selectedCountry = this.routeParamMap.get('countryId')
        countryStartDate = this.routeParamMap.get('countryStartDate')
      } else {
        this.selectedCountry = this.countriesDropdownData.length > 0 ? this.countriesDropdownData[0].value : ''
        this.selectedCountryName = this.countriesDropdownData.length > 0 ? this.countriesDropdownData[0].label.substr(0, this.countriesDropdownData[0].label.indexOf(' (')) : ''
        countryStartDate = this.countriesDropdownData.length > 0 ? this.countriesDropdownData[0].startDate : ''
      }
      this._setDatetimePicker(countryStartDate)
      if (!this.needInit) {
        this.trendGoBtnDisabled = false;
        this.detailGoBtnDisabled = false;
      } else {
        this.trendGoBtnDisabled = true;

        this.detailGoBtnDisabled = true;
        this._setDateFilterSelect(this.selectedDate)
        this._requestData(this.selectedCountry, this.selectedDate, true, true)
      }
    }
  }

  ngAfterViewInit() {
    // NOTE: Hack shadow dom in Coral-Combo-Box to change the css
    //       ELF library upgrading may cause these code misfunction
    let element:any = document.getElementById('country-country-combobox')
    if (element && element.shadowRoot) {
      let coralTextField = element.shadowRoot.querySelector('coral-text-field')
      if (coralTextField && coralTextField.shadowRoot) {
        let input = coralTextField.shadowRoot.querySelector('input')
        if (input) {
          input.style['font-size'] = '1.8em'
          input.style['font-weight'] = 'bold'
        }
      }
      let btnDiv = element.shadowRoot.querySelector('#toggle-button');
      if (btnDiv) {
        btnDiv.style['border-left'] = 'none'
        btnDiv.style['width'] = '30px'
        let coralIcon = btnDiv.querySelector('coral-icon')
        if (coralIcon) {
          coralIcon.style['font-size'] = '18px';
        }
      }
    }
  }

  _setTablePageSize() {
    let userInfo = this.authService.userInfo
    let commonObj = userInfo['frontend_config'] && userInfo['frontend_config']['common']
    this.headlineTablePageSize = commonObj && commonObj['countryHeadlinePageSize'] ? commonObj && commonObj['countryHeadlinePageSize'] : this.defaultHeadlineTablePageSize;
  }
  
  _lightenDarkenColor(col:string,amt:number) {
    let usePound = false;
    if ( col[0] == "#" ) {
        col = col.slice(1);
        usePound = true;
    }

    let num = parseInt(col,16);
    let r = (num >> 16) + amt;
    if ( r > 255 ) r = 255;
    else if  (r < 0) r = 0;
    let b = ((num >> 8) & 0x00FF) + amt;
    if ( b > 255 ) b = 255;
    else if  (b < 0) b = 0;
    let g = (num & 0x0000FF) + amt;
    if ( g > 255 ) g = 255;
    else if  ( g < 0 ) g = 0;

    let s = (g | (b << 8) | (r << 16)).toString(16);
    while (s.length < 6) {
      s = "0" + s
    }
    return (usePound?"#":"") + s;
  }

  _setColorSchema() {
    let userInfo = this.authService.userInfo
    let commonObj = userInfo['frontend_config'] && userInfo['frontend_config']['common']
    this.positiveColor = commonObj && commonObj['positiveColor'] ? commonObj && commonObj['positiveColor'] : this.defaultPositiveColor;
    this.neutralColor  = commonObj && commonObj['neutralColor'] ? commonObj && commonObj['neutralColor'] : this.defaultNeutralColor;
    this.negativeColor  = commonObj && commonObj['negativeColor'] ? commonObj && commonObj['negativeColor'] : this.defaultNegativeColor;

    this.gradientPositiveColor0 = this._lightenDarkenColor(this.positiveColor, 50);
    this.gradientPositiveColor1 = this._lightenDarkenColor(this.positiveColor, 25);
    this.gradientPositiveColor2 = this._lightenDarkenColor(this.positiveColor, -25);
    this.gradientPositiveColor3 = this._lightenDarkenColor(this.positiveColor, -50);
    this.gradientNeutralColor0 = this._lightenDarkenColor(this.neutralColor, 50);
    this.gradientNeutralColor1 = this._lightenDarkenColor(this.neutralColor, 25);
    this.gradientNeutralColor2 = this._lightenDarkenColor(this.neutralColor, -25);
    this.gradientNeutralColor3 = this._lightenDarkenColor(this.neutralColor, -50);
    this.gradientNegativeColor0 = this._lightenDarkenColor(this.negativeColor, 50);
    this.gradientNegativeColor1 = this._lightenDarkenColor(this.negativeColor, 25);
    this.gradientNegativeColor2 = this._lightenDarkenColor(this.negativeColor, -25);
    this.gradientNegativeColor3 = this._lightenDarkenColor(this.negativeColor, -50);
  }

  _setTrendTimeframeSplitBtn() {
    const timeframeToggleBtn:any = document.getElementById('trend-timeframe-split-btn');
    timeframeToggleBtn.addEventListener('tap', (e:any) => {
      let className = e.target['className']
      let daysNumber = this.defaultDaysNumber;
      switch(className) {
        case '30d':
          daysNumber = 30;
          break;
        case '60d':
          daysNumber = 60;
          break;
          case '90d':
            daysNumber = 90;
            break;
          case '180d':
            daysNumber = 180;
            break;
        default:
          daysNumber = this.defaultTrendTimeframeValue;
      }
      if (daysNumber == this.trendTimeframeValue) {
        return;
      } else {
        this.trendTimeframeValue = daysNumber;
      }

      this._setTrendCharts(this.trendTimeframeValue)
    });
    const trendframeToggleBtn30d:any = document.getElementById('trend-timeframe-split-btn-30d');
    trendframeToggleBtn30d.active = this.defaultTrendTimeframeValue == 30;
    const trendframeToggleBtn60d:any = document.getElementById('trend-timeframe-split-btn-60d');
    trendframeToggleBtn60d.active = this.defaultTrendTimeframeValue == 60;
    const trendframeToggleBtn90d:any = document.getElementById('trend-timeframe-split-btn-90d');
    trendframeToggleBtn90d.active = this.defaultTrendTimeframeValue == 90;
    const trendframeToggleBtn180d:any = document.getElementById('trend-timeframe-split-btn-180d');
    trendframeToggleBtn180d.active = this.defaultTrendTimeframeValue == 180;
    this.trendTimeframeValue = this.defaultTrendTimeframeValue;
  }

  _setTimeframeSplitBtn() {
    const timeframeToggleBtn:any = document.getElementById('country-timeframe-split-btn');
    timeframeToggleBtn.addEventListener('tap', (e:any) => {
      let className = e.target['className']
      let daysNumber = this.defaultDaysNumber;
      switch(className) {
        case '1d':
          daysNumber = 1;
          break;
        case '7d':
          daysNumber = 7;
          break;
        default:
          daysNumber = 30;
      }
      if (daysNumber == this.chartTimeframeValue) {
        return;
      } else {
        this.chartTimeframeValue = daysNumber;
      }

      let params:any[] = [
        this.chartLabels,
        this.chartNegativeNewsData,
        this.chartNeutralNewsData,
        this.chartPositiveNewsData,
        this.chartTotalNewsData,
        this.chartNegativePercentageData,
        this.chartNeutralPercentageData,
        this.chartPositivePercentageData,
        this.chartNegativeSentimentsData,
        this.chartNeutralSentimentsData,
        this.chartPositiveSentimentsData
      ].map(a => {
        if (daysNumber == 30) {
          return a;
        } else {
          return a.slice(a.length - this.chartTimeframeValue, a.length)
        }
      })
      this._setBarChart(params[0], params[1], params[2], params[3], params[4], params[5],
         params[6], params[7], params[8], params[9], params[10])
      //this._setDateFilterSelect(this.selectedDate, this.selectedDate, this.chartTimeframeValue)
      //this.selectedFilterDate = this.selectedDate
      this.detailFilterGoBtnDisabled = false;
    });
    const timeframeToggleBtn1d:any = document.getElementById('country-timeframe-split-btn-1d');
    timeframeToggleBtn1d.active = this.defaultDaysNumber == 1;
    const timeframeToggleBtn7d:any = document.getElementById('country-timeframe-split-btn-7d');
    timeframeToggleBtn7d.active = this.defaultDaysNumber == 7;
    const timeframeToggleBtn30d:any = document.getElementById('country-timeframe-split-btn-30d');
    timeframeToggleBtn30d.active = this.defaultDaysNumber == 30;
    this.chartTimeframeValue = this.defaultDaysNumber;
  }

  _setSentimentFilter() {
    let el:any = document.getElementById('country-sentiment-filter-select');
    el.data = [
      {
        'value': '1',
        'label': 'Positive',
        'selected': true
      },
      {
        'value': '0',
        'label': 'Neutral',
        'selected': true
      },
      {
        'value': '-1',
        'label': 'Negative',
        'selected': true
      }
    ]
  }

  _requestData(countryId: any, selectedDate: any, needDetailData: any, needTrendData:any) {
    if (!needDetailData && !needTrendData) {
      return;
    }

    this.loading = true
    let trendRequest$ = of([]);
    let sentimentRequest$ = of([]);
    let chartRequest$ = of([]);
    let topicsRequest$ = of([]);
    let headlinesRequest$ = of([]);

    if (needTrendData) {
      this.currentCountry4TrendSection = ''
      trendRequest$ = this.sentimentService.requestCountryTrend(countryId);
    }

    if (needDetailData) {
      this._resetDetailSectionData();
      sentimentRequest$ = this.sentimentService.requestOneCountrySummary(countryId, selectedDate);
      chartRequest$ = this.sentimentService.requestCountryChart(countryId, selectedDate);
      
      if (!this.topicFilterDropdownInited) {
        topicsRequest$ = this.appStateService.hasTopicsData() ? of(this.appStateService.getTopicsData()) : this.sentimentService.requestTopics();
      } else {
        topicsRequest$ = of([])
      }
      let headlinesRequestPayload: any = {countryId: this.selectedCountry, date: this.selectedDate, pageSize: this.headlineTablePageSize};
      if (this.selectedFilterSentiments.length > 0 && this.selectedFilterSentiments.length < 3) {
        headlinesRequestPayload.selectedSentiments = this.selectedFilterSentiments.join(',')
      }
      if (this.selectedFilterTopics.length > 0) {
        headlinesRequestPayload.selectedSubjects = this.selectedFilterTopics.join(',')
      }
      if (this.selectedFilterSortBy != this.defaultSelectedFilterSortBy) {
        headlinesRequestPayload.sort = this.selectedFilterSortBy
      }
      headlinesRequest$ = this.sentimentService.requestCountryHeadlines(headlinesRequestPayload);
    }
    
    forkJoin([sentimentRequest$, chartRequest$, topicsRequest$, headlinesRequest$, trendRequest$]).subscribe({
      next: (value:any) => {
        if (needDetailData) {
          //// Senitments data for Headers
          let sentimentHeadlineData = value[0]
          //console.log(value[0])
          let validateSentimentHeadlineData = (data:any) => {
            if (!data || !data['fields'] || data['fields'].length == 0 || !data['items'] || data['items'].length == 0) {
              warn('No summarized sentiments for selected country and date', 6000);
              return false;
            } else {
              let sentiments = data['items'][0]['sentiments']
              let fakeSentiment = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1].join(',')
              if (!sentiments || !sentiments['day'] || !sentiments['week'] || !sentiments['month'] || (sentiments['day'].join(',') == fakeSentiment)
                  && (sentiments['week'].join(',') == fakeSentiment)
                  && (sentiments['month'].join(',') == fakeSentiment)) {
                    warn('No summarized sentiments for selected country and date', 6000);
                    return false;
              }
            }
            return true
          }
          if(validateSentimentHeadlineData(sentimentHeadlineData)) {
            this.countryActiveState = sentimentHeadlineData.items[0].country.active ? 'Active' : 'Others'
            // calculate indexes
            let fields = sentimentHeadlineData.fields
            let totalNewsIndex = fields.indexOf('totalNews')
            let negativePercentageIndex = fields.indexOf('negativePercentage')
            let neutralPercentageIndex = fields.indexOf('neutralPercentage')
            let positivePercentageIndex = fields.indexOf('positivePercentage')
            let negativeSentimentsIndex = fields.indexOf('negativeSentiments')
            let neutralSentimentsIndex = fields.indexOf('neutralSentiments')
            let positiveSentimentsIndex = fields.indexOf('positiveSentiments')
            let sentimentIndicatorIndex = fields.indexOf('sentimentIndicator')

            let sentiments = sentimentHeadlineData.items[0].sentiments
            this.d1 = {
              negativePercentage:sentiments.day[negativePercentageIndex],
              neutralPercentage:sentiments.day[neutralPercentageIndex],
              positivePercentage:sentiments.day[positivePercentageIndex],
              totalNews:sentiments.day[totalNewsIndex],
              negativeSentiments:this._roundNumber2(sentiments.day[negativeSentimentsIndex]),
              neutralSentiments:this._roundNumber2(sentiments.day[neutralSentimentsIndex]),
              positiveSentiments:this._roundNumber2(sentiments.day[positiveSentimentsIndex]),
              sentimentIndicator:sentiments.day[sentimentIndicatorIndex]
            }
            this.d7 = {
              negativePercentage:sentiments.week[negativePercentageIndex],
              neutralPercentage:sentiments.week[neutralPercentageIndex],
              positivePercentage:sentiments.week[positivePercentageIndex],
              totalNews:sentiments.week[totalNewsIndex],
              negativeSentiments:this._roundNumber2(sentiments.week[negativeSentimentsIndex]),
              neutralSentiments:this._roundNumber2(sentiments.week[neutralSentimentsIndex]),
              positiveSentiments:this._roundNumber2(sentiments.week[positiveSentimentsIndex]),
              sentimentIndicator:sentiments.week[sentimentIndicatorIndex]
            }
            this.d30 = {
              negativePercentage:sentiments.month[negativePercentageIndex],
              neutralPercentage:sentiments.month[neutralPercentageIndex],
              positivePercentage:sentiments.month[positivePercentageIndex],
              totalNews:sentiments.month[totalNewsIndex],
              negativeSentiments:this._roundNumber2(sentiments.month[negativeSentimentsIndex]),
              neutralSentiments:this._roundNumber2(sentiments.month[neutralSentimentsIndex]),
              positiveSentiments:this._roundNumber2(sentiments.month[positiveSentimentsIndex]),
              sentimentIndicator:sentiments.month[sentimentIndicatorIndex]
            }
          } else {
            this._setDefaultSummarySentimentScores();
          }

          //// Chart Data
          let chartData:any = value[1]
          //console.log(value[1])
          let validateChartData = (data:any) => {
            if (!data || !data['fields'] || data['fields'].length == 0
                || !data['items'] || data['items'].length == 0) {
              warn('No chart data for selected country and date', 6000);
              return false;
            } else {
              let items = data['items']
              let fakeSentiment = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1].join(',')
              let allSentimentsInValid = items.every((i:any) => {
                if (i.length != 12) {
                  return true;
                }
                if (i.slice(1).join(',') == fakeSentiment) {
                  return true;
                }
                return false;
              })
              if (allSentimentsInValid) {
                warn('No chart data for selected country and date', 6000);
                return false;
              }
            }
            return true
          }
          if (validateChartData(chartData)) {
            // calculate indexes
            let chartFields = chartData.fields
            let chartDateIndex = chartFields.indexOf('date')
            let chartNegativeNewsIndex = chartFields.indexOf('negativeNews')
            let chartNeutralNewsIndex = chartFields.indexOf('neutralNews')
            let chartPositiveNewsIndex = chartFields.indexOf('positiveNews')
            let chartTotalNewsIndex = chartFields.indexOf('totalNews')
            let chartNegativePercentageIndex = chartFields.indexOf('negativePercentage')
            let chartNeutralPercentageIndex = chartFields.indexOf('neutralPercentage')
            let chartPositivePercentageIndex = chartFields.indexOf('positivePercentage')
            let chartNegativeSentimentsIndex = chartFields.indexOf('negativeSentiments')
            let chartNeutralSentimentsIndex = chartFields.indexOf('neutralSentiments')
            let chartPositiveSentimentsIndex = chartFields.indexOf('positiveSentiments')


            chartData.items.sort((a:any, b: any) => (a[chartDateIndex] > b[chartDateIndex])? 1 : -1).forEach((item:any) => {
              this.chartLabels.push(item[chartDateIndex] ? item[chartDateIndex].substring(5) : '')
              this.chartNegativeNewsData.push(item[chartNegativeNewsIndex])
              this.chartNeutralNewsData.push(item[chartNeutralNewsIndex]);
              this.chartPositiveNewsData.push(item[chartPositiveNewsIndex]);
              this.chartTotalNewsData.push(item[chartTotalNewsIndex]);
              this.chartNegativePercentageData.push(item[chartNegativePercentageIndex]);
              this.chartNeutralPercentageData.push(item[chartNeutralPercentageIndex]);
              this.chartPositivePercentageData.push(item[chartPositivePercentageIndex]);
              this.chartNegativeSentimentsData.push(this._roundNumber2(item[chartNegativeSentimentsIndex]));
              this.chartNeutralSentimentsData.push(this._roundNumber2(item[chartNeutralSentimentsIndex]));
              this.chartPositiveSentimentsData.push(this._roundNumber2(item[chartPositiveSentimentsIndex]));
            })
          }
          let params:any[] = [
            this.chartLabels,
            this.chartNegativeNewsData,
            this.chartNeutralNewsData,
            this.chartPositiveNewsData,
            this.chartTotalNewsData,
            this.chartNegativePercentageData,
            this.chartNeutralPercentageData,
            this.chartPositivePercentageData,
            this.chartNegativeSentimentsData,
            this.chartNeutralSentimentsData,
            this.chartPositiveSentimentsData
          ].map(a => {
            if (this.defaultDaysNumber == 30) {
              return a;
            } else {
              return a.slice(a.length - this.chartTimeframeValue, a.length)
            }
          })
          this._setBarChart(params[0], params[1], params[2], params[3], params[4], params[5],
            params[6], params[7], params[8], params[9], params[10])

          //// topic data
          if (!this.topicFilterDropdownInited) {
            let topicData = value[2]
            let validateTopicData = () => {}
            //console.log(value[2])
            if (!this.appStateService.hasTopicsData()) {
              this.appStateService.setTopicsData(topicData)
            }
            let topics:any = []
            topicData.filter((topic:any) => topic['country_inscope'] == true).forEach((topic:any) => {
              let category = topic.category
              let categoryObj:any = topics.find((e:any) => e.label == category)
              if (!categoryObj) {
                categoryObj = {
                  value: category,
                  label: category,
                  selected: true,
                  items: []
                }
                topics.push(categoryObj)
              }
              categoryObj.items.push({label: topic.description, value: topic.code, selected: true})
            })
            if (topics.length > 0) {
              this.topicFilterDropdownInited = true;
            }
            this._setTopicMultiSelect(topics)
          }

          //// news headline table data
          let newsGridData = value[3]
          //console.log(value[3])
          let tableData:any = [];
          if (this._validateNewsHeadlineData(newsGridData)) {
            tableData = this._parseNewsHeadlineResponse(newsGridData)
          }
          this._setGrid(tableData, newsGridData.pagination)

          this.currentCountry4DetailSection = this.selectedCountryName
          this.hiddenDetailSectionAll = false;
          this.detailGoBtnDisabled = true;
        }
        
        if (needTrendData) {
          let trendData = value[4]
          
          this.sixMonthTrendData = [[], [], [], [], [], [], [], [], [], [], [], []]
          this.sixMonthTrendLabels = [];
          let validateTrendData = (data:any) => {
            if (!(data.fields && data.fields.length == 6) || !data.items) {
              warn("Country trend data is invalid", 6000)
              return false;
            }
            return true
          }
          if (validateTrendData(trendData)) {
            // parse trend data
            let fields = trendData.fields
            let negativeSentimentsIndex = fields.indexOf('negativeSentiments')
            let neutralSentimentsIndex = fields.indexOf('neutralSentiments')
            let positiveSentimentsIndex = fields.indexOf('positiveSentiments')
            let negativePercentageIndex = fields.indexOf('negativePercentage')
            let neutralPercentageIndex = fields.indexOf('neutralPercentage')
            let positivePercentageIndex = fields.indexOf('positivePercentage')
            let items = trendData.items
            items.forEach((item:any) => {
              this.sixMonthTrendLabels.push(item.date.substr(5))
              this.sixMonthTrendData[0].push(item.week[positiveSentimentsIndex]);
              this.sixMonthTrendData[1].push(item.week[neutralSentimentsIndex]);
              this.sixMonthTrendData[2].push(item.week[negativeSentimentsIndex]);
              this.sixMonthTrendData[3].push(item.month[positiveSentimentsIndex]);
              this.sixMonthTrendData[4].push(item.month[neutralSentimentsIndex]);
              this.sixMonthTrendData[5].push(item.month[negativeSentimentsIndex]);
              this.sixMonthTrendData[6].push(item.week[positivePercentageIndex]);
              this.sixMonthTrendData[7].push(item.week[neutralPercentageIndex]);
              this.sixMonthTrendData[8].push(item.week[negativePercentageIndex]);
              this.sixMonthTrendData[9].push(item.month[positivePercentageIndex]);
              this.sixMonthTrendData[10].push(item.month[neutralPercentageIndex]);
              this.sixMonthTrendData[11].push(item.month[negativePercentageIndex]);
            })

            // set trend charts
            this._setTrendCharts(this.trendTimeframeValue)

            this.currentCountry4TrendSection = this.selectedCountryName
            this.hiddenTrendSectionAll = false;
            this.trendGoBtnDisabled = true;
          }
        }

        this.loading = false;
      },
      error: (e:any) => {
        this.loading = false;
        if (needDetailData) {
          this.hiddenDetailSectionAll = true;
        }
        if (needTrendData) {
          this.hiddenTrendSectionAll = true;
        }
        error(e.message, 6000)
      }
    })

  }

  _validateNewsHeadlineData(data:any) {
    if (!data || !data['fields'] || data['fields'].length == 0 || !data['items'] || data['items'].length == 0 ) {
      warn('No news headlines for selected country and date', 6000);
      return false;
    }
    return true
  }

  _parseNewsHeadlineResponse(response: any, pageNumber?: any) {
    let no = pageNumber ? (pageNumber - 1) * this.headlineTablePageSize + 1 : 1
    let tableData:any = []
    // calculate indexes
    let newsFields = response.fields
    let newsIdIndex = newsFields.indexOf('newsId')
    let newsDatetimeIndex = newsFields.indexOf('datetime')
    let newsMaxSentimentIndex = newsFields.indexOf('maxSentiment')
    let newsSentimentIndicatorIndex = newsFields.indexOf('sentimentIndicator')
    let newsSourceIndex = newsFields.indexOf('source')
    let newsHeadlineIndex = newsFields.indexOf('headline')
    response.items.forEach((item:any) => {
      let d = []
      let m = moment(item[newsDatetimeIndex])
      d.push(no++)
      d.push(item[newsIdIndex])
      d.push(m.format("YYYY-MM-DD (ddd)"))
      d.push(m.format("HH:mm:ss"))
      d.push(this._roundNumber2(item[newsMaxSentimentIndex]))
      d.push(item[newsSourceIndex])
      d.push(item[newsHeadlineIndex])
      d.push(item[newsSentimentIndicatorIndex])
      tableData.push(d)
    })
    return tableData;
  }

  _setCountryComboBox(data:any, value:any) {
    const dropdown:any = document.getElementById("country-country-combobox");
    dropdown.data = data
    dropdown.value = value
  }

  // lastDate:string - 2022-06-22
  _setDateFilterSelect(lastDate:any, selectedFilterDate?:string/*, dateRange?: number*/) {
    /*let vDateRange = dateRange ? dateRange : this.defaultDaysNumber;*/
    let vDateRange = 30
    let vSelectedFilterDate =  selectedFilterDate ? selectedFilterDate : lastDate
    this.dateFilterDropdownData = []
    if (vDateRange > 1) {
      this.dateFilterDropdownData.push({
        label: 'All Dates',
        value: 'ALL_DATES'
      })
    }

    for (let i = 0; i < vDateRange; i++) {
      let l = moment(lastDate, 'YYYY-MM-DD').subtract(i, 'days').format('YYYY-MM-DD');
      this.dateFilterDropdownData.push({
        label: l,
        value: l
      })
    }

    const dropdown:any = document.getElementById("country-date-filter-select");
    dropdown.data = this.dateFilterDropdownData
    dropdown.value = vSelectedFilterDate
    this.selectedFilterDate = vSelectedFilterDate
  }

  _setSortByFilterSelect() {
    const dropdown:any = document.getElementById("country-sort-by-filter-select");
    dropdown.data = [
      {
        label: "Date/Time (Ascending)",
        value: "date-asc",
      }, {
        label: "Date/Time (Descending)",
        value: "date-dsc",
      }, {
        label: "Sentiment (Ascending)",
        value: "sent-asc",
        disabled: true,
      }, {
        label: "Sentiment (Descending)",
        value: "sent-dsc",
        disabled: true,
      }
    ]
    dropdown.value = this.selectedFilterSortBy
  }

  _adjustSortByFilterSelect() {
    const dropdown:any = document.getElementById("country-sort-by-filter-select");
    if (this.selectedFilterSentiments.length == 1) {
      dropdown.data = [
        {
          label: "Date/Time (Ascending)",
          value: "date-asc",
        }, {
          label: "Date/Time (Descending)",
          value: "date-dsc",
        }, {
          label: "Sentiment (Ascending)",
          value: "sent-asc",
        }, {
          label: "Sentiment (Descending)",
          value: "sent-dsc",
        }
      ]
    } else {
      dropdown.data = [
        {
          label: "Date/Time (Ascending)",
          value: "date-asc",
        }, {
          label: "Date/Time (Descending)",
          value: "date-dsc",
        }, {
          label: "Sentiment (Ascending)",
          value: "sent-asc",
          disabled: true,
        }, {
          label: "Sentiment (Descending)",
          value: "sent-dsc",
          disabled: true
        }
      ]
      if (this.selectedFilterSortBy == "sent-asc" || this.selectedFilterSortBy == "sent-dsc") {
        this.selectedFilterSortBy = this.defaultSelectedFilterSortBy;
      }
    }
    dropdown.value = this.selectedFilterSortBy
  }

  _setTopicMultiSelect(topics:any) {
    const dropdown:any = document.getElementById("country-topic-multiselection");
    dropdown.data = topics;
  }
  
  _setTrendCharts(days?:number) {
    if (!days) {
      days = this.defaultTrendTimeframeValue
    }
    let totalDataLength = this.sixMonthTrendLabels.length
    let labels = this.sixMonthTrendLabels.slice(totalDataLength-this.trendTimeframeValue);
    let sentimentData7d = {
      positive: this.sixMonthTrendData[0].slice(totalDataLength-this.trendTimeframeValue),      
      neutral: this.sixMonthTrendData[1].slice(totalDataLength-this.trendTimeframeValue),
      negative: this.sixMonthTrendData[2].slice(totalDataLength-this.trendTimeframeValue)
    }
    let percentageData7d = {
      positive: this.sixMonthTrendData[6].slice(totalDataLength-this.trendTimeframeValue),      
      neutral: this.sixMonthTrendData[7].slice(totalDataLength-this.trendTimeframeValue),
      negative: this.sixMonthTrendData[8].slice(totalDataLength-this.trendTimeframeValue)
    }
    let sentimentData30d = {
      positive: this.sixMonthTrendData[3].slice(totalDataLength-this.trendTimeframeValue),      
      neutral: this.sixMonthTrendData[4].slice(totalDataLength-this.trendTimeframeValue),
      negative: this.sixMonthTrendData[5].slice(totalDataLength-this.trendTimeframeValue)
    }
    let percentageData30d = {
      positive: this.sixMonthTrendData[9].slice(totalDataLength-this.trendTimeframeValue),      
      neutral: this.sixMonthTrendData[10].slice(totalDataLength-this.trendTimeframeValue),
      negative: this.sixMonthTrendData[11].slice(totalDataLength-this.trendTimeframeValue)
    }
    
    this.trendChartsDataHidden = [[false, false, false, false, false, false], [false, false, false, false, false, false]]
    let lineChart:any = document.getElementById('six-month-trend-chart-7d');
    if (lineChart && lineChart.chart) {
      for (let i = 0; i < 6; i++) {
        let hidden = lineChart.chart.getDatasetMeta(i).hidden
        if (hidden === null) {
          hidden = lineChart.chart.config.data.datasets[i].hidden
        }
        this.trendChartsDataHidden[0][i] = !!hidden;
      }      
    }
    let lineChart1:any = document.getElementById('six-month-trend-chart-30d');
    if (lineChart1 && lineChart1.chart) {
      for (let i = 0; i < 6; i++) {
        let hidden = lineChart1.chart.getDatasetMeta(i).hidden
        if (hidden === null) {
          hidden = lineChart1.chart.config.data.datasets[i].hidden
        }
        this.trendChartsDataHidden[1][i] = !!hidden;
      }      
    }
    this._updateTrendChartConfig(labels, sentimentData7d, percentageData7d, sentimentData30d, percentageData30d);
  }

  _updateTrendChartConfig(labels:any, sentimentData7d:any, percentageData7d:any, sentimentData30d:any, percentageData30d:any) {
      let dataSet:any = [];
      dataSet = [
        {
          label: 'Sentiment Negative',
          data: sentimentData7d.negative,
          fill: false,
          borderColor: this.negativeColor,
          pointBorderColor: this.negativeColor,
          pointBackgroundColor: 'white',
          //borderDash: [10, 6],
          tension: 0.3,
          pointRadius: 1,
          type: 'line',
          yAxisID: 'B',
          hidden: this.trendChartsDataHidden[0][0],
        },
        {
          label: 'Sentiment Neutral',
          data: sentimentData7d.neutral,
          fill: false,
          borderColor: this.neutralColor,
          pointBorderColor: this.neutralColor,
          pointBackgroundColor: 'white',
          //borderDash: [10, 6],
          tension: 0.3,
          pointRadius: 1,
          type: 'line',
          yAxisID: 'B',
          hidden: this.trendChartsDataHidden[0][1],
        },
        {
          label: 'Sentiment Positive',
          data: sentimentData7d.positive,
          fill: false,
          borderColor: this.positiveColor,
          pointBorderColor: this.positiveColor,
          pointBackgroundColor: 'white',
          //borderDash: [10, 6],
          tension: 0.3,
          pointRadius: 1,
          type: 'line',
          yAxisID: 'B',
          hidden: this.trendChartsDataHidden[0][2],
        },
        {
          label: 'Percentage Negative',
          data: percentageData7d.negative,
          backgroundColor: 'white',
          borderColor: this._lightenDarkenColor(this.negativeColor, 120),
          borderWidth: 2,
          yAxisID: 'A',
          hidden: this.trendChartsDataHidden[0][3],
        },
        {
          label: 'Percentage Neutral',
          data: percentageData7d.neutral,
          backgroundColor: 'white',
          borderColor: this._lightenDarkenColor(this.neutralColor, 80),
          borderWidth: 2,
          yAxisID: 'A',
          hidden: this.trendChartsDataHidden[0][4],
        },
        {
          label: 'Percentage Positive',
          data: percentageData7d.positive,
          backgroundColor: 'white',
          borderColor: this._lightenDarkenColor(this.positiveColor, 50),
          borderWidth: 2,
          yAxisID: 'A',
          hidden: this.trendChartsDataHidden[0][5],
        },
      ]
      
      let lineChart:any = document.getElementById('six-month-trend-chart-7d');
      lineChart.config = {
        type: 'bar',
        data: {
          labels: labels,
          datasets: dataSet
        },
        options: {
          layout: {
            padding: {
                left: 10,
                right: 10,
                top: 0,
                bottom: 0
            }
          },
          legend: {
            display: true,
            labels: {
              fontSize: 16,
              fontStyle: 'bold',
            },
          },
          scales: {
            xAxes: [{
              stacked: true,
              offset: true,
            }],
            yAxes: [{
              id: "A",
              stacked: true,
              display: true, 
              position: 'right',
              scaleLabel: {
                display: true,
                labelString: 'Percentage'
              },
              ticks: {
                callback: function(value:any, index:any, ticks:any) {
                  return (value * 100).toFixed(0) + '%';
                }
              },
              type: 'linear'
            }, {
              id: 'B',
              stacked: false,
              position: 'left',
              scaleLabel: {
                display: true,
                labelString: 'Sentiment'
              },
            }]
          },
          tooltips: {
            titleFontSize: 13,
            bodyFontSize: 12,
            bodyFontStyle: 'bold',
            bodyFontColor: '#606060',
            callbacks: {
                label: (tooltipItem:any, data:any) => {
                  ///// BEGIN: to avoid add same tooltips multiple times when there are some values are same of one day
                  if (data.datasets[tooltipItem.datasetIndex].type == 'line') {
                    let sameVaulePointIndex:any = []
                    data.datasets.forEach((ds:any, index: number) => {
                      if((Number(ds.data[tooltipItem.index]) == Number(tooltipItem.value)) 
                        && !lineChart.chart.getDatasetMeta(index).hidden) {
                        sameVaulePointIndex.push(index)
                      }
                    })
                    if (sameVaulePointIndex.length > 1 && tooltipItem.datasetIndex != sameVaulePointIndex[0]) {
                      return; 
                    }
                  }
                  ///// END

                  let index = tooltipItem.index;
                  let label = [
                    `Sentiment Negative: ${data.datasets[0].data[index]}`,
                    `Sentiment Neutral:  ${data.datasets[1].data[index]}`,
                    `Sentiment Positive: ${data.datasets[2].data[index]}`,
                    `Percentage Negative: ${data.datasets[3].data[index]}`,
                    `Percentage Neutral:  ${data.datasets[4].data[index]}`,
                    `Percentage Positive: ${data.datasets[5].data[index]}`,
                  ]
                  return label;
                }
            }
          }
        }
      };
      let dataSet1:any = [];
      dataSet1 = [
        {
          label: 'Sentiment Negative',
          data: sentimentData30d.negative,
          fill: false,
          borderColor: this.negativeColor,
          pointBorderColor: this.negativeColor,
          pointBackgroundColor: 'white',
          //borderDash: [10, 6],
          tension: 0.3,
          pointRadius: 1,
          type: 'line',
          yAxisID: 'B',
          hidden: this.trendChartsDataHidden[1][0],
        },
        {
          label: 'Sentiment Neutral',
          data: sentimentData30d.neutral,
          fill: false,
          borderColor: this.neutralColor,
          pointBorderColor: this.neutralColor,
          pointBackgroundColor: 'white',
          //borderDash: [10, 6],
          tension: 0.3,
          pointRadius: 1,
          type: 'line',
          yAxisID: 'B',
          hidden: this.trendChartsDataHidden[1][1],
        },
        {
          label: 'Sentiment Positive',
          data: sentimentData30d.positive,
          fill: false,
          borderColor: this.positiveColor,
          pointBorderColor: this.positiveColor,
          pointBackgroundColor: 'white',
          //borderDash: [10, 6],
          tension: 0.3,
          pointRadius: 1,
          type: 'line',
          yAxisID: 'B',
          hidden: this.trendChartsDataHidden[1][2],
        },
        {
          label: 'Percentage Negative',
          data: percentageData30d.negative,
          backgroundColor: 'white',
          borderColor: this._lightenDarkenColor(this.negativeColor, 120),
          borderWidth: 2,
          yAxisID: 'A',
          hidden: this.trendChartsDataHidden[1][3],
        },
        {
          label: 'Percentage Neutral',
          data: percentageData30d.neutral,
          backgroundColor: 'white',
          borderColor: this._lightenDarkenColor(this.neutralColor, 80),
          borderWidth: 2,
          yAxisID: 'A',
          hidden: this.trendChartsDataHidden[1][4],
        },
        {
          label: 'Percentage Positive',
          data: percentageData30d.positive,
          backgroundColor: 'white',
          borderColor: this._lightenDarkenColor(this.positiveColor, 50),
          borderWidth: 2,
          yAxisID: 'A',
          hidden: this.trendChartsDataHidden[1][5],
        },
      ]
      
      let lineChart1:any = document.getElementById('six-month-trend-chart-30d');
      lineChart1.config = {
        type: 'bar',
        data: {
          labels: labels,
          datasets: dataSet1
        },
        options: {
          layout: {
            padding: {
                left: 10,
                right: 10,
                top: 0,
                bottom: 0
            }
          },
          legend: {
            display: true,
            labels: {
              fontSize: 16,
              fontStyle: 'bold',
            },
          },
          scales: {
            xAxes: [{
              stacked: true,
              offset: true,
            }],
            yAxes: [{
              id: "A",
              stacked: true,
              display: true, 
              position: 'right',
              scaleLabel: {
                display: true,
                labelString: 'Percentage'
              },
              ticks: {
                callback: function(value:any, index:any, ticks:any) {
                  return (value * 100).toFixed(0) + '%';
                }
              },
              type: 'linear'
            }, {
              id: 'B',
              stacked: false,
              position: 'left',
              scaleLabel: {
                display: true,
                labelString: 'Sentiment'
              },
            }]
          },
          tooltips: {
            titleFontSize: 13,
            bodyFontSize: 12,
            bodyFontStyle: 'bold',
            bodyFontColor: '#606060',
            callbacks: {
                label: (tooltipItem:any, data:any) => {
                  ///// BEGIN: to avoid add same tooltips multiple times when there are some values are same of one day
                  if (data.datasets[tooltipItem.datasetIndex].type == 'line') {
                    let sameVaulePointIndex:any = []
                    data.datasets.forEach((ds:any, index: number) => {
                      if((Number(ds.data[tooltipItem.index]) == Number(tooltipItem.value)) 
                        && !lineChart1.chart.getDatasetMeta(index).hidden) {
                        sameVaulePointIndex.push(index)
                      }
                    })
                    if (sameVaulePointIndex.length > 1 && tooltipItem.datasetIndex != sameVaulePointIndex[0]) {
                      return; 
                    }
                  }
                  ///// END

                  let index = tooltipItem.index;
                  let label = [
                    `Sentiment Negative: ${data.datasets[0].data[index]}`,
                    `Sentiment Neutral:  ${data.datasets[1].data[index]}`,
                    `Sentiment Positive: ${data.datasets[2].data[index]}`,
                    `Percentage Negative: ${data.datasets[3].data[index]}`,
                    `Percentage Neutral:  ${data.datasets[4].data[index]}`,
                    `Percentage Positive: ${data.datasets[5].data[index]}`,
                  ]
                  return label;
                }
            }
          }
        }
      };
  }

  _setBarChart(chartLabels:any,
    chartNegativeNewsData:any,
    chartNeutralNewsData:any,
    chartPositiveNewsData:any,
    chartTotalNewsData:any,
    chartNegativePercentageData:any,
    chartNeutralPercentageData:any,
    chartPositivePercentageData:any,
    chartNegativeSentimentsData:any,
    chartNeutralSentimentsData:any,
    chartPositiveSentimentsData:any ) {
    let barChart:any = document.getElementById('country-bar-chart');
    barChart.config =
    {
      type: 'bar',
      datalabels: {
        align: 'end',
        anchor: 'start'
      },
      plugins: [ChartDataLabels, {
        // Increase spacing between legend and chart
        beforeInit: function(chart: any, options: any) {
          chart.legend.afterFit = function() {
            this.height = this.height + 10;
          };
        }
      }],
      data: {
        labels: chartLabels,
        datasets: [
          {
            label: 'Negative',
            data: chartNegativeNewsData,
            backgroundColor: this.negativeColor,
            borderColor: '#cfcfcf',
            borderWidth: 1
          },
          {
            label: 'Neutral',
            data: chartNeutralNewsData,
            backgroundColor: this.neutralColor,
            borderColor: '#cfcfcf',
            borderWidth: 1
          }, {
            label: 'Positive',
            data: chartPositiveNewsData,
            backgroundColor: this.positiveColor,
            borderColor: '#cfcfcf',
            borderWidth: 1,
            // datalabels: {
            //   anchor: 'end',
            //   align: 'start',
            // }
          }
        ]
      },
      options: {
        layout: {
          padding: {
              left: 10,
              right: 10,
              top: 0,
              bottom: 0
          }
        },
        legend: {
          display: true,
          labels: {
            fontSize: 16,
            fontStyle: 'bold',
          },
        },
        onClick: (e:any) => {
          // set filters by clicking on chart bar
          // NOTE: keep bar click handler before x-axis label click handler
          let chartElement:any = barChart.chart.getElementAtEvent(e)[0];
          if (chartElement) {
            let xIndex = chartElement._index;
            let yIndex = chartElement._datasetIndex;
            //console.log(chartElement, xIndex, yIndex)
            let dateFilterValueIndex = (this.chartTimeframeValue  - xIndex) /* (this.chartTimeframeValue == 1) ? (this.chartTimeframeValue - 1 - xIndex) : (this.chartTimeframeValue  - xIndex) */
            this._setDateFilterValueByIndex(dateFilterValueIndex)
            this._setSentimentFilterValues([(yIndex - 1) + ''])
            this.detailFilterGoBtnDisabled = false;
            return;
          }

          // set filters by clicking on x-axis label
          let nearestX = barChart.chart.getElementsAtEventForMode(e, 'nearest', {intersect: false, axis: 'x'});
          let nearestY = barChart.chart.getElementsAtEventForMode(e, 'nearest', {intersect: false, axis: 'y'});
          //console.log(nearestX, nearestY)
          if (nearestX.length && nearestY.length) {
            let barX = nearestX[0];
            let barY = nearestY[0];
            //console.log(barX._datasetIndex, barX._index, barY._datasetIndex, barY._index)
            if (barX._datasetIndex == 0 && barY._datasetIndex == 0) {
              let dateFilterValueIndex = (this.chartTimeframeValue  - barX._index) /* (this.chartTimeframeValue == 1) ? (this.chartTimeframeValue - 1 - xIndex) : (this.chartTimeframeValue  - xIndex) */
              this._setDateFilterValueByIndex(dateFilterValueIndex)
              this._setSentimentFilterValues(['1', '0', '-1'])
              this.detailFilterGoBtnDisabled = false;
              return;
            }
          }
        },
        scales: {
          xAxes: [{
            stacked: true,
            offset: true,
          }],
          yAxes: [{
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: 'News Items',
              fontSize: 12,
              fontStyle: 'bold'
            }
          }]
        },
        plugins: {
          datalabels: {
            color: 'black',
            display: function(context:any) {
              let total = context.chart.scales['y-axis-0'].max
              if (!total) {
                total = 100
              }
              return (context.dataset.data[context.dataIndex] / total ) > 0.07
            },
            font: {
              weight: 'normal'
            },
            formatter: function(value:any, context:any) {
              let dataIndex = context.dataIndex;
              let datasetIndex = context.datasetIndex;
              if (datasetIndex == 2) {
                return chartPositivePercentageData[dataIndex] + '%';
              } else if(datasetIndex == 1) {
                return chartNeutralPercentageData[dataIndex] + '%';
              } else {
                return  chartNegativePercentageData[dataIndex] + '%';
              }
            }
          }
        },
        tooltips: {
          titleFontSize: 13,
          bodyFontSize: 12,
          bodyFontStyle: 'bold',
          bodyFontColor: '#606060',
          callbacks: {
              label: function(tooltipItem:any, data:any) {
                let index = tooltipItem.index;
                let label = [
                  `Positive: ${chartPositiveNewsData[index]} (${chartPositivePercentageData[index]}%) ${chartPositiveSentimentsData[index]}`,
                  `Neutral: ${chartNeutralNewsData[index]} (${chartNeutralPercentageData[index]}%) ${chartNeutralSentimentsData[index]}`,
                  `Negative: ${chartNegativeNewsData[index]} (${chartNegativePercentageData[index]}%) ${chartNegativeSentimentsData[index]}`,
                  `Total: ${chartTotalNewsData[index]}`
                ]
                return label;
              }
          }
        }
      }
    }
  }

  _setGrid(newsData: any, paginationData: any): void {
    let textHighlightFommater = {
      render: function(){},
      bind: (rowIndex:any, colIndex:any, value:any, cell:any) => {
        var content = cell.getContent();
        content = document.createElement("span");
        content.textContent = value

        let rowData:any = newsData[rowIndex]
        let sentimentIndicator: any = rowData[7]
        if (sentimentIndicator == 1) {
          cell.setStyle('color', this.positiveColor)
        } else if (sentimentIndicator == 0){
          cell.setStyle('color', this.neutralColor)
        } else if (sentimentIndicator == -1) {
          cell.setStyle('color', this.negativeColor)
        }
        cell.setContent(content);
        cell.setAttribute('title', value)
      },
    }
    let linkFormatter = {
      render: function(){},
      bind: (rowIndex:any, colIndex:any, value:any, cell:any) => {
        var content = cell.getContent();
        content = document.createElement("a");
        content.textContent = value
        content.addEventListener('click', () => {
          this._resetNewsPopupPanelData();
          const grid: any = document.getElementById("country-news-grid");
          const allRowData = grid.api.getDataView().getAllRowData();
          let rowData:any = allRowData[rowIndex]
          //console.log('rowData:', rowData)
          let newsId = rowData['newsId']
          this.loading = true;
          this.sentimentService.requestCountryNewsDetail(newsId, this.selectedCountry).subscribe({
            next: (value:any) => {
              let validateNewsData = (news:any) => {
                if (!news || !news['headline'] || !news['timestamp']
                    || !news['source'] || !news['negativeSentiment'] || !news['neutralSentiment']
                    || !news['positiveSentiment'] || !news['topics']) {
                  error("Failed to get news detail informaton.", 6000);
                  return false;
                }
                return true;
              }
              if (validateNewsData(value)) {
                let newsDetailData = {
                  headline: value['headline'],
                  timestamp: moment(value['timestamp']).format("dddd - MMMM Do YYYY HH:mm:ss"),
                  body: value['body'] ? value['body'] : "<<There is no content for this headline.>>",
                  source: value['source'],
                  negativeSentiment: this._roundNumber2(value['negativeSentiment']),
                  neutralSentiment: this._roundNumber2(value['neutralSentiment']),
                  positiveSentiment: this._roundNumber2(value['positiveSentiment']),
                  topics: value['topics'].map((v:any) => `${v['description']} [${v['code']}]`)
                }
                //console.log(newsDetailData)
                this.newsPopupPanelData = newsDetailData
                var popup:any = document.getElementById('country-news-popup-panel');
                popup.opened = true;
              }
              this.loading = false;
            },
            error: (e:any) => {
              this.loading = false;
              this.hiddenDetailSectionAll = true;
              error(e.message, 6000)
            }
          })
        })
        content.style['cursor'] = "pointer";
        cell.setContent(content);
      },
    }

    let fields = ['no', 'newsId', 'date', 'time', 'sentiment', 'source', 'title', 'sentimentIndicator'];
    const columns: CompositeGrid.Column[] = [
      { id: 'c0', title: 'No', field: fields[0], width:50, styles: {
        'border-left': 'none',
        'border-top': 'none',
        'border-right': 'none',
        'border-bottom': '2px solid black !important'}},
      { id: 'c1', field: fields[1], visible:false},
      { id: 'c2', title: 'Date', width: 120, field: fields[2], visible:true, alignment: 'left', styles: {
        'border-left': 'none',
        'border-top': 'none',
        'border-right': 'none',
        'border-bottom': '2px solid black !important'}},
      { id: 'c3', title: 'Timestamp', width: 100, field: fields[3], styles: {
      'border-left': 'none',
      'border-top': 'none',
      'border-right': 'none',
      'border-bottom': '2px solid black !important'} },
      { id: 'c4', title: 'Sentiment', width: 90, field: fields[4], styles: {
      'border-left': 'none',
      'border-top': 'none',
      'border-right': 'none',
      'border-bottom': '2px solid black !important'}, formatter: textHighlightFommater},
      { id: 'c5', title: 'Source', width: 120, field: fields[5], alignment: 'left', styles: {
      'border-left': 'none',
      'border-top': 'none',
      'border-right': 'none',
      'border-bottom': '2px solid black !important'}},
      { id: 'c6', title: 'Headline', minWidth: 500, field: fields[6], alignment: 'left', styles: {
      'border-left': 'none',
      'border-top': 'none',
      'border-right': 'none',
      'border-bottom': '2px solid black !important'}, formatter: linkFormatter },
      { id: 'c7', field: fields[7], visible:false},
    ];

    // let onPageChanged = () => {
    //   console.log('page changed')
    // }

    const config: CompositeGrid.Options = {
      rowHeight: 40,
      // sorting: {
      //   sortableColumns: true,
      //   // multiColumn: true,
      //   threeStatesSorting: true
      // },
      columns: columns,
      dataModel: {
        fields: fields,
        data: newsData,
      },
      whenDefined: function (e: any) {
        let api: CompositeGrid = e.api as CompositeGrid;
        // let core: CoreGrid = api.getCoreGrid();
        // let dv: DataView = api.getDataView();
        // Do something with grid with api...
      },
      // pagination: {
      //     element: "country-news-grid-pagination",
      //     page: 1,
      //     pageSize: 10,
      //     pageChanged: onPageChanged
      // },
      // rowGrouping: {
      //   groupBy: 'day'
      // },
      // extensions: [new PaginationExtension(), /*new RowGroupingExtension()*/]
      extensions: [new CellSelectionExtension(), new ContextMenuExtension()],
      contextMenu: this.contextMenuModel,
      // rowSelection: {
      //   basedOnContent: true
      // }
    } as CompositeGrid.Options;

    //this.dashboardGrid.config = config;
    const grid: any = document.getElementById("country-news-grid");
    grid.config = config;
    const pagination: any = document.getElementById("country-news-grid-pagination");
    pagination.max = Math.ceil(paginationData.total / this.headlineTablePageSize);
  }

  onCountryChange(event: any) {
    if (event.detail.value == '') {
      this.trendGoBtnDisabled = true;
      this.detailGoBtnDisabled = true;
      return; // ignor empty value;
    }
    if (event.detail.value == this.selectedCountry) {
      if (!this.topicFilterDropdownInited) {
        this.detailGoBtnDisabled = false;
      }
      return;
    }
    this.trendGoBtnDisabled = false;
    this.selectedCountry = event.detail.value
    let countryData = this.countriesDropdownData.find((c:any) => c.value == this.selectedCountry)
    let countryStartDate = countryData.startDate
    if (countryStartDate && moment(this.selectedDate, 'YYYY-MM-DD').diff(countryStartDate, "days") < 0) {
      info(`${this.selectedDate} is earlier than the start date ${countryStartDate} of the country. Change it to yesterday`, 6000)
      this.selectedDate = this.yesterday
    }
    this._setDatetimePicker(countryStartDate)
    this.selectedCountryName = countryData['label'].substr(0, countryData['label'].indexOf(' ('))
    this.detailGoBtnDisabled = false;
  }

  onDateChange(event: any) {
    this.selectedDate = event.detail.value
    this.detailGoBtnDisabled = false;
  }

  onTrendGoBtnClick() {
    // rest widgets
    // request data
    this._resetTrendSection();
    this._requestData(this.selectedCountry, this.selectedDate, false, true)
  }

  onDetailGoBtnClick() {
    // rest widgets
    // request data
    //this.hiddenDetailSectionAll = true;
    this._resetDetailSection();
    this._requestData(this.selectedCountry, this.selectedDate, true, false)
    this._setDateFilterSelect(this.selectedDate)
  }

  onDateFilterChange(event:any) {
    this.detailFilterGoBtnDisabled = false;
    this.selectedFilterDate = event.detail.value
    //console.log(event)
  }

  onSentimentFilterChange(event:any) {
    this.detailFilterGoBtnDisabled = false;
    this.selectedFilterSentiments = event.target.values
    // need to adjust Sort filter after Sentiments multi select change
    this._adjustSortByFilterSelect()
  }

  onSortByFilterChange(event:any) {
    this.detailFilterGoBtnDisabled = false;
    this.selectedFilterSortBy = event.detail.value
  }

  onTopicChange(event:any) {
    this.detailFilterGoBtnDisabled = false;
    let valueData = event.target.data.map((d:any) => d.items).flat();
    let values = event.target.values
    let allSelected = (values.length == valueData.length)
    if (allSelected) {
      // all selected equals non selection
      this.selectedFilterTopics = []
    } else {
      this.selectedFilterTopics = event.target.values
    }
  }

  onTopicGoBtnClick() {
    this._resetPagination(1);
    let payload: any = {countryId: this.selectedCountry, pageSize: this.headlineTablePageSize};
    if (this.selectedFilterDate == "ALL_DATES") {
      payload.date = this.selectedDate;
      payload['back30date'] = moment(this.selectedDate, 'YYYY-MM-DD').subtract(30 - 1, 'days').format('YYYY-MM-DD');
    } else {
      payload.date = this.selectedFilterDate
    }
    if (this.selectedFilterSentiments.length > 0 && this.selectedFilterSentiments.length < 3) {
      payload.selectedSentiments = this.selectedFilterSentiments.join(',')
    }
    if (this.selectedFilterTopics.length > 0) {
      payload.selectedSubjects = this.selectedFilterTopics.join(',')
    }
    if (this.selectedFilterSortBy != this.defaultSelectedFilterSortBy) {
      payload.sort = this.selectedFilterSortBy
    }
    this.loading = true;
    this.sentimentService.requestCountryHeadlines(payload).subscribe({
      next:  (v:any) => {
        let newsGridData = v
        let tableData:any = [];
        if (this._validateNewsHeadlineData(v)) {
          tableData = this._parseNewsHeadlineResponse(newsGridData)
        }
        this._setGrid(tableData, newsGridData.pagination)

        this.hiddenDetailSectionAll = false;
        this.loading = false;
        this.detailFilterGoBtnDisabled = true;

        setTimeout(() => {
          let element:any = document.getElementById("country-news-grid");
        element.scrollIntoView();

        this.loading = false;}, 0)
        //setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 0)
      },
      error: (e:any) => {
        this.loading = false;
        error(e.message, 6000)
      }
    });
  }

  onNewsPopupPrintClick() {
    // the format for printing is by CSS
    let navSection:any = document.getElementById('nav-section')
    navSection.classList.toggle('no-print')
    window.print();
    navSection.classList.toggle('no-print')
  }

  _setDatetimePicker(countryStartDate?: string) {
    this.yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
    this.maxDate = this.yesterday;

    let minMinDate = moment().subtract(1 + 335, 'days').format('YYYY-MM-DD')
    if (countryStartDate) {
      let csDate = moment(countryStartDate, 'YYYY-MM-DD')
      if (csDate.diff(minMinDate, 'days') > 0) {
        this.minDate = csDate.format('YYYY-MM-DD');
      } else {
        this.minDate = minMinDate;
      }
    } else {
      this.minDate = minMinDate;
    }
  }

  _setDateFilterValue(v:string) {
    const dropdown:any = document.getElementById("country-date-filter-select");
    dropdown.value = v
    this.selectedFilterDate = v
  }

  _setDateFilterValueByIndex(index:number) {
    const dropdown:any = document.getElementById("country-date-filter-select");
    let data = dropdown.data
    dropdown.value = data[index]['value']
    this.selectedFilterDate = data[index]['value']
  }

  _setSentimentFilterValues(vs:string[]) {
    let multiSelect:any = document.getElementById('country-sentiment-filter-select');
    multiSelect.values = vs
    this.selectedFilterSentiments = vs;
    // need to adjust Sort filter after Sentiments multi select change
    this._adjustSortByFilterSelect()
  }

  _setNewsGridPagination() {
    const pagination: any = document.getElementById("country-news-grid-pagination");
    pagination.addEventListener('value-changed', (event: any) => {
      let targetPageNum = event.detail.value

      let payload: any = {countryId: this.selectedCountry, pageSize: this.headlineTablePageSize, pageNumber: targetPageNum};
      if (this.selectedFilterDate == "ALL_DATES") {
        payload.date = this.selectedDate;
        payload['back30date'] = moment(this.selectedDate, 'YYYY-MM-DD').subtract(30 - 1, 'days').format('YYYY-MM-DD');
      } else {
        payload.date = this.selectedFilterDate
      }
      if (this.selectedFilterSentiments.length > 0 && this.selectedFilterSentiments.length < 3) {
        payload.selectedSentiments = this.selectedFilterSentiments.join(',')
      }
      if (this.selectedFilterTopics.length > 0) {
        payload.selectedSubjects = this.selectedFilterTopics.join(',')
      }
      if (this.selectedFilterSortBy != this.defaultSelectedFilterSortBy) {
        payload.sort = this.selectedFilterSortBy
      }
      this.loading = true;
      this.sentimentService.requestCountryHeadlines(payload).subscribe({
        next:  (v:any) => {
          let newsGridData = v
          let tableData:any = []
          if (this._validateNewsHeadlineData(v)) {
            tableData = this._parseNewsHeadlineResponse(newsGridData, targetPageNum)
          }
          this._setGrid(tableData, newsGridData.pagination)

          this.hiddenDetailSectionAll = false;
          setTimeout(() => {let element:any = document.getElementById("country-news-grid-pagination");
          element.scrollIntoView();
          this.loading = false;}, 0)
          //setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 0)
        },
        error: (e:any) => {
          this.loading = false;
          error(e.message, 6000)
        }
      });
    });
  }

  _roundNumber2(value:any) {
    let v = Number(value);
    return v.toFixed(2)
  }

  _resetTrendSection() {
    this.currentCountry4TrendSection = ''
    this.sixMonthTrendData = [[], [], [], [], [], [], [], [], [], [], [], []]
    this.sixMonthTrendLabels = [];
    this._setTrendCharts()
  }

  _resetDetailSection() {
    this.currentCountry4DetailSection = ''
    this._resetChart();
    this._resetGrid();
    this._resetPagination(1);
  }

  _resetGrid() {
    let tableData:any = [];
    this._setGrid(tableData, {total: 0})
  }

  _resetChart() {
    this.chartLabels = []
    this.chartNegativeNewsData = []
    this.chartNeutralNewsData = []
    this.chartPositiveNewsData = []
    this.chartTotalNewsData = []
    this.chartNegativePercentageData = []
    this.chartNeutralPercentageData = []
    this.chartPositivePercentageData = []
    this.chartNegativeSentimentsData = []
    this.chartNeutralSentimentsData = []
    this.chartPositiveSentimentsData = []
    this._setBarChart(this.chartLabels, this.chartNegativeNewsData, this.chartNeutralNewsData,
      this.chartPositiveNewsData, this.chartTotalNewsData, this.chartNegativePercentageData,
      this.chartNeutralPercentageData, this.chartPositivePercentageData, this.chartNegativeSentimentsData,
      this.chartNeutralSentimentsData, this.chartPositiveSentimentsData)
  }

  _resetTimeframeToggle() {
    const timeframeToggleBtn1d:any = document.getElementById('country-timeframe-split-btn-1d');
    timeframeToggleBtn1d.active = this.defaultDaysNumber == 1;
    const timeframeToggleBtn7d:any = document.getElementById('country-timeframe-split-btn-7d');
    timeframeToggleBtn7d.active = this.defaultDaysNumber == 7;
    const timeframeToggleBtn30d:any = document.getElementById('country-timeframe-split-btn-30d');
    timeframeToggleBtn30d.active = this.defaultDaysNumber == 30;
    this.chartTimeframeValue = this.defaultDaysNumber;
  }

  _resetPagination(max?:number) {
    const pagination: any = document.getElementById("country-news-grid-pagination");
    if (max) {
      pagination.max = max;
    }
    pagination.value = 1;
  }

  _resetDetailSectionData() {
    this.currentCountry4DetailSection = ''
    this._setDefaultSummarySentimentScores()
    this.chartLabels = []
    this.chartNegativeNewsData = []
    this.chartNeutralNewsData = []
    this.chartPositiveNewsData = []
    this.chartTotalNewsData = []
    this.chartNegativePercentageData = []
    this.chartNeutralPercentageData = []
    this.chartPositivePercentageData = []
    this.chartNegativeSentimentsData = []
    this.chartNeutralSentimentsData = []
    this.chartPositiveSentimentsData = []
  }

  _resetNewsPopupPanelData() {
    this.newsPopupPanelData = {
      headline: '',
      timestamp: '',
      body: '',
      source: '',
      negativeSentiment: '',
      neutralSentiment: '',
      positiveSentiment: '',
      topics: []
    }
  }

  enlargeHeadlineContentFontSize() {
    if (this.headlineContentFontSize <= 2.5) {
      this.headlineContentFontSize += 0.1
      this.headlineContentFontSizeStr = this.headlineContentFontSize + 'em'
    }
  }

  shrinkHeadlineContentFontSize() {
    if (this.headlineContentFontSize >= 0.4) {
      this.headlineContentFontSize -= 0.1
      this.headlineContentFontSizeStr = this.headlineContentFontSize + 'em'
    }
  }

  // Prepare model for context-menu
  readonly contextMenuModel: any = {
    items: {
      COPY_CELL: {
        text: "Copy",
        callback: (e:any) => {
          let text = "No Selected Cell";
          if (e.cell) {
            text = e.cell.getTextContent()
          }
          navigator.clipboard.writeText(text)
        }
      },
    },
    onMenu: function(e:any) {
      var context = e.context;
      var menu = e.menu;
      menu.addItems("COPY_CELL");
    }
  };
}
