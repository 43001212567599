import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  private countriesData: any;
  private companiesData: any;
  private topicsData: any;

  constructor() {
    this.resetAppState()
  }

  resetAppState() {
    this.countriesData = []
  }

  setCountriesData(data: any) {
    this.countriesData = data
  }

  getCountriesData() {
    return this.countriesData;
  }

  hasCountriesData(): boolean {
    return this.countriesData && this.countriesData.length > 0
  }

  setCompaniesData(data: any) {
    this.companiesData = data
  }

  getCompaniesData() {
    return this.companiesData;
  }

  hasCompaniesData(): boolean {
    return this.companiesData && this.companiesData.length > 0
  }

  setTopicsData(data: any) {
    this.topicsData = data
  }

  getTopicsData() {
    return this.topicsData;
  }

  hasTopicsData(): boolean {
    return this.topicsData && this.topicsData.length > 0
  }

  clearCache() {
    this.companiesData = null;
    this.countriesData = null;
    this.topicsData = null;
  }

}