import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/guard/auth.guard';
import { CounterpartyDashboardComponent } from './counterparty-dashboard/counterparty-dashboard.component';
import { CounterpartyComponent } from './counterparty/counterparty.component';
import { CountryComponent } from './country/country.component';
import { CountryDashboardComponent } from './country-dashboard/country-dashboard.component';
import { MainComponent } from './main/main.component';
import { ReferenceDataComponent } from './reference-data/reference-data.component';
import { AdminComponent } from './admin/admin.component';
import { AdminGuard } from 'src/guard/admin.guard';
import { AvalailableComponentGuard } from 'src/guard/available-component.guard';

const routes: Routes = [
  { path: '',   redirectTo: '/country-dashboard', pathMatch: 'full' },
  { path: 'main', component: MainComponent },
  { path: 'country-dashboard', component: CountryDashboardComponent, canActivate: [AuthGuard, AvalailableComponentGuard], data: {reuse: true}  },
  { path: 'counterparty', component: CounterpartyComponent, canActivate: [AuthGuard, AvalailableComponentGuard], data: {reuse: true} },
  { path: 'counterparty-dashboard', component: CounterpartyDashboardComponent, canActivate: [AuthGuard, AvalailableComponentGuard], data: {reuse: true} },
  { path: 'country', component: CountryComponent, canActivate: [AuthGuard, AvalailableComponentGuard], data: {reuse: true} },
  { path: 'reference-data', component: ReferenceDataComponent, canActivate: [AuthGuard, AvalailableComponentGuard], data: {reuse: true} },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard, AvalailableComponentGuard, AdminGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
