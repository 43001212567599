import { Component } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';

import '@elf/amber-loader';
import '@elf/coral-button';
import '@elf/coral-panel';
import '@elf/coral-text-field';
import '@elf/coral-password-field';
import '@elf/coral-dialog';
import '@elf/coral-popup-panel';
import '@elf/amber-notification';
import '@elf/emerald-popup-menu';

import '@elf/elf-theme-halo/light/imports/native-elements';
import '@elf/amber-loader/themes/halo/light';
import '@elf/coral-button/themes/halo/light';
import '@elf/coral-panel/themes/halo/light';
import '@elf/coral-text-field/themes/halo/light';
import '@elf/coral-password-field/themes/halo/light';
import '@elf/coral-dialog/themes/halo/light';
import '@elf/coral-popup-panel/themes/halo/light';
import '@elf/amber-notification/themes/halo/light';
import '@elf/emerald-popup-menu/themes/halo/light'
import { AuthService } from 'src/service/auth.service';
import { info, confirm, warn, error } from '@elf/amber-notification/lib/helpers';
import { Router } from '@angular/router';
import { ClientEventType, PubSubService } from 'src/service/pub-sub.service';
import { ConfigService } from 'src/service/config.service';
import { AppStateService } from 'src/service/app-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public loading: boolean = false;
  public loggedIn: boolean = false;
  public title: string = "Login";
  public loginForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });
  loginDlgOpened: boolean = false;
  usernameMenuOpened: boolean = false;
  hasAdminPrivilege: boolean = false;
  public shortUserId: string = "Login"

  countryDashboardEnabled: boolean = false;
  countryEnabled: boolean = false;
  companyDashboardEnabled: boolean = false;
  companyEnabled: boolean = false;
  referenceDataEnabled: boolean = false;
  adminEnabled: boolean = false;

  companyDashboardComponentName: string;
  companyComponentName: string;
  readonly defaultOrgName: string = 'RFT'
  orgName: string = this.defaultOrgName
  logoFileName: string = this.defaultOrgName;

  constructor(private router: Router,
              private fb: FormBuilder,
              private authService: AuthService,
              private pubSubService: PubSubService,
              private configService: ConfigService,
              private appStateService: AppStateService) {
    this.companyDashboardComponentName = this.configService.defaultValues['companyDashboardComponentName']
    this.companyComponentName = this.configService.defaultValues['companyComponentName']
  }

  ngOnInit() {
    this.pubSubService.subscribeClientEvent(type => {
      if (type == ClientEventType.showLoadingBlocker) {
        this.loading = true
      }
      if (type == ClientEventType.hideLoadingBlocker) {
        this.loading = false
      }
      if (type == ClientEventType.showLoginDialog) {
        this.loginDlgOpened = true;
      }
    });

    if (this.authService.isLoggedIn()) {
      this.loggedIn = true;
      let userId = this.authService.userInfo['username']
      //console.log(this.authService.userInfo)
      this.shortUserId = userId.indexOf('@') == -1 ? userId : userId.substr(0, userId.indexOf('@'))
      this.orgName = this.authService.userInfo['org_name'] ? this.authService.userInfo['org_name'] : this.defaultOrgName;
      if (this.orgName == "HDFC") {
        this.logoFileName = "HDFC"
      } else {
        this.logoFileName = "RFT"
      }
      this.initComponentVariables();
      this.hasAdminPrivilege = this.authService.isAdmin()
      let frontendObj = this.authService.userInfo['frontend_config']
      if (frontendObj && frontendObj['common']) {
        if (frontendObj['common']['companyDashboardComponentName']) {
          this.companyDashboardComponentName = frontendObj['common']['companyDashboardComponentName']
        }
        if (frontendObj['common']['companyComponentName']) {
          this.companyComponentName = frontendObj['common']['companyComponentName']
        }
      }
    } else {
      this.loginDlgOpened = true;
    }

    let usernameTextInput:any = document.getElementById("login-username");
    usernameTextInput.addEventListener("keyup", (event:any) => {
      if(event.key !== "Enter" || !(this.password && this.password.value)) return;
      this.login();
      event.preventDefault();
    });
    let passwordTextInput:any = document.getElementById("login-password");
    passwordTextInput.addEventListener("keyup", (event:any) => {
      if(event.key !== "Enter" || !(this.username && this.username.value)) return;
      this.login();
      event.preventDefault();
    });
  }

  initComponentVariables() {
    let frontendConfigObj = this.authService.userInfo['frontend_config'] ? this.authService.userInfo['frontend_config'] : {}
    this.countryDashboardEnabled = frontendConfigObj.hasOwnProperty('countryDashboard');
    this.countryEnabled = frontendConfigObj.hasOwnProperty('country')
    this.companyDashboardEnabled = frontendConfigObj.hasOwnProperty('companyDashboard')
    this.companyEnabled = frontendConfigObj.hasOwnProperty('company')
    this.referenceDataEnabled = frontendConfigObj.hasOwnProperty('referenceData')
    this.adminEnabled = frontendConfigObj.hasOwnProperty('admin')
  }

  get username(): AbstractControl | null {
    return this.loginForm.get('username')
  }

  get password(): AbstractControl | null {
    return this.loginForm.get('password')
  }

  ngAfterViewInit() {
    let button:any = document.getElementById('usernameBtn');
    let menu:any = document.getElementById('usernameMenu');
    let menuController = menu.parentElement;
    menu.positionTarget = button;
    menu.position = ['bottom-end']
    button.addEventListener('click', function() {
      if (!menu.fullyOpened && !menu.transitioning) {
        menu.opened = true;
      }
    });
    menu.data = this.calMenuData();

    menuController.addEventListener('item-trigger', (e:any) => {
      var value = e.detail.value;
      menu.opened = false;
      switch(value) {
        // case 'HELP':
          //this.openHelpPage();
          // break;
        case 'LOGOUT':
          this.logout();
          break;
      }
    });
  }

  calMenuData() {
    let menuData = [{
      type: 'header',
      label: 'Menu'
    },/*{
      icon: 'help',
      value: 'HELP',
      label: 'Help'
    },*/{
      icon: 'leftpanel-closed',
      value: 'LOGOUT',
      label: 'Logout'
    }, {
      type: 'divider'
    }, {
      label: `Version ${this.configService.getVersion()}`,
      disabled: true
    }]
    return menuData;
  }

  login(): void {
    this.loading = true
    this.authService.login(this.username && this.username.value, this.password && this.password.value).subscribe(
      (value: any) => {
        this.loginDlgOpened = false;
        value['username'] = this.username && this.username.value;
        this.authService.storeSessionInfo(value)
        let userId = this.authService.userInfo['username']
        this.shortUserId = userId.indexOf('@') == -1 ? userId : userId.substr(0, userId.indexOf('@'))
        this.orgName = this.authService.userInfo['org_name'] ? this.authService.userInfo['org_name'] : this.defaultOrgName;
        if (this.orgName == "HDFC") {
          this.logoFileName = "HDFC"
        } else {
          this.logoFileName = "RFT"
        }
        this.loggedIn = true;
        this.loginForm.reset()
        this.initComponentVariables();
        this.hasAdminPrivilege = this.authService.isAdmin()
        let frontendObj = this.authService.userInfo['frontend_config']
        if (frontendObj && frontendObj['common']) {
          if (frontendObj['common']['companyDashboardComponentName']) {
            this.companyDashboardComponentName = frontendObj['common']['companyDashboardComponentName']
          } else {
            this.companyDashboardComponentName = this.configService.defaultValues['companyDashboardComponentName']
          }
          if (frontendObj['common']['companyComponentName']) {
            this.companyComponentName = frontendObj['common']['companyComponentName']
          } else {
            this.companyComponentName = this.configService.defaultValues['companyComponentName']
          }
        } else {
          this.companyDashboardComponentName = this.configService.defaultValues['companyDashboardComponentName']
          this.companyComponentName = this.configService.defaultValues['companyComponentName']
        }
        this.loading = false;
        this.router.navigate(['/country-dashboard'])
      }, (error:Error) => {
        this.loading = false;
        this.errorMsg(error.message, 6000)
        //this.loginForm.reset()
        this.loginDlgOpened = true;
      }
    )
  }

  logout(): void {
    this.shortUserId = "Login";
    this.orgName = this.defaultOrgName;
    this.loggedIn = false;
    this.authService.clearSessionInfo();
    this.appStateService.clearCache();
    this.router.navigate(["/main"])
    this.loginDlgOpened = true;
    this.hasAdminPrivilege = false;
  }

  errorMsg(msg: string, milliseconds: number) {
    error(msg, milliseconds)
  }

  cancelLogin(): void {
    this.loginDlgOpened = true;
  }

  test(): void {
    error('error', 6000)
    info('info', 6000)
    warn('warn', 6000)
  }
}
