import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class EncryptService {
  _salt:string = "R%f%%T*I@@B88$OR##-T(R*";

  constructor() { }

  encrypt(message: string): string {
    if (!message) {
      return "";
    }
    return CryptoJS.AES.encrypt(message, this._salt).toString()
  }

  decrypt(ciphertext: string): string {
    if (!ciphertext) {
      return '';
    }
    let bytes  = CryptoJS.AES.decrypt(ciphertext, this._salt);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
}
