import { Injectable } from '@angular/core';
import { LocalstorageService } from './localstorage.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { HttpRequestBase } from './http-request-base.service';
import { catchError, map, throwError } from 'rxjs';
import { info, confirm, warn, error } from '@elf/amber-notification/lib/helpers';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends HttpRequestBase  {

  private baseUrl = environment.apiUrl; // "https://api.dev.news-solutions.refinitiv.com";
  constructor(private localStorageService: LocalstorageService, private http: HttpClient) {
    super();
  }

  requestOrganizations() {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/admin/orgs?username=${username}`
    return this.http.get(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  addOrganization(payload:any) {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/admin/orgs?username=${username}`
    return this.http.post(url, payload, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  updateOrganization(payload:any) {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/admin/orgs?username=${username}`
    return this.http.put(url, payload, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  deleteOrganization(orgId:any) {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/admin/orgs?username=${username}&orgId=${orgId}`
    return this.http.delete(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  requestUsers() {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/admin/users?username=${username}`
    return this.http.get(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  addUser(payload:any) {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/admin/users?username=${username}`
    return this.http.post(url, payload, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  updateUser(payload:any) {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/admin/users?username=${username}`
    return this.http.put(url, payload, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }

  deleteUser(userId:any) {
    let username = this.localStorageService.getItem('username');
    let accessToken = this.localStorageService.getItem('access_token');
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }
    let url = `${this.baseUrl}/admin/users?username=${username}&userId=${userId}`
    return this.http.delete(url, options).pipe(
      map((res: any) => {
        if (res && res.Code && res.Code == "401") {
          return throwError( () => new Error('User is not authorized. Please contact Administrator.'));
        }
        return res
      }),
      catchError(this.handleError)
    );
  }
}