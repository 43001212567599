import { Injectable } from '@angular/core';
import { EncryptService } from './encrypt.service';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  constructor(private encryptService: EncryptService) { }

  setItem(key: any, value:string) {
    if (typeof(value) == 'boolean') {
      value = value + ''
    } else if (typeof(value) == 'object') {
      value = JSON.stringify(value)
    }
    window.localStorage.setItem(key, this.encryptService.encrypt(value))
  }

  getItem(key:any) {
    if (!key) {
      return "";
    }
    let value = window.localStorage.getItem(key);
    if (!value) {
      return ""
    } else {
      return this.encryptService.decrypt(value)
    }
  }

  removeItem(key:any) {
    if (!key) return;
    window.localStorage.removeItem(key)
  }

  clear() {
    window.localStorage.clear();
  }

}
